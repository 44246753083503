import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import "./Codeblock.css";
const Codeblock = ({ heading, code }) => {
  const codeBlockStyle = {
    background: "white",
    borderRadius: "1px",
    padding: "10px",
    paddingRight: "10px",
  };
  return (
    <div className="codeblock">
      <h3>{heading}</h3>
      <div className="codeblock">
        <SyntaxHighlighter
          language="javascript"
          showLineNumbers
          customStyle={codeBlockStyle}
        >
          {code}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export default Codeblock;

import React from "react";
import { Link } from "react-router-dom";

const User = () => {
  return (
    <div className="container">
      <h1>User</h1>
      <h2>Description</h2>
      <p>
        All the information pertaining to a user is embodied in the User object.
      </p>
      <h2>Object Attributes</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>id</td>
          <td>String</td>
          <td>Yes</td>
          <td> Id of the user</td>
        </tr>
        <tr>
          <td>teamId</td>
          <td>Integer</td>
          <td>Yes</td>
          <td> Id of the team that this user belongs to</td>
        </tr>
        <tr>
          <td>email</td>
          <td>String</td>
          <td>Yes</td>
          <td> Email address of the user</td>
        </tr>

        <tr>
          <td>firstName</td>
          <td>String</td>
          <td>Yes</td>
          <td> This user's first name</td>
        </tr>
        <tr>
          <td>lastName</td>
          <td>String</td>
          <td>Yes</td>
          <td> This user's last name</td>
        </tr>
        <tr>
          <td>timezone</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            {" "}
            The timezone of the user in{" "}
            <Link to="https://en.wikipedia.org/wiki/UTC_offset">
              UTC offset format
            </Link>
          </td>
        </tr>
        <tr>
          <td>profileImage</td>
          <td>String</td>
          <td>No</td>
          <td> URL of the user's profile image</td>
        </tr>
      </table>
    </div>
  );
};

export default User;

import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/attachments/22741007/40239320.png";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const ClientActions = () => {
  const tableData = [
    {
      id: 1,
      trigger: "Button",
      event: "client.pressButton",
    },
    {
      id: 2,
      trigger: "Slash Command",
      event: "client.slashCommand",
    },
    {
      id: 3,
      trigger: "Widget",
      event: "client.widgetAction",
    },
    {
      id: 4,
      trigger: "FlockML",
      event: "client.flockmlAction",
    },
  ];
  const codeBlocks = [
    {
      heading: `Configuring Actions in Attachment Buttons`,
      code: singleLineString`{
        "description": "attachment description",
        "views": {
            "widget": { "src": "<widget url>", "width": 400, "height": 400 }
        },
        "buttons": [
          {
            "name": "<button 1>",
            "icon": "<icon 1 url>",
            "action": { "type": "openWidget", "desktopType": "modal", "mobileType": "modal", "url": "<action url>" },
            "id": "<button id 1>"
        },
         {
            "name": "<button 2>",
            "icon": "<icon 2 url>",
            "action": { "type": "openBrowser", "url": "<url>", "sendContext": true }
            "id": "<button id 2>"
        }
      ]
    }`,
    },
    {
      heading: `Opening a widget using flock.js`,
      code: singleLineString`flock.openWidget("<widget url>", "modal")`,
    },
    {
      heading: `flock.openWidget("<widget url>", "modal")`,
      code: singleLineString`flock.openBrowser("<url>", true);`,
    },
    {
      heading: `Opening a widget using FlockML`,
      code: singleLineString`<flockml>click <action type="openWidget" 
      url="<url>" desktopType="sidebar" mobileType="modal">here</action> to launch a widget.</flockml>`,
    },
    {
      heading: `Opening the browser using FlockML`,
      code: singleLineString`<flockml>click <action type="openBrowser"
       url="<url>" sendContext="true">here</action> to open the browser.</flockml>`,
    },
    {
      heading: `Sending event to the event listener using FlockML`,
      code: singleLineString`<flockml>client <action type="sendEvent">here
      </action> to send an event to the event listener URL.</action>`,
    },
  ];

  return (
    <div className="container">
      <h1>Client Actions</h1>
      <ul>
        <li>
          <a href="/display/flockos/Client+Actions#ClientActions-Whatareclientactions?">
            What are Client Actions
          </a>
          <ul>
            <li>
              <a href="/display/flockos/Client+Actions#ClientActions-OpeningaWidget">
                Opening a Widget{" "}
              </a>
            </li>
            <li>
              <a href="/display/flockos/Client+Actions#ClientActions-OpeningtheBrowser">
                Opening the Browser{" "}
              </a>
            </li>
            <li>
              <a href="/display/flockos/Client+Actions#ClientActions-SendinganeventtotheeventlistenerURL?">
                Sending an event to the event listener URL{" "}
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="/display/flockos/Client+Actions#ClientActions-Whathappenswhenaclientactionistriggered?">
            What happens when a client action is triggered?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Client+Actions#ClientActions-HowdoIconfigureaclientaction?">
            How do I configure a client action?
          </a>
          <ul>
            <li>
              <a href="/display/flockos/Client+Actions#ClientActions-DeveloperDashboard">
                Developer Dashboard{" "}
              </a>
            </li>
            <li>
              <a href="/display/flockos/Client+Actions#ClientActions-AttachmentButtons">
                Attachment Buttons{" "}
              </a>
            </li>
            <li>
              <a href="/display/flockos/Client+Actions#ClientActions-flock.js">
                flock.js{" "}
              </a>
            </li>
            <li>
              <a href="/display/flockos/Client+Actions#ClientActions-FlockML">
                FlockML{" "}
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="/display/flockos/Client+Actions#ClientActions-Canclientactionsbetriggeredforuserswhohaven'tinstalledtheapp?">
            Can client actions be triggered for users who haven't installed the
            app?
          </a>
        </li>
      </ul>

      <section id="ClientActions-Whatareclientactions?">
        <h2>What are client actions?</h2>
        <p>
          Client actions are actions that Flock clients take when a user
          interacts with controls like{" "}
          <Link to="/display/flockos/Slash+Commands"> Slash Commands </Link>,
          <Link to="/display/flockos/Attachment+Pickers">
            {" "}
            Attachment Picker{" "}
          </Link>
          ,{" "}
          <Link to="/display/flockos/Launcher+Buttons"> Launcher button </Link>,
          and so on.
        </p>
        <p>Flock currently supports three kinds of client actions. They are:</p>
        <ul>
          <li>
            Opening a <Link to="/display/flockos/Widgets"> Widget </Link>
            inside the Flock client{" "}
          </li>
          <li>Opening a URL in the system browser; </li>
          <li>
            Sending an event to the{" "}
            <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
              {" "}
              events listener URL{" "}
            </a>
          </li>
        </ul>
        <div id="ClientActions-OpeningaWidget">
          <p className="bold">Opening a Widget</p>
          <p>
            You can use <Link to="/display/flockos/Widgets"> widgets </Link>
            to render web applications inside an iframe or a webview within
            Flock. They can not only do everything that a normal webapp can, but
            also interact with the Flock client hosting them.
          </p>
          <p>To open a widget, the following info is required:</p>
          <ul>
            <li>URL of the widget</li>
            <li>
              Type of widget in desktop – it can be either modal or sidebar
            </li>
            <li>
              Type of widget in mobile – this is usually optional. The only
              acceptable value is modal.
            </li>
          </ul>
        </div>
        <div id="ClientActions-OpeningtheBrowser">
          <p className="bold">Opening the Browser</p>
          <p>
            You can also choose to open a URL in the browser. To open the
            browser, provide the following:
          </p>
          <ul>
            <li>The URL to open.</li>
            <li>
              Whether to send event context or not. When enabled,{" "}
              <a href="/display/flockos/Events#Events-WidgetOrBrowserURLReceivingEventsonaWidgetorintheBrowser">
                {" "}
                event parameters{" "}
              </a>{" "}
              are appended to the URL.
            </li>
          </ul>
        </div>
        <div id="ClientActions-SendinganeventtotheeventlistenerURL?">
          <p className="bold">Sending an event to the event listener URL</p>
          <p>
            Or if you prefer to remain silent, you can send an event to the
            event listener URL.
          </p>
        </div>
      </section>
      <section id="ClientActions-Whathappenswhenaclientactionistriggered?">
        <h2>What happens when a client action is triggered?</h2>
        <p>
          An <Link to="/display/flockos/Events"> event </Link>
          is generated every time a client action is triggered.
        </p>
        <ul>
          <li>
            If the configured action is to open a widget or browser URL, the
            event is appended as a{" "}
            <a href="/display/flockos/Events#Events-WidgetOrBrowserURLReceivingEventsonaWidgetorintheBrowser">
              query parameter to the URL{" "}
            </a>
            .
          </li>
          <li>
            Or if the configured action is to{" "}
            <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
              {" "}
              send the event to the event listener URL{" "}
            </a>
            , it is sent to the app service URL.
          </li>
        </ul>
        <p>
          The events sent by various controls when a client action is triggered
          are listed below:
        </p>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Trigger</th>
              <th>Event</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <tr key={item.id}>
                <td>{item.trigger}</td>
                <td>{item.event}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          The event name along with its attribute provides sufficient context
          for the client action.
        </p>
      </section>
      <section id="ClientActions-HowdoIconfigureaclientaction?">
        <h2>How do I configure a client action?</h2>
        <p>
          There are multiple ways to configure client actions in Flock, all of
          which are described below.
        </p>
        <div id="ClientActions-DeveloperDashboard">
          <p className="bold">Developer Dashboard</p>
          <p>
            Use the developer dashboard to configure an action for a{" "}
            <Link to="/display/flockos/Slash+Commands"> Slash Commands </Link>,
            <Link to="/display/flockos/Launcher+Buttons">
              {" "}
              Launcher button{" "}
            </Link>
            ,
            <Link to="/display/flockos/Chat+Tab+Buttons">
              {" "}
              Chat Tab Buttons{" "}
            </Link>
            or{" "}
            <Link to="/display/flockos/Attachment+Pickers">
              {" "}
              Attachment Picker{" "}
            </Link>
            . Once you have enabled the control, you can select the appropriate
            action and provide any additional info that's required.
          </p>
        </div>
        <img src={img1} alt="" />
        <div id="ClientActions-AttachmentButtons">
          <p className="bold">Attachment Buttons</p>
          <p>
            Client actions for{" "}
            <a href="/display/flockos/Sending+Attachments#SendingAttachments-AttachmentButtonsWhatareAttachmentbuttons?">
              {" "}
              attachment buttons{" "}
            </a>{" "}
            are configured while creating the corresponding{" "}
            <a href="/display/flockos/Attachment"> Attachment </a>. Use the
            action property of a button to set the appropriate{" "}
            <a href="/display/flockos/Attachment#Attachment-ActionConfig">
              {" "}
              ActionConfig{" "}
            </a>
            object.
          </p>
        </div>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <div id="ClientActions-flock.js">
          <p className="bold">flock.js</p>
          <p>
            Widgets can open another widget or the browser using{" "}
            <Link to="/display/flockos/flock.js"> flock.js</Link>.
          </p>
        </div>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
        <Codeblock heading={codeBlocks[2].heading} code={codeBlocks[2].code} />
        <div id="ClientActions-FlockML">
          <p className="bold">FlockML</p>
          <p>
            <Link to="/display/flockos/FlockML"> FlockML </Link>
            's action tag can also be used to trigger client actions:
          </p>
        </div>
        <Codeblock heading={codeBlocks[3].heading} code={codeBlocks[3].code} />
        <Codeblock heading={codeBlocks[4].heading} code={codeBlocks[4].code} />
        <Codeblock heading={codeBlocks[5].heading} code={codeBlocks[5].code} />
      </section>
      <section id="ClientActions-Canclientactionsbetriggeredforuserswhohaven'tinstalledtheapp?">
        <h2>
          Can client actions be triggered for users who haven't installed the
          app?
        </h2>
        <p>
          Yes. Your app may send a message to a user who may not have installed
          your app, or a group where some users may not have installed your app.
          When these users interact with this message's attachment (e.g. click
          on an attachment button, or take some action inside the attachment
          widget), a client action is triggered.
        </p>
        <p>
          If you verify that the{" "}
          <Link to="/display/flockos/Event+Tokens"> event token </Link>
          sent with the corresponding event is really signed using your app's
          secret (you should always do this anyways), you can be certain that
          the event originated from Flock and was actually triggered by the user
          whose userId is included in the event token (even if they haven't
          installed the app).
        </p>
      </section>
    </div>
  );
};

export default ClientActions;

import React from "react";

const DeprecatedGroup = () => {
  const tableData = [
    {
      id: 1,
      name: "id",
      type: "String",
      required: "Yes",
      description: "Id of this group",
    },
    {
      id: 2,
      name: "name",
      type: "String",
      required: "Yes",
      description: "The name of this group",
    },
    {
      id: 3,
      name: "memberCount",
      type: "Number",
      required: "Yes",
      description: "Count of the group's members",
    },
    {
      id: 4,
      name: "profileImage",
      type: "String",
      required: "No",
      description: "URL for the group's profile image",
    },
  ];
  return (
    <div className="container">
      <h1>Group</h1>
      <div className="caution">
        <p>
          <p className="bold">Deprecation Notice</p> The object Group is
          deprecated. The following deprecated methods return this object.
          Developers should instead use the newer methods listed next to them,
          which return the Channel object. groups.list, deprecated by
          channels.list groups.getInfo, deprecated by channels.getInfo
        </p>
      </div>
      <h2>Description</h2>
      <p>This object describes metadata for a group.</p>
      <h2>Object Attributes</h2>
      <table className="custom-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item) => (
            <tr key={item.id}>
              <td>{item.name}</td>
              <td>{item.type}</td>
              <td>{item.required}</td>
              <td>{item.description}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DeprecatedGroup;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
import img1 from "../assets/chatFetch.png";

const ChatFetchMessages = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`[
    { "from": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
      "to": "u:cfc76545-3400-4864-892a-513a9f4ae409",
      "uid": "fd4877b719b1",
      "text": "Hello" },
    {
       "from": "u:cfc76545-3400-4864-892a-513a9f4ae409",
      "to": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
      "uid": "59e2da001af2",
      "text": "Hello again"
     } 
    ]`,
    },
  ];
  return (
    <div className="container">
      <h1>chat.fetchMessages</h1>
      <p>
        This method allows an app to fetch specific messages from a
        conversation.
      </p>
      <p>
        The method endpoint is https://api.flock.co/v1/chat.fetchMessages and
        follows the{" "}
        <a href="/display/flockos/Methods#Methods-CallingConventionsCallingamethod">
          method calling conventions.
        </a>
      </p>
      <p>
        Your app provides a list of message uids to fetch, and Flock will, in
        response, return a list of{" "}
        <Link to="/display/flockos/Message"> Message </Link> objects.
      </p>

      <section id="section1">
        <h2>Parameters</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>token</td>
            <td>String</td>
            <td>Yes</td>
            <td>Token of the user on whose behalf the message is being sent</td>
          </tr>
          <tr>
            <td>chat</td>
            <td>String</td>
            <td>Yes</td>
            <td>
              <Link to="/display/flockos/Identifiers"> Identifiers </Link>
              for user or group for which messages are to be fetched
            </td>
          </tr>
          <tr>
            <td>uids</td>
            <td>Array</td>
            <td>Yes</td>
            <td>
              <p>A comma separated list of message uids (max length: 25).</p>
              <p>
                If a message doesn't exist for any uid, it is silently skipped.
              </p>
              <p>Example: ["fd4877b719b1", "59e2da001af2"]</p>
            </td>
          </tr>
        </table>
      </section>
      <section id="section2">
        <h2>Response</h2>
        <p>
          A list of <Link to="/display/flockos/Message"> Message </Link>
          objects.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section3">
        <h2>Error</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Error Code</th>
            <th>HTTP Status</th>
            <th>Description</th>
            <th>Additional Parameters</th>
          </thead>
          <tr>
            <td>UnknownChat</td>
            <td>400</td>
            <td>The group or userId provided in chat is not known. </td>
            <td> </td>
          </tr>
        </table>
      </section>
      <img style={{ width: "70%" }} src={img1} alt="" />
    </div>
  );
};

export default ChatFetchMessages;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ChatSendMessage = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "uid": "d9e054e0-a679-4a7a-9cfa-7299669aa083"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>chat.sendMessage</h1>
      <p>This method allows an app to send a message to a user or a group.</p>
      <p>
        The method endpoint is https://api.flock.co/v1/chat.sendMessage and
        follows the{" "}
        <a href="/display/flockos/Methods#Methods-CallingConventionsCallingamethod">
          {" "}
          method calling conventions{" "}
        </a>
        .
      </p>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td> Token of the user on whose behalf the message is being sent</td>
        </tr>
        <tr>
          <td>to</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            {" "}
            <Link to="/display/flockos/Identifiers"> Identifiers </Link>
            of the user to whom the message is to be sent
          </td>
        </tr>
        <tr>
          <td>text</td>
          <td>String</td>
          <td>No</td>
          <td> Text of the message</td>
        </tr>
        <tr>
          <td>flockml</td>
          <td>String</td>
          <td>No</td>
          <td>
            <Link to="/display/flockos/FlockML"> FlockML </Link>
            alternative to message text. If present this will be shown instead
            of message text.
          </td>
        </tr>
        <tr>
          <td>notification</td>
          <td>String</td>
          <td>No</td>
          <td>
            Notification to be shown for the message. If not present, text is
            used for the notification.
          </td>
        </tr>
        <tr>
          <td>mentions </td>
          <td>Array</td>
          <td>No</td>
          <td>
            <p>
              An array of user ids indicating users who have been mentioned in
              the message.
            </p>
            <p>Only relevant for group messages.</p>
          </td>
        </tr>

        <tr>
          <td>sendAs </td>
          <td>SendAs</td>
          <td>No</td>
          <td>
            Use this field if you want to display another name and image as the
            sender.
          </td>
        </tr>
        <tr>
          <td>attachments </td>
          <td> Array of Attachments No</td>
          <td>No</td>
          <td>
            An array of{" "}
            <Link to="/display/flockos/Attachment"> attachments </Link>. Any
            rich content to be included with the message is sent as an
            attachments. Note: Currently, there is a limitation of one
            attachment per message.
          </td>
        </tr>
        <tr>
          <td>onBehalfOf</td>
          <td>String</td>
          <td>No</td>
          <td>
            Needed when a bot sends a message to a channel. Set its value to the
            id of the user on whose behalf the bot is sending a message. See{" "}
            <Link to="/display/flockos/Bots">
              {" "}
              how bots can send a message to a channel.{" "}
            </Link>
          </td>
        </tr>
        <tr>
          <td>visibleTo</td>
          <td>Array</td>
          <td>No</td>
          <td>
            Use this to display the message to a limited number of members in a
            channel. Its value should be an array of user ids.
          </td>
        </tr>
      </table>
      <p>SendAs</p>
      <p>The attributes of the sendAs field are as follows:</p>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>name</td>
          <td>String</td>
          <td>Yes</td>
          <td> Name of the sender.</td>
        </tr>
        <tr>
          <td>profileImage</td>
          <td>String</td>
          <td>Yes</td>
          <td> URL of the sender's profile image.</td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>The uid of the message.</p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      <h2>Error</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Error Code</th>
          <th>HTTP Status</th>
          <th>Description</th>
          <th>Additional Parameters</th>
        </thead>
        <tr>
          <td>UnknownRecipient</td>
          <td>400</td>
          <td>The recipient of the message in the to field is unknown </td>
          <td> </td>
        </tr>
        <tr>
          <td>MissingTextOrAttachment</td>
          <td>400</td>
          <td>The message contains neither text nor any attachment </td>
          <td> </td>
        </tr>
      </table>
    </div>
  );
};

export default ChatSendMessage;

import React from "react";
import { Link } from "react-router-dom";

const Integrations = () => {
  return (
    <div className="container">
      <h1>Integrations</h1>

      <p>
        FlockOS Integrations allow apps to extend Flock clients in many
        interesting ways.
      </p>
      <ul>
        <li>
          <Link to="/display/flockos/Slash+Commands"> Slash Commands </Link>
          allow users to send text based commands to your app
        </li>
        <li>
          Use an{" "}
          <Link to="/display/flockos/Attachment+Pickers">
            {" "}
            Attachment Picker{" "}
          </Link>
          to make it easy to send messages with{" "}
          <Link to="/display/flockos/Attachment"> Attachment </Link>
        </li>
        <li>
          Launcher Buttons and{" "}
          <Link to="/display/flockos/Chat+Tab+Buttons"> Chat Tab Buttons </Link>
          are typically used to create a sidebar{" "}
          <Link to="/display/flockos/Widgets"> Widget </Link>, but you can use
          them in other ways too.
        </li>
        <li>
          Or give a sneak peak of URLs shared by users using{" "}
          <Link to="/display/flockos/URL+Preview"> URL Preview </Link>.
        </li>
      </ul>
    </div>
  );
};

export default Integrations;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ChannelCreate = () => {
  const codeBlocks = [
    {
      heading: `Example`,
      code: singleLineString`{
        "id": "g:<id1>",
        "members": {
            "u:<id2>": { "status": "added" },
            "u:<id3>": { "status": "failed", "error": "UserNotFound" }
        }
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>channels.create</h1>
      <p>
        This method can be used to create a new channel with the given name,
        image and purpose. It takes a list of members that are to be added to
        the channel. The user whose token is used to create the channel is also
        added to the channel. The method returns the channel id in the response.
      </p>
      <p>
        The method endpoint is https://api.flock.co/v1/channels.create and
        follows the method calling conventions.
      </p>
      <section id="section1">
        <h2>Parameters</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>token</td>
            <td>String</td>
            <td>Yes</td>
            <td>
              {" "}
              <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
                {" "}
                Authentication token{" "}
              </a>
              of the user creating the channel
            </td>
          </tr>
          <tr>
            <td>name</td>
            <td>String</td>
            <td>Yes</td>
            <td> Name of the channel (max 255 characters)</td>
          </tr>
          <tr>
            <td>purpose</td>
            <td>String</td>
            <td>No</td>
            <td> Channel purpose</td>
          </tr>
          <tr>
            <td>profileImage</td>
            <td>String</td>
            <td>No</td>
            <td> URL of the channel's profile image</td>
          </tr>
          <tr>
            <td>type</td>
            <td>String</td>
            <td>No</td>
            <td> Can be "public" or "private". Set to "private" by default.</td>
          </tr>
          <tr>
            <td>members</td>
            <td>Array</td>
            <td>Yes</td>
            <td> A list of user identifiers to be added to the channel</td>
          </tr>
        </table>
      </section>
      <section id="section2">
        <h2>Response</h2>
        <p>The response object has the following attributes:</p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>id</td>
            <td>String</td>
            <td> Identifier for the channel</td>
          </tr>
          <tr>
            <td>members</td>
            <td>Object</td>
            <td>
              {" "}
              An object whose keys are user ids of the members who were added
              and values are{" "}
              <Link to="/display/flockos/ChannelMemberStatus">
                {" "}
                ChannelMemberStatus{" "}
              </Link>
              objects, indicating whether the addition was successful or not.
            </td>
          </tr>
        </table>
        <p>Here's an example response:</p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
    </div>
  );
};

export default ChannelCreate;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const ClientSlashCommand = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`// Assuming the command was:
      // remind me in 30 minutes to have dinner
      {
          "name": "client.slashCommand",
          "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
          "userName": "Alyssa P. Hacker",
          "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
          "chatName": "Ben Bitdiddle",
          "command": "remind",
          "text": "me in 30 minutes to have dinner"
      }`,
    },
    {
      heading: ``,
      code: singleLineString`{
        "text": "<toaster message>"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>client.slashCommand</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is generated when a user enters a{" "}
          <Link to="/display/flockos/Slash+Commands"> Slash Commands </Link>.
        </p>

        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Attribute Name</th>
            <th> Attribute Type</th>
            <th> Attribute Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>

          <tr>
            <td>userName</td>
            <td>String</td>
            <td>Name of the user</td>
          </tr>
          <tr>
            <td>chat</td>
            <td>String</td>
            <td>Id of the user or group whose chat tab was open</td>
          </tr>
          <tr>
            <td>chatName</td>
            <td>String</td>
            <td>Name of the user or group whose chat tab is open</td>
          </tr>
          <tr>
            <td>command</td>
            <td>String</td>
            <td>The name of the command</td>
          </tr>
          <tr>
            <td>text</td>
            <td>String</td>
            <td>Additional text entered by the user</td>
          </tr>
        </table>
      </section>
      <section id="section3">
        <h2>Response</h2>
        <p>
          Your app can respond with a text value – this should be a short (upto
          100 chars) string that is displayed to the user who entered the slash
          command.
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      </section>
    </div>
  );
};

export default ClientSlashCommand;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const Flockjs = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`
      <html>
    <head>
        <script src="/path/to/flock.js"></script>
    </head>
    <body>
        ...
    </body>
</html>`,
    },
  ];
  return (
    <div className="container">
      <h1>flock.js</h1>
      <ul>
        <li>
          <a href="/display/flockos/flock.js#flock.js-Whatisflock.js?">
            What is flock.js?
          </a>
        </li>
        <li>
          <a href="/display/flockos/flock.js#flock.js-HowdoIuseit?">
            How do I use it?
          </a>
        </li>
        <li>
          <a href="/display/flockos/flock.js#flock.js-Methods">Methods</a>
          <ul>
            <li>
              <a href="/display/flockos/flock.js#flock.js-flock.openWidget(url,desktopType[,moblileType])">
                flock.openWidget(url, desktopType[, moblileType])
              </a>
            </li>
            <li>
              <a href="/display/flockos/flock.js#flock.js-flock.openBrowser(url[,sendContext])">
                flock.openBrowser(url[, sendContext])
              </a>
            </li>
            <li>
              <a href="/display/flockos/flock.js#flock.js-flock.close()">
                flock.close()
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <section id="flock.js-Whatisflock.js?">
        <h2>What is flock.js?</h2>
        <p>
          Widgets can use flock.js to communicate with their host Flock client.
          flock.js can only be used from within widgets inside Flock. It is not
          supported independently inside a browser
        </p>
        <p>
          It is available at{" "}
          <Link to="https://apps-static.flock.co/js-sdk/1.8.6/flock.js">
            https://apps-static.flock.co/js-sdk/1.8.6/flock.js
          </Link>
        </p>
        <p>Some of the things that you can do with flock.js are:</p>
        <ul>
          <li>
            Use the currently opened widget to open another widget. For eg
            launch a modal widget from a sidebar widget
          </li>
          <li>Launch the browser</li>
          <li>Close the current widget (only applicable for modal widgets)</li>
        </ul>
      </section>
      <section id="flock.js-HowdoIuseit?">
        <h2>How do I use it?</h2>
        <p>Include flock.js in your widget via a script tag in the HTML.</p>

        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="flock.js-Methods">
        <h2>Methods</h2>
        <p>The following methods are available on the flock object.</p>
        <div id="flock.js-flock.openWidget(url,desktopType[,moblileType])">
          <p className="bold">
            flock.openWidget(url, desktopType[, moblileType])
          </p>
          <p>This method opens a widget.</p>
          <ul>
            <li>url. The URL that should be opened</li>
            <li>desktopType. Can be either 'modal' or 'sidebar</li>
            <li>mobileType. Can only be 'modal', which is also the default.</li>
          </ul>
          <p>
            Note: When opening a widget using this method, the assoicated{" "}
            <Link to="/display/flockos/Events"> event </Link>
            that is passed to the URL is{" "}
            <Link to="/display/flockos/client.widgetAction">
              {" "}
              client.widgetAction
            </Link>
            .
          </p>
        </div>
        <div id="flock.js-flock.openBrowser(url[,sendContext])">
          <p className="bold">flock.openBrowser(url[, sendContext])</p>
          <p>This method launches a browser.</p>
          <ul>
            <li>url. The URL that should be opened.</li>
            <li>
              sendContext. If false, then Flock just opens the link in the
              browser without passing any context to it.
            </li>
          </ul>
          <p>
            Note: When launching the browser with context using this method, the
            assoicated <Link to="/display/flockos/Events"> event </Link> that is
            passed to the URL is{" "}
            <Link to="/display/flockos/client.widgetAction">
              {" "}
              client.widgetAction
            </Link>
            .
          </p>
        </div>
        <div id="flock.js-flock.close()">
          <p className="bold">flock.close()</p>
          <p>Closes the widget within which this method was called.</p>
        </div>
      </section>
    </div>
  );
};

export default Flockjs;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const img1 =
  "https://support.flock.com/hc/article_attachments/360024469174/Webhooks_Dev01.png";
const img2 =
  "https://support.flock.com/hc/article_attachments/360025396953/Webhooks_DevO01.png";
const img3 =
  "https://support.flock.com/hc/article_attachments/360025396993/Webhooks_DevO02.png";
const img4 =
  "https://support.flock.com/hc/article_attachments/360025396973/Webhooks_DevO03.png";
const OutgoingWebhook = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "id":"000025c9-0000-002a-0000-000000002fff",
        "from":"cc1ma89nnd4jm9vf@go.to/talk.to_MAC_1.0.0.147_nqKMZE",
        "to":"1937653121826658704@groups.go.to",
        "type":"message",
        "text":"Test"
        }`,
    },
  ];
  return (
    <div className="container">
      <h1>Create An Outgoing Webhook</h1>
      <p>
        You can use Flock's webhooks to send and receive messages from external
        services using JSON packets. An outgoing webhook sends messages from a
        Flock channel to an external service.
      </p>
      <p>
        Before you create an outgoing webhook, you'll need to set up a Callback
        URL - an HTTP/HTTPS endpoint on your server that accepts JSON payloads.
      </p>
      <div className="caution">
        <p>
          Callback URL The messages are posted to your endpoint with the HTTP
          headers Content-Type: application/json;and Accept-Encoding: gzip,
          deflate. The JSON payload is in the body. If you need help setting up
          your callback URL, contact developer support.
        </p>
      </div>
      <section id="section1">
        <h2> Create an outgoing webhook</h2>
        <li>
          Navigate to the Flock Developer Dashboard at{" "}
          <Link to="https://dev.flock.com">https://dev.flock.com</Link>
        </li>
        <li>Sign in with your Flock account and click Webhooks on the left.</li>
        <li>Choose to Add an Outgoing Webhook.</li>
        <img src={img1} alt="" />
        <li>
          Set the Channel where the webhook should look for messages to send to
          an external service.
        </li>
        <img src={img2} alt="" />
        <li>
          Give the webhook a name and an icon that will be used to post replies
          in Flock.
        </li>
        <img src={img3} alt="" />
        <li>Enter the Callback URL that Flock should send your messages to.</li>
        <img src={img4} alt="" />
        <li>
          Click on Save Settings. The outgoing webhook will post JSON packets to
          the Callback URL in the following format
        </li>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <li>
          A ?token= &lt; webhook-token &gt; is appended to the callback URL
          while making the call so you can verify that the request came from
          Flock. For more info, see{" "}
          <Link to="/display/flockos/Webhooks"> Flock API Documentation </Link>
        </li>
      </section>
    </div>
  );
};

export default OutgoingWebhook;

import React from "react";
import { Link } from "react-router-dom";

const Tutorial = () => {
  return (
    <div className="container">
      <h2>Tutorial</h2>
      <p>
        The easiest way to get started with FlockOS development is to go through
        the following video series on creating a Flock app from scratch. The app
        allows you to setup alarms in Flock, similar to the Reminders app.
      </p>
      <p>The series is divided into seven parts, listed below:</p>
      <ul>
        <li>
          <Link to="https://youtu.be/SHEjU8RKTHw?list=PLSZI1GwLI9F4odDnZsUXHr03fDiNFsBDJ">
            Introduction{" "}
          </Link>
        </li>
        <li>
          <Link to="https://youtu.be/96pyn7t8A8w?list=PLSZI1GwLI9F4odDnZsUXHr03fDiNFsBDJ">
            App Creation and Installation{" "}
          </Link>
        </li>
        <li>
          <Link to="https://youtu.be/2d8Ja09p24E?list=PLSZI1GwLI9F4odDnZsUXHr03fDiNFsBDJ">
            Slash Command{" "}
          </Link>
        </li>
        <li>
          <Link to="https://youtu.be/c94VySHJp5I?list=PLSZI1GwLI9F4odDnZsUXHr03fDiNFsBDJ">
            Sending Messages{" "}
          </Link>
        </li>
        <li>
          <Link to="https://youtu.be/fHhYK-LLz2w?list=PLSZI1GwLI9F4odDnZsUXHr03fDiNFsBDJ">
            App Launcher{" "}
          </Link>
        </li>
        <li>
          <Link to="https://youtu.be/iX5xFvRuads?list=PLSZI1GwLI9F4odDnZsUXHr03fDiNFsBDJ">
            Message Action Button{" "}
          </Link>
        </li>
        <li>
          <Link to="https://youtu.be/GxiMNzSW238?list=PLSZI1GwLI9F4odDnZsUXHr03fDiNFsBDJ">
            Conclusion{" "}
          </Link>
        </li>
      </ul>
      <p>
        The source code for the app is{" "}
        <a href="https://github.com/flockchat/flock-alarms">
          available on Github.
        </a>
      </p>
    </div>
  );
};

export default Tutorial;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const ClientOpenAttachment = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "name": "client.openAttachmentWidget",
        "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "userName": "Alyssa P. Hacker",
        "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195"
        "chatName": "Ben Bitdiddle",
        "messageId": "192ae268-dac3-428f-95a5-3a1ff7affcc9"
        "messageUid": "1373df0e-30d9-44f7-9fc5-dfc9c8d426fa",
        "attachmentId": "bb6c323f-f166-482c-8acf-37f1c4add30b"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>client.openAttachmentWidget</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is generated when a Flock client opens an attachment
          widget. An attachment widget is either opened inline (on desktop) or
          in a modal webview (on mobile).
        </p>

        <p>
          This event is only ever sent to the URL of the attachment widget – it
          is never sent to the event listener URL or to the browser.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>
          <tr>
            <td>userName</td>
            <td>String</td>
            <td>Name of the User</td>
          </tr>
          <tr>
            <td>chat</td>
            <td>String</td>
            <td>Id of the user or group whose chat tab was open</td>
          </tr>
          <tr>
            <td>chatName</td>
            <td>String</td>
            <td>Name of the user or group whose chat tab is open</td>
          </tr>

          <tr>
            <td>messageId </td>
            <td>String</td>
            <td>Client id for the message whose attachment widget is opened</td>
          </tr>

          <tr>
            <td>messageUid </td>
            <td>String</td>
            <td>Server id for the message whose attachment widget is opened</td>
          </tr>

          <tr>
            <td>attachmentId </td>
            <td>String</td>
            <td>id of the attachment whose widget is opened</td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default ClientOpenAttachment;

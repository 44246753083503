import React from "react";
import { singleLineString } from "../Helpers/StringFormatter";
import { Link } from "react-router-dom";
import Codeblock from "./Codeblock";
import img1 from "../assets/attachments/39584256/39584258.png";
import img2 from "../assets/attachments/39584256/39584364.png";
const UrlPreview = () => {
  const codeBlocks = [
    {
      heading: `chat.unfurlUrl`,
      code: singleLineString`{
        "name": "chat.unfurlUrl",
        "userId": "u:8c67055b-202f-4ffe-a18f-1c4553a50175",
        "url": "https://flock.co",
        "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "messageId": "4458f0a9-eca7-4efb-8560-2a0fd3ac858d"
    }`,
    },
    {
      heading: `chat.unfurlUrl response`,
      code: singleLineString`{
        "attachment": {
            "title": "Flock -- A Communication app for teams"
            "description": "Flock is a free enterprise tool for business communication.
             Packed with tons of productivity features, Flock drives 
             efficiency and boosts speed of execution.",
            "url": "https://flock.co",
            "views": {
                "image": {
                "original": { "src": "https://flock.co/logo.png", "width": 400, "height": 400 },
            }
        }
    }`,
    },
  ];

  return (
    <div className="container">
      <h1>URL Preview</h1>
      <ul>
        <li>
          <a href="/display/flockos/URL+Preview#URLPreview-WhatisURLPreview?">
            What is URL Preview?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/URL+Preview#URLPreview-HowdoIhandleURLpreviewsfrommyapp?">
            How do I handle URL previews from my app?{" "}
          </a>
        </li>
      </ul>
      <section id="URLPreview-WhatisURLPreview?">
        <h2>What is URL Preview?</h2>
        <p>
          When a user sends a message containing a URL, Flock will attempt to
          show a preview of the page that the URL points to. For eg
        </p>
        <img style={{ width: "50%" }} src={img1} alt="" />
        <p>
          While Flock does this automatically for public URLs, your app can
          register to generate URL previews for specific URL patterns. For eg
        </p>
        <ul>
          <li>
            A Google Drive Integration app may register to provide URL previews
            for any URLs beginning with{" "}
            <a href="http://docs.google.com">http://docs.google.com</a>
          </li>
          <li>
            A GitHub Integration app may register to provide URL previews for
            any URLs beginning with{" "}
            <a href="http://github.com">http://github.com</a>
          </li>
        </ul>
      </section>
      <section id="URLPreview-HowdoIhandleURLpreviewsfrommyapp?">
        <h2>How do I handle URL previews from my app?</h2>
        <ul>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App"> Creating an App </Link>
            to setup your app in the dev dashboard{" "}
          </li>
          <li>
            In the Advanced Info section, provide a list of domains that your
            app can generate previews for.
            <img style={{ width: "25%" }} src={img2} alt="" />
          </li>
          <li>
            Save these changes, then go through{" "}
            <a href="/display/flockos/Creating+an+App#CreatinganApp-AppInstallationHowdoIinstallandtestmyapp?">
              {" "}
              App Installation{" "}
            </a>{" "}
            and install this app into your own Flock account.
          </li>
          <li>
            After the app is installed, whenever a user shares a URL that
            matches the domains you have entered above, the
            <Link to="/display/flockos/chat.generateUrlPreview">
              {" "}
              chat.generateUrlPreview{" "}
            </Link>
            event will be sent to your{" "}
            <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
              {" "}
              events listener URL{" "}
            </a>
            . For example:
            <Codeblock
              heading={codeBlocks[0].heading}
              code={codeBlocks[0].code}
            />
          </li>
          <li>
            <Link to="/display/flockos/Events"> Respond to the event </Link>{" "}
            with an
            <Link to="/display/flockos/Attachment"> Attachment </Link> object.
            Check Sending Attachment for more details also. Include the original
            URL in the url attribute. When a user clicks on the attachment,
            Flock will open the URL in the browser.
            <Codeblock
              heading={codeBlocks[1].heading}
              code={codeBlocks[1].code}
            />
          </li>
          <li>
            Any authentication required to generate the URL Preview will have to
            be handled by you. For eg if your app is generating a URL Preview
            for a Google Docs URL you may need OAuth permissions from the user
            for his Google account. You can send a message to a user from your
            Bot with a link asking him to provide you with these permissions.
          </li>
        </ul>
      </section>
    </div>
  );
};

export default UrlPreview;

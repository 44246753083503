import React from "react";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const DeprecatedGroupGetMembers = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`[
    {
        "id": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "firstName": "Nicole",
        "lastName": "Sullivan"
    },
    {
        "id": "u:d08c8f64-1a64-4cd9-a563-df5079794aa7",
        "firstName": "Bob",
        "lastName": "Hartnett",
    },
    {
    ...
    } 
 ]`,
    },
  ];
  return (
    <div className="container">
      <h1>groups.getMembers</h1>
      <div className="caution">
        <p className="bold">Deprecation Notice</p>

        <p>
          The method groups.getMembers is deprecated. Use channels.listMembers
          instead.
        </p>
      </div>
      <h2>Description</h2>
      <p>
        This method retrieves public profiles of users belonging to this group.
      </p>
      <h2>Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>Authentication token for the user.</td>
        </tr>
        <tr>
          <td>groupId</td>
          <td>String</td>
          <td>Yes</td>
          <td>Identifier of the group for which information was requested</td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>A list of PublicProfile objects.</p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      <h2>Errors</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Error Code</th>
          <th>HTTP Status</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>UnknownGroup</td>
          <td>400</td>
          <td>The group provided in the groupId field can't be located </td>
        </tr>
      </table>
    </div>
  );
};

export default DeprecatedGroupGetMembers;

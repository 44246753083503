import React from "react";
import { Link } from "react-router-dom";

const FlockOsOverview = () => {
  return (
    <div className="container">
      <section>
        <h1>FlockOS Overview</h1>
        <h2>World's First Chat Operating System</h2>
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/-IR4ojI8QMM"
          title="Introduction to FlockOS"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
        <p>
          FlockOS enables developers to build apps, bots and integrations on top
          of <Link to="https://www.flock.com/"> Flock </Link>, the #1 team
          messenger.
        </p>
        <p>
          Apps are a first class citizen in Flock, and FlockOS allows your apps
          to blend seamlessly with the native Flock experience.
        </p>
      </section>
      <section>
        <h2>Features</h2>
        <p>With FlockOS, your apps can</p>
        <ul>
          <li>
            <Link to="/display/flockos/Sending+Messages"> Send Messages </Link>
            and
            <Link to="/display/flockos/Sending+Attachments"> Attachments </Link>
            to Flock groups and users. For example, the GitHub app sends
            configurable notifications to any Flock Group. The Todo app notifies
            your group whenever a task is completed.
          </li>
          <li>
            Configure a <Link to="/display/flockos/Bots"> Bot </Link> to send
            directed messages to users like the Reminder bot, which sends a
            reminder message at a scheduled time.
          </li>
          <li>
            Add an{" "}
            <Link to="/display/flockos/Attachment+Pickers">
              Attachment Picker
            </Link>{" "}
            to the Attachment Picker bar. For example, the Poll app adds a
            button to the Attachment bar that allows a user to create and send
            opinion polls in Flock
          </li>
          <li>
            Add a
            <Link to="/display/flockos/Chat+Tab+Buttons">
              {" "}
              Chat Tab Button{" "}
            </Link>
            to the Chat tab bar. The Todo app adds a button to the Chat tab bar,
            which pops up the sidebar widget and shows the various tasks of the
            team
          </li>
          <li>
            Add <Link to="/display/flockos/Widgets">Widgets </Link> to Flock
            desktop and mobile clients. These include the modal widget, the
            sidebar widget and the attachment widget. Apps such as the Poll app,
            Todo app and others use these widgets to provide an amazing GUI for
            their apps
          </li>
          <li>
            Setup a{" "}
            <Link to="/display/flockos/Slash+Commands"> Slash Command</Link> to
            receive commands from the user. For example, the /video slash
            command creates a video conference room and displays its URL in the
            group
          </li>
          <li>
            Setup a{" "}
            <Link to="/display/flockos/Launcher+Buttons">
              Launcher Button.{" "}
            </Link>{" "}
            For example, the ToDo app uses the App Launcher button to display a
            list of todos.
          </li>
          <li>
            Receive specific URLs to generate{" "}
            <Link to="/display/flockos/URL+Preview">URL Previews </Link>. For
            example, the Google Drive app subscribes to all URLs starting with
            drive.google.com to provide a rich preview back.
          </li>
        </ul>
      </section>
      <section>
        <h2>Tutorial</h2>
        <p>
          The easiest way to get started with FlockOS development is to go
          through <Link to="/display/flockos/Tutorial">the tutorial</Link> on
          building a FlockOS app from scratch.
        </p>
      </section>
      <section>
        <h2>Further Reading</h2>
        <ul>
          <li>
            <Link to="/display/flockos/Creating+an+App">Creating an App </Link>
            explains how to setup an app in the developer dashboard and handle
            installs.
          </li>
          <li>
            Pick any of the features under{" "}
            <Link to="/display/flockos/Messaging">Messaging</Link> or{" "}
            <Link to="/display/flockos/Integrations">Integrations</Link>
            and add them to your app.
          </li>
          <li>
            You can test the app at anytime by{" "}
            <a href="/display/flockos/Creating+an+App#CreatinganApp-AppInstallationHowdoIinstallandtestmyapp?">
              installing it{" "}
            </a>
            into your own Flock account.
          </li>
          <li>
            Finally,{" "}
            <a href="/display/flockos/Creating+an+App#CreatinganApp-PublishHowdoIpublishmyApp?">
              publish your app{" "}
            </a>
            to allow others in your team to install it.
          </li>
          <li>
            If you want to publish it for every Flock user, please{" "}
            <Link to="/display/flockos/Help+and+Support">
              get in touch with us.
            </Link>
          </li>
        </ul>
      </section>
      <section>
        <h2>API Reference</h2>
        <p>
          While building your app, you can go through our API reference here:
        </p>
        <ul>
          <li>
            <Link to="/display/flockos/Methods">Methods</Link>
          </li>
          <li>
            <Link to="/display/flockos/Events">Events </Link>
          </li>
          <li>
            <Link to="/display/flockos/Objects">Objects </Link>
          </li>
          <li>
            <Link to="/display/flockos/FlockML">FlockML </Link>
          </li>
          <li>
            <Link to="/display/flockos/flock.js">flock.js </Link>
          </li>
          <li>
            <Link to="/display/flockos/Event+Tokens">Event Tokens </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default FlockOsOverview;

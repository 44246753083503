import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/attachments/39584234/39584243.png";
import img2 from "../assets/attachments/39584234/39584235.png";
import img3 from "../assets/attachments/39584234/40239344.png";
const LauncherButton = () => {
  const tableData = [
    {
      id: 1,
      field: "Tooltip text",
      mandatory: "Yes",
      description:
        "This will be shown against your app launcher button to the user",
    },
    {
      id: 2,
      field: "Select action for app launcher",
      mandatory: "Yes",
      description:
        "Select an appropriate client action for the attachment picker.",
    },
  ];
  return (
    <div className="container">
      <h1>Launcher Buttons</h1>
      <li>
        <a href="/display/flockos/Launcher+Buttons#LauncherButtons-WhatisaLauncherbutton?">
          What is a Launcher button?
        </a>
      </li>
      <li>
        <a href="/display/flockos/Launcher+Buttons#LauncherButtons-WhataretheavailableactionsforaLauncherbutton?">
          What are the available actions for a Launcher button?
        </a>
      </li>
      <li>
        <a href="/display/flockos/Launcher+Buttons#LauncherButtons-HowdoIcreatealauncherbutton?">
          How do I create a launcher button?
        </a>
      </li>
      <section id="LauncherButtons-WhatisaLauncherbutton?">
        <h2>What is a Launcher button?</h2>
        <p>
          A Launcher button is another way your app can integrate into the Flock
          desktop and mobile clients and enable your users to Launch your app.
        </p>
        <p>
          Launcher bar and Launcher buttons on desktop, as highlighted in red in
          the image below:
        </p>
        <img style={{ width: "30%" }} src={img1} alt="" />
        <img style={{ width: "20%" }} src={img2} alt="" />
        <p>
          The Launcher button of your app can be used to open a{" "}
          <Link to="/display/flockos/Widgets"> Widget </Link>, open a browser or
          send an event to your event listener URL. For example,
        </p>
        <ul>
          <li>
            The Todo app uses the Launcher button to open a sidebar widget
            showing all the todo lists within that team
          </li>
          <li>
            The Github app uses it to open a modal widget allowing one to
            configure the app
          </li>
        </ul>
      </section>
      <section id="LauncherButtons-WhataretheavailableactionsforaLauncherbutton?">
        <h2>What are the available actions for a Launcher button?</h2>
        <p>
          When a user presses the Launcher button, you can configure it to do
          one of the below:
        </p>
        <ul>
          <li>Launch a modal widget</li>
          <li>Launch a sidebar widget</li>
          <li>Launch a browser</li>
          <li>Send an event to the event listener URL</li>
        </ul>
        <p>
          Alongwith launching the above widgets or browser, a{" "}
          <Link to="/display/flockos/client.pressButton">
            {" "}
            client.pressButton{" "}
          </Link>
          event will also be passed as a parameter to the web app URL loaded in
          any of the above widgets or browser
        </p>
      </section>
      <section id="LauncherButtons-HowdoIcreatealauncherbutton?">
        <h2>How do I create a launcher button?</h2>
        <ol>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App"> Creating an App </Link>
            to setup your app in the developer dashboard.{" "}
          </li>
          <li>
            In the Advanced Info section, enable the app launcher.
            <img src={img3} alt="" />
            <p>Fill in the info below:</p>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Mandatory</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.field}</td>
                    <td>{item.mandatory}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </li>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App">
              {" "}
              app installation{" "}
            </Link>
            and install this app into your own Flock account{" "}
          </li>
          <li>
            Whenever a user presses the Launcher button, the event
            <Link to="/display/flockos/client.pressButton">
              {" "}
              client.pressButton{" "}
            </Link>
            along with a{" "}
            <Link to="/display/flockos/Event+Tokens"> validation token </Link>
            will be appended to the corresponding client action you selected and
            sent to your service{" "}
          </li>
          <li>
            <Link to="/display/flockos/Event+Tokens">
              {" "}
              Verify the event token{" "}
            </Link>{" "}
            and take the relevant action at your end{" "}
          </li>
        </ol>
      </section>
    </div>
  );
};

export default LauncherButton;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ChannelListMembers = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`[
        {
          "userId": "u:xxxxxxx",
          "affiliation": "member",
          "publicProfile": {
            "id": "u:xxxxxxx",,
            "firstName": "Nicole",
            "lastName": "Sullivan",
            "profileImage": "https://i.flockusercontent.com/xxxxxx"
          }
        },
        {
          "userId": "u:yyyyyyy",
          "affiliation": "moderator",
          "publicProfile": {
            "id": "u:yyyyyyy",
            "firstName": "Bob",
            "lastName": "Hartnett",
            "profileImage": "https://i.flockusercontent.com/yyyyyy"
          }
        },
      ]`,
    },
  ];
  return (
    <div className="container">
      <h1>channels.listMembers</h1>
      <p>
        This method retrieves public profiles of users belonging to this group.
      </p>
      <h2>Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            {" "}
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              {" "}
              Authentication token{" "}
            </a>
            for the user
          </td>
        </tr>
        <tr>
          <td>channelId</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            <Link to="/display/flockos/Identifiers"> Identifiers </Link>
            of the channel for which the membership information was requested
          </td>
        </tr>
        <tr>
          <td>showPublicProfile</td>
          <td>Boolean</td>
          <td>Yes</td>
          <td>
            If true, populate the public profile for each ChannelMember
            returned.
          </td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>
        A list of{" "}
        <Link to="/display/flockos/ChannelMember"> ChannelMember </Link>{" "}
        objects.
      </p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      <table className="custom-table" border="1">
        <thead>
          <th>Error Code</th>
          <th>HTTP Status</th>
          <th>Description</th>
          <th>Additional Parameters</th>
        </thead>
        <tr>
          <td>UnknownChannel</td>
          <td>400</td>
          <td> The group provided in the channelId field can't be located </td>
          <td></td>
        </tr>
      </table>
    </div>
  );
};

export default ChannelListMembers;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ChatReceiveMessage = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
            "name": "chat.receiveMessage",
            "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
            "message": {
                "from": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
                "to": "b:8c67055b-202f-4ffe-a18f-1c4553a50175",
                "text": "<message text>"
            }
        }`,
    },
  ];
  return (
    <div className="container">
      <h1>chat.receiveMessage</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is sent to the{" "}
          <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
            {" "}
            event listenter URL{" "}
          </a>
          when a message is sent to an app's bot.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>
          <tr>
            <td>message</td>
            <td>
              A <Link to="/display/flockos/Message"> Message </Link>
              object
            </td>
            <td>The message that was sent to the app's bot</td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default ChatReceiveMessage;

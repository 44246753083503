import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ChannelGetInfo = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "id": "g:f71eec8b-b94a-45af-a698-cc139b3d48b1",
        "name": "Channel name",
        "memberCount": 15,
        "profileImage": "https://av.flock.co/9e6d31314638145778a3f51e-full"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>channels.getInfo</h1>
      <p>This method retrieves information related to a channel.</p>

      <section id="section1">
        <h2>Parameters</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>token</td>
            <td>String</td>
            <td>Yes</td>

            <td>
              {" "}
              <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
                {" "}
                Authentication token{" "}
              </a>
              for the user.
            </td>
          </tr>
          <tr>
            <td>channelId</td>
            <td>String</td>
            <td>Yes</td>
            <td>
              <Link to="/display/flockos/Identifiers"> Identifiers </Link>
              of the channel for which information was requested
            </td>
          </tr>
        </table>
      </section>
      <section id="section2">
        <h2>Response</h2>
        <p>
          A single <Link to="/display/flockos/Channel"> Channel </Link>
          object.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section3">
        <h2>Errors</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Error Code</th>
            <th>HTTP Status</th>
            <th>Description</th>
            <th>Additional Parameter</th>
          </thead>
          <tr>
            <td>UnknownChannel</td>
            <td>400</td>
            <td>The group provided in the channelId field can't be located</td>
            <td></td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default ChannelGetInfo;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ClientWidgetAction = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "name": "client.widgetAction",
        "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "userName": "Alyssa P. Hacker",
        "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "chatName": "Ben Bitdiddle",
        "messageUid": "fda6144e-c925-487f-92ed-9e0956a4263c"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>client.widgetAction</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is generated when your app's widget calls any of the
          following <Link to="/display/flockos/flock.js"> flock.js </Link>
          methods:
          <ul>
            <li>openWidget</li>
            <li>openBrowser</li>
          </ul>
        </p>

        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Attribute Name</th>
            <th> Attribute Type</th>
            <th> Attribute Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>

          <tr>
            <td>userName</td>
            <td>String</td>
            <td>Name of the user</td>
          </tr>
          <tr>
            <td>chat</td>
            <td>String</td>
            <td>Id of the user or group whose chat tab was open</td>
          </tr>
          <tr>
            <td>chatName</td>
            <td>String</td>
            <td>Name of the user or group whose chat tab is open</td>
          </tr>

          <tr>
            <td>messageUid</td>
            <td>String</td>
            <td>
              uid of the message for which the attachment widget was opened
            </td>
          </tr>
          <tr>
            <td>attachmentId</td>
            <td>String</td>
            <td>
              id of the attachment for which the attachment widget was opened
            </td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default ClientWidgetAction;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const Methods = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`https://api.flock.co/v1/<method-name>`,
    },
    {
      heading: `Method call using a GET request`,
      code: singleLineString`https://api.flock.co/v1/chat.sendMessage?to=u:513a9f4ae409&text=hello&token=632542db-1a23-4e06-8b39-01f8b0868d57`,
    },
    {
      heading: `Method call using a POST request`,
      code: singleLineString`POST /v1/chat.sendMessage HTTP/1.1
        Host: api.flock.co
        Content-Type: application/x-www-form-urlencoded
        Content-Length: 70
         
        to=u:513a9f4ae409&text=hello&token=632542db-1a23-4e06-8b39-01f8b0868d57`,
    },
    {
      heading: `HTTP response to a method call`,
      code: singleLineString`HTTP/1.1 200 OK
        Content-Type: application/json
        Content-Length: 51
          
        {
            "uid": "d9e054e0-a679-4a7a-9cfa-7299669aa083"
        }
        `,
    },
    {
      heading: `Error response to a method call`,
      code: singleLineString`HTTP/1.1 400 Bad Request
        Content-Type: application/json
          
        {
            "error": "MissingParameter",
            "description": "One or more parameters required for this method call are missing",
            "parameter": "text"
        }`,
    },
  ];
  return (
    <div className="container">
      <h1>Methods</h1>
      <ul>
        <li>
          <a href="/display/flockos/Methods#Methods-Whataremethods?">
            What are methods?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Methods#Methods-ListofmethodsavailableinFlockOS">
            List of methods available in FlockOS
          </a>
        </li>
        <li>
          <a href="/display/flockos/Methods#Methods-CallingConventionsCallingamethod">
            Calling a method
          </a>
        </li>
        <li>
          <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
            Authentication
          </a>
        </li>
        <li>
          <a href="/display/flockos/Methods#Methods-Returnvalues">
            Return Values
          </a>
        </li>
        <li>
          <a href="/display/flockos/Methods#Methods-Errors">Errors</a>
        </li>
      </ul>
      <section id="Methods-Whataremethods?">
        <h2>What are methods?</h2>
        <p>
          Methods are the basic constructs that allow you to perform actions or
          fetch information from Flock. For example, Flock has methods for each
          of the sample use cases listed below, among others.
        </p>
        <ul>
          <li>Sending a message to a user or a group;</li>
          <li>Fetching the list of a user's contacts;</li>
          <li>Fetching information about a user or a group.</li>
        </ul>
        <p>
          Similar to methods and function calls in any programming language,
          Flock methods too receive a list of named parameters, and may
          optionally return a value.
        </p>
      </section>
      <section id="Methods-ListofmethodsavailableinFlockOS">
        <h2>List of methods available in FlockOS</h2>
        <ul>
          <li>
            <Link to="/display/flockos/channels.addMembers">
              channels.addMembers
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/channels.create">channels.create</Link>
          </li>
          <li>
            <Link to="/display/flockos/channels.getInfo">channels.getInfo</Link>
          </li>
          <li>
            <Link to="/display/flockos/channels.list">channels.list</Link>
          </li>
          <li>
            <Link to="/display/flockos/channels.listMembers">
              channels.listMembers
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/channels.removeMembers">
              channels.removeMembers
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/chat.fetchMessages">
              chat.sendMessage
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/chat.sendMessage">
              chat.fetchMessages
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/roster.listContacts">
              roster.listContacts
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/users.getInfo">users.getInfo</Link>
          </li>
          <li>
            <Link to="/display/flockos/users.getPublicProfile">
              users.getPublicProfile
            </Link>
          </li>
        </ul>
      </section>
      <section id="Methods-CallingConventionsCallingamethod">
        <h2>Calling a method</h2>
        <p>Flock provides an HTTPS endpoint for each API method.</p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />

        <p>
          To call a method, send an HTTP GET or POST request to the method
          endpoint, alongwith its parameters (can be either GET or POST).
        </p>
        <p>
          For example, to call{" "}
          <Link to="/display/flockos/chat.sendMessage">chat.sendMessage</Link>,
          you can either send a GET request to following URL:
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />

        <p>
          (See{" "}
          <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
            Authentication{" "}
          </a>{" "}
          for more on the token parameter.)
        </p>
        <p>
          Or you can send a POST request to
          https://api.flock.co/v1/chat.sendMessage:
        </p>
        <Codeblock heading={codeBlocks[2].heading} code={codeBlocks[2].code} />

        <p>
          If a method call is successful, the status code 200 is returned in the
          HTTP response.
        </p>
      </section>
      <section id="Methods-AuthenticationAuthentication">
        <h2>Authentication</h2>
        <p>
          To call a method, an app will need to pass an authentication token on
          behalf of the user (either a normal user or a{" "}
          <a href="/display/flockos/Bots"> bot </a> ).
        </p>
        <p>
          For normal users, you receive this token in the{" "}
          <Link to="/display/flockos/app.install"> app.install </Link>
          event.
        </p>
        <p>
          For a bot, you can see the token in the developer dashboard after you
          enable it.
        </p>
        <p>
          This token is passed as the token parameter in the HTTP request that
          calls the method.
        </p>
      </section>
      <section id="Methods-Returnvalues">
        <h2>Return values</h2>
        <p>
          Yes, methods may optionally return a value. A response value is
          returned as a JSON document in the response body of the HTTP request.
        </p>
        <Codeblock heading={codeBlocks[3].heading} code={codeBlocks[3].code} />
      </section>
      <section id="Methods-Errors">
        <h2>Errors</h2>
        <p>
          If an error occurs while processing your request, an HTTP status code
          other than 200 is returned. Further information about the error is
          provided by a JSON in the HTTP response body.
        </p>
        <Codeblock heading={codeBlocks[4].heading} code={codeBlocks[4].code} />

        <p>Two fields will always be present when an error is returned:</p>
        <ul>
          <li>error – an error code</li>
          <li>description – a human readable description of the error</li>
        </ul>
        <p>
          Depending on the error code, additional fields may also be present in
          the JSON if they provide more context. For example, parameter is an
          additional field in the response above.
        </p>
        <p>
          Below is a list of generic error codes that can be returned in
          response to a method call. Note that some methods can return other
          errors in addition to these. See individual method pages for more
          information.
        </p>
        <table className="custom-table" border="1">
          <thead>
            <th>Error Code</th>
            <th>HTTP Status</th>
            <th>Description</th>
            <th>Additional Attribute</th>
          </thead>
          <tr>
            <td>InvalidRequestData </td>
            <td>400</td>
            <td>The request was malformed </td>
            <td></td>
          </tr>
          <tr>
            <td>InvalidParameter </td>
            <td>400</td>
            <td>A parameter for the method call is missing or invalid</td>
            <td>parameter – name of the missing paramenter</td>
          </tr>

          <tr>
            <td>InvalidToken </td>
            <td>403</td>
            <td>An invalid authentication token was sent with the request </td>
            <td></td>
          </tr>

          <tr>
            <td>AppDisabled </td>
            <td>403</td>
            <td>App has been disabled for this user</td>
            <td>disabledBy – one of user or team</td>
          </tr>

          <tr>
            <td>NoSuchMethod </td>
            <td>404</td>
            <td>The method named in the request URL does not exist </td>
            <td></td>
          </tr>

          <tr>
            <td>HTTPMethodNotAllowed </td>
            <td>405</td>
            <td>An HTTP method other than POST was used </td>
            <td></td>
          </tr>

          <tr>
            <td>RequestTimeout </td>
            <td>408</td>
            <td>
              A timeout occured before the server could receive the complete
              request from the app{" "}
            </td>
            <td></td>
          </tr>

          <tr>
            <td>TooManyRequests </td>
            <td>429</td>
            <td>Rate limit reached </td>
            <td></td>
          </tr>
          <tr>
            <td>InternalServerError </td>
            <td>500</td>
            <td>An unexpected condition was encountered </td>
            <td></td>
          </tr>
          <tr>
            <td>ServiceUnavailable </td>
            <td>503</td>
            <td>The API service is unavailable at this time </td>
            <td></td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default Methods;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
import img1 from "../assets/clientMessage.png";

const ClientMessageAction = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "name": "client.messageAction",
        "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "userName": "Alyssa P. Hacker",
        "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "chatName": "Ben Bitdiddle",
        "messageUids": [ "fd4877b719b1", "59e2da001af2" ]
        "messages": [ { "from": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
                        "to": "u:cfc76545-3400-4864-892a-513a9f4ae409",
                        "uid": "fd4877b719b1",
                        "text": "Hello",
                        "timestamp": 1481808450532 },
                      { "from": "u:cfc76545-3400-4864-892a-513a9f4ae409",
                        "to": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
                        "uid": "59e2da001af2",
                        "text": "Hello again",
                        "timestamp": 1481808450533 } ]
    }`,
    },
    {
      heading: ``,
      code: singleLineString`{
        "text": "<toaster message>"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>client.messageAction</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is triggered when a user presses the message action button.
        </p>

        <p>
          If your app enables this button, it will be provided next to every
          message. It will also be provided if a user selects multiple messages.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <p>
          In addition to the chat in which the message action button was
          pressed, Flock also provides a list of messages uids for which the
          button was pressed. If your app needs more information about the
          messages, it should call the{" "}
          <Link to="/display/flockos/chat.fetchMessages">
            {" "}
            chat.fetchMessages{" "}
          </Link>
          method.
        </p>
        <p>
          Optionally, Flock might provide the entire{" "}
          <Link to="/display/flockos/Message"> Message </Link>
          object alongwith the event itself, so you can skip calling
          <Link to="/display/flockos/chat.fetchMessages">
            {" "}
            chat.fetchMessages{" "}
          </Link>
          if the messages attribute is present. Note that this attribute is
          strictly optional however (especially when many messages have been
          selected). Your app should not depend on the presence of this
          attribute.
        </p>
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Attribute Name</th>
            <th>Attribute Type</th>
            <th>Attribute Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>
          <tr>
            <td>userName</td>
            <td>String</td>
            <td>Name of the User</td>
          </tr>
          <tr>
            <td>chat</td>
            <td>String</td>
            <td>Id of the user or group whose chat tab was open</td>
          </tr>
          <tr>
            <td>chatName</td>
            <td>String</td>
            <td>Name of the user or group whose chat tab is open</td>
          </tr>

          <tr>
            <td>messageUids </td>
            <td>Array</td>
            <td>
              An array of <Link to="/display/flockos/Message"> Message </Link>
              uids
            </td>
          </tr>

          <tr>
            <td>messages </td>
            <td>Array</td>
            <td>
              <p>
                An array of Message objects. This is an optional attribute that
                may not always be present.
              </p>
              <p>
                If present, this allows you to skip calling{" "}
                <Link to="/display/flockos/chat.fetchMessages">
                  {" "}
                  chat.fetchMessage{" "}
                </Link>
                .
              </p>
            </td>
          </tr>
        </table>
      </section>
      <section id="section3">
        <h2>Response</h2>
        <p>
          Your app can respond with a text value – this should be a short (upto
          100 chars) string that is displayed to the user who pressed the
          button.
        </p>

        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      </section>
      <img src={img1} style={{ width: "50%" }} alt="" />
    </div>
  );
};

export default ClientMessageAction;

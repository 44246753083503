import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/attachments/39584225/39584229.png";
import img2 from "../assets/attachments//39584225/40239336.png";

const SlashCommands = () => {
  const tableData = [
    {
      id: 1,
      field: "Name of command",
      mandatory: "Yes",
      description: "The name of the slash command",
    },
    {
      id: 2,
      field: "Short description",
      mandatory: "Yes",
      description:
        "A short description of what the slash command does. This will be shown to the user against the slash command",
    },
    {
      id: 3,
      field: "Syntax hint",
      mandatory: "No",
      description: "A hint to the user on how to use the command",
    },
    {
      id: 4,
      field: "Select action for Slash command",
      mandatory: "Yes",
      description:
        "Select an appropriate client action for this slash command.",
    },
  ];
  return (
    <div className="container">
      <h1>Slash Commands</h1>
      <ul>
        <li>
          <a href="/display/flockos/Slash+Commands#SlashCommands-Whatisaslashcommand?">
            {" "}
            What is a slash command?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Slash+Commands#SlashCommands-Whatistheformatofaslashcommand?">
            {" "}
            What is the format of a slash command?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Slash+Commands#SlashCommands-createHowdoIcreateaslashcommand?">
            {" "}
            How do I create a slash command?
          </a>
        </li>
      </ul>
      <section id="SlashCommands-Whatisaslashcommand?">
        <h2>What is a slash command?</h2>
        <p>
          Slash commands can be used by your users to send a command to your
          app. For example,
          <ul>
            <li>
              The Appear.in app has a /video slash command which sends a
              notification to the <Link to="http://appear.in/">Appear.in</Link>{" "}
              app to generate a video conference URL within that chat tab
            </li>
            <li>
              The Todo app has a /todo slash command which provides the user a
              quick way to create to-dos from a conversation
            </li>
          </ul>
        </p>
        <p>
          Your app can use a slash command when you want to provide your user a
          CLI to perform some action. Note that the user can perform the same
          action using a{" "}
          <Link to="/display/flockos/Chat+Tab+Buttons"> Chat Tab Buttons </Link>
          or <Link to="/display/flockos/Widgets"> Widget </Link>. However power
          users may prefer using a slash command. A user can always see the
          entire list of slash commands available from various apps by typing
          "/" -
        </p>
      </section>
      <img style={{ width: "30%" }} src={img1} alt="" />
      <section id="SlashCommands-Whatistheformatofaslashcommand?">
        <h2>What is the format of a slash command?</h2>
        <p>
          A slash command comprises of a forward slash followed by the command
          name. Your slash command may also accept additional text.
        </p>

        <ul>
          <li>
            commandName should be a string comprising of alphanumeric
            characters, or the symbols underscore or hyphen. It must begin with
            an alphanumeric character.
          </li>
          <li>
            There should be no spaces between the slash and the command name.
          </li>
          <li>
            There must be one or more spaces between the command name and the
            additional text that follows it.
          </li>
        </ul>
      </section>
      <section id="SlashCommands-createHowdoIcreateaslashcommand?">
        <h2>How do I create a slash command?</h2>
        <ol>
          <li>
            Go through
            <Link to="/display/flockos/Creating+an+App"> Creating an App </Link>
            to setup your app in the developer dashboard.
          </li>
          <li>In the Advanced Info section, enable the slash command.</li>
          <img src={img2} alt="" />
          <p>Fill in the info below:</p>
          <table className="custom-table">
            <thead>
              <tr>
                <th>Field</th>
                <th>Mandatory</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((item) => (
                <tr key={item.id}>
                  <td>{item.field}</td>
                  <td>{item.mandatory}</td>
                  <td>{item.description}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <li>
            Save these changes, then follow the steps in{" "}
            <a href="/display/flockos/Creating+an+App#CreatinganApp-AppInstallationHowdoIinstallandtestmyapp?">
              {" "}
              app installation{" "}
            </a>{" "}
            and install this app into your own Flock account.
          </li>
          <li>
            Once the app is installed, whenever a user enters a slash command,
            the event{" "}
            <Link to="/display/flockos/client.slashCommand">
              {" "}
              client.slashCommand
            </Link>{" "}
            will either be sent to the{" "}
            <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
              {" "}
              events listenere URL{" "}
            </a>
            or appended to the{" "}
            <Link to="/display/flockos/Events"> widget or browser URL </Link>,
            depending on the client action you selected in step 2.
          </li>
          <li>
            If you want to send a message back to the user upon receiving the
            event or opening the widget or browser URL, see{" "}
            <Link to="/display/flockos/Sending+Messages">
              {" "}
              Sending Messages{" "}
            </Link>
            .
          </li>
        </ol>
      </section>
    </div>
  );
};

export default SlashCommands;

import React from "react";
import { singleLineString } from "../Helpers/StringFormatter";

import { Link } from "react-router-dom";
import Codeblock from "./Codeblock";
import img1 from "../assets/attachments/39584204/39584206.png";

const Bots = () => {
  const codeBlocks = [
    {
      heading: `Send a message using a bot`,
      code: singleLineString`POST /v1/chat.sendMessage HTTP/1.1
      Host: api.flock.co
      Content-Type: application/x-www-form-urlencoded
      Content-Length: 70
        
      to=u:<user>&text=hello&token=<bot token>`,
    },
    {
      heading: `Receive a message sent to a bot`,
      code: singleLineString`{
        "name": "chat.receiveMessage",
        "message": {
            "from": "u:<user>",
            "to": "u:<bot>",
            "text": "hello"
        }
    }`,
    },
    {
      heading: `Send a message to a channel using a bot`,
      code: singleLineString`POST /v1/chat.sendMessage HTTP/1.1
        Host: api.flock.co
        Content-Type: application/x-www-form-urlencoded
        Content-Length: 70
          
        to=g:<channel>&text=hello&token=<bot token>&onBehalfOf=u:<user>`,
    },
  ];
  return (
    <div className="container">
      <h1>Bots</h1>
      <ul>
        <li>
          <a href="/display/flockos/Bots#Bots-WhatcanIuseabotfor?">
            What can I use a bot for?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Bots#Bots-HowdoIgetabotformyapp?">
            How do I get a bot for my app?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Bots#Bots-HowdoIsetmybot'snameandavatar?">
            How do I set my bot's name and avatar?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Bots#Bots-Howwillmyapp'sbotbevisibletomyusers?">
            How will my app's bot be visible to my users?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Bots#Bots-HowdoIsendandreceivemessagesusingbots?">
            How do I send and receive messages using bots?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Bots#Bots-ChannelCanabotjoinachannelandsendmessagestoit?">
            Can a bot join a channel and send messages to it?{" "}
          </a>
        </li>
      </ul>
      <p>
        Each app optionally gets its own bot. Bots have a name and an avatar,
        and you can use them to send and receive messages to and from your
        users.
      </p>
      <section id="Bots-WhatcanIuseabotfor?">
        <h2>What can I use a bot for?</h2>
        <p>What you use a bot for is limited only by your imagination:</p>
        <ul>
          <li>A reminder app can use a Reminder bot to send reminders</li>
          <li>
            A travel bot can let users search for and book flights through chat
          </li>
          <li>
            You can create a support bot to receive questions and provide
            answers to users
          </li>
        </ul>
      </section>
      <section id="Bots-HowdoIgetabotformyapp?">
        <h2>How do I get a bot for my app?</h2>
        <p>
          You can enable your app's bot during the{" "}
          <Link to="/display/flockos/Creating+an+App">
            {" "}
            app creation process{" "}
          </Link>{" "}
          in the Advanced info section. You will see your bot's{" "}
          <Link to="/display/flockos/Identifiers"> identifier </Link>
          and bot token on enabling your Bot. Any messages sent to your app's
          bot will be sent at this identifier. You can use the token to make{" "}
          <Link to="/display/flockos/Methods"> methods </Link>
          calls on behalf of the bot e.g. sending messages to a user.
        </p>
        <img src={img1} alt="" />
      </section>
      <section id="Bots-HowdoIsetmybot'snameandavatar?">
        <h2>How do I set my bot's name and avatar?</h2>
        <p>
          Your bot will automatically assume your App's name followed by "Bot"
          and your App's icon as its default avatar
        </p>
      </section>
      <section id="Bots-Howwillmyapp'sbotbevisibletomyusers?">
        <h2>How will my app's bot be visible to my users?</h2>
        <p>
          Once you enable your bot, any users who install or have installed your
          app will see your app's bot in their contact list.
        </p>
      </section>
      <section id="Bots-HowdoIsendandreceivemessagesusingbots?">
        <h2>How do I send and receive messages using bots?</h2>
        <p>
          You can send a message using the{" "}
          <Link to="/display/flockos/chat.sendMessage"> chat.sendMessage </Link>{" "}
          method. Authenticate the method call using your bot's token.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <p>
          To receive a message, listen for the{" "}
          <Link to="/display/flockos/chat.receiveMessage">
            {" "}
            chat.receiveMessage{" "}
          </Link>{" "}
          event on your{" "}
          <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
            {" "}
            events listener URL{" "}
          </a>
          .
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      </section>
      <section id="Bots-ChannelCanabotjoinachannelandsendmessagestoit?">
        <h2>Can a bot join a channel and send messages to it?</h2>
        <p>
          Bots cannot join channels, but they can send messages to it. While
          calling{" "}
          <Link to="/display/flockos/chat.sendMessage"> chat.sendMessage </Link>
          , set to to the channel id, and token to the bot token.
        </p>
        <p>
          You also need to set onBehalfOf to the id of a user who has installed
          the app, is a member of the channel and has permission to post
          messages to it. Since bots cannot join channels themselves, the user
          whose id is provided in onBehalfOf is used to check for channel
          membership and posting permissions. This means that an app's bot can
          only post messages to channels where at least one user who has
          installed the app has permission to post messages.
        </p>
        <Codeblock heading={codeBlocks[2].heading} code={codeBlocks[2].code} />
      </section>
    </div>
  );
};

export default Bots;

// Filename - components/SidebarData.js

import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";
import * as RiIcons from "react-icons/ri";

export const SidebarData = [
  {
    title: "FlockOS Overview",
    path: "/display/flockos/FlockOS+Overview",
    //icon: <IoIcons.IoMdHelpCircle />,
  },
  {
    title: "Tutorial",
    path: "/display/flockos/Tutorial",
    //icon: <IoIcons.IoMdHelpCircle />,
  },
  {
    title: "Creating an app",
    path: "/display/flockos/Creating+an+App",
    //icon: <IoIcons.IoMdHelpCircle />,
  },
  {
    title: "Messaging",
    path: "/display/flockos/Messaging",
    //icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
    iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,

    subNav: [
      {
        title: "Sending Messages",
        path: "/display/flockos/Sending+Messages",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Sending Attachments",
        path: "/display/flockos/Sending+Attachments",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Bots",
        path: "/display/flockos/Bots",
        //icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "Integrations",
    path: "/display/flockos/Integrations",
    //icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
    iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,

    subNav: [
      {
        title: "Slash Commands",
        path: "/display/flockos/Slash+Commands",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Attachment Pickers",
        path: "/display/flockos/Attachment+Pickers",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Launcher Buttons",
        path: "/display/flockos/Launcher+Buttons",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Chat Tab Buttons",
        path: "/display/flockos/Chat+Tab+Buttons",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Message Action Buttons",
        path: "/display/flockos/Message+Action+Buttons",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "URL Preview",
        path: "/display/flockos/URL+Preview",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Widgets",
        path: "/display/flockos/Widgets",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Client Actions",
        path: "/display/flockos/Client+Actions",
        //icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "API Reference",
    path: "/display/flockos/API+Reference",
    //icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
    iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,

    subNav: [
      {
        title: "Identifiers",
        path: "/display/flockos/Identifiers",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Methods",
        path: "/display/flockos/Methods",
        //icon: <FaIcons.FaEnvelopeOpenText />,

        iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
        iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,
        subNav: [
          {
            title: "channel.addMembers",
            path: "/display/flockos/channels.addMembers",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "channel.create",
            path: "/display/flockos/channels.create",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "channel.getInfo",
            path: "/display/flockos/channels.getInfo",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "channel.list",
            path: "/display/flockos/channels.list",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "channel.listMembers ",
            path: "/display/flockos/channels.listMembers",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "channel.removeMembers",
            path: "/display/flockos/channels.removeMembers",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "chat.fetchMessages",
            path: "/display/flockos/chat.fetchMessages",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "chat.sendMessage",
            path: "/display/flockos/chat.sendMessage",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "roster.listContacts",
            path: "/display/flockos/roster.listContacts",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "users.getInfo",
            path: "/display/flockos/users.getInfo",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "users.getPublicProfile",
            path: "/display/flockos/users.getPublicProfile",
            //icon: <IoIcons.IoIosPaper />,
          },
        ],
      },
      {
        title: "Objects",
        path: "/display/flockos/Objects",
        //icon: <FaIcons.FaEnvelopeOpenText />,

        iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
        iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,
        subNav: [
          {
            title: "Attachment",
            path: "/display/flockos/Attachment",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "Channel",
            path: "/display/flockos/Channel",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "ChannelMember",
            path: "/display/flockos/ChannelMember",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "ChannelMemberStatus",
            path: "/display/flockos/ChannelMemberStatus",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "Message",
            path: "/display/flockos/Message",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "PublicProfile",
            path: "/display/flockos/PublicProfile",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "User",
            path: "/display/flockos/User",
            //icon: <IoIcons.IoIosPaper />,
          },
        ],
      },

      {
        title: "Events",
        path: "/display/flockos/Events",
        //icon: <IoIcons.IoIosPaper />,
        iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
        iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,
        subNav: [
          {
            title: "app.install",
            path: "/display/flockos/app.install",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "app.uninstall",
            path: "/display/flockos/app.uninstall",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "chat.generateUrlPreview",
            path: "/display/flockos/chat.generateUrlPreview",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "chat.receiveMessage",
            path: "/display/flockos/chat.receiveMessage",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "client.flockmlAction",
            path: "/display/flockos/client.flockmlAction",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "client.messageAction",
            path: "/display/flockos/client.messageAction",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "client.openAttachment",
            path: "/display/flockos/client.openAttachmentWidget",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "client.pressButton",
            path: "/display/flockos/client.pressButton",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "client.slashCommand",
            path: "/display/flockos/client.slashCommand",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "client.widgetAction",
            path: "/display/flockos/client.widgetAction",
            //icon: <IoIcons.IoIosPaper />,
          },
        ],
      },
      {
        title: "Event Tokens",
        path: "/display/flockos/Event+Tokens",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "FlockML",
        path: "/display/flockos/FlockML",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "flock.js",
        path: "/display/flockos/flock.js",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Deprecated",
        path: "/display/flockos/Deprecated",
        //icon: <IoIcons.IoIosPaper />,
        iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
        iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,
        subNav: [
          {
            title: "Group",
            path: "/display/flockos/Group",
            //icon: <IoIcons.IoIosPaper />,
          },

          {
            title: "groups.getInfo",
            path: "/display/flockos/groups.getInfo",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "groups.getMembers",
            path: "/display/flockos/groups.getMembers",
            //icon: <IoIcons.IoIosPaper />,
          },
          {
            title: "groups.list",
            path: "/display/flockos/groups.list",
            //icon: <IoIcons.IoIosPaper />,
          },
        ],
      },
    ],
  },
  {
    title: "Webhooks",
    path: "/display/flockos/Webhooks",
    //icon: <FaIcons.FaEnvelopeOpenText />,

    iconClosed: <RiIcons.RiArrowDownSFill style={{ color: "black" }} />,
    iconOpened: <RiIcons.RiArrowUpSFill style={{ color: "black" }} />,

    subNav: [
      {
        title: "Create An Incoming Webhook",
        path: "/display/flockos/Create+An+Incoming+Webhook",
        //icon: <IoIcons.IoIosPaper />,
      },
      {
        title: "Create An Outgoing Webhook",
        path: "/display/flockos/Create+An+Outgoing+Webhook",
        //icon: <IoIcons.IoIosPaper />,
      },
    ],
  },
  {
    title: "SDKs",
    path: "/display/flockos/SDKs",
    //icon: <IoIcons.IoMdHelpCircle />,
  },
  {
    title: "Help and Support",
    path: "/display/flockos/Help+and+Support",
    //icon: <IoIcons.IoMdHelpCircle />,
  },
];

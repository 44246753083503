import React from "react";
import { Link } from "react-router-dom";
const UsersGetPublicProfile = () => {
  return (
    <div className="container">
      <h1>users.getPublicProfile</h1>
      <p>Use this method to lookup the PublicProfile of any Flock user.</p>
      <p>
        The method endpoint is https://api.flock.co/v1/users.getPublicProfile
        and follows the method calling conventions.
      </p>
      <h2>Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              {" "}
              Authentication token{" "}
            </a>
            for the user on whose behalf the app is looking up the public
            profile
          </td>
        </tr>
        <tr>
          <td>userId</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            <Link to="/display/flockos/Identifiers"> Identifiers </Link>
            of the user whose public profile needs to be looked up
          </td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>
        A <Link to="/display/flockos/PublicProfile"> PublicProfile </Link>
        object.
      </p>
    </div>
  );
};

export default UsersGetPublicProfile;

import React from "react";

const Channel = () => {
  return (
    <div className="container">
      <h1>Channel</h1>
      <h2>Description</h2>
      <p>This object describes metadata for a channel.</p>
      <h2>Object Attributes</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>id </td>
          <td>String</td>
          <td>Yes</td>
          <td> Id of this channel</td>
        </tr>
        <tr>
          <td>name </td>
          <td>String</td>
          <td>Yes</td>
          <td> The name of this channel</td>
        </tr>
        <tr>
          <td>memberCount </td>
          <td>Number</td>
          <td>Yes</td>
          <td> Count of the channel's members</td>
        </tr>
        <tr>
          <td>profileImage </td>
          <td>String </td>
          <td>String </td>
          <td> URL for the channel's profile image</td>
        </tr>
      </table>
    </div>
  );
};

export default Channel;

import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/attachments/39584209/39584219.png";
import img2 from "../assets/attachments/39584209/39584221.png";
import img3 from "../assets/attachments/39584209/40239341.png";
const AttachmentPicker = () => {
  const tableData = [
    {
      id: 1,
      field: "Tooltip text",
      mandatory: "Yes",
      description:
        "This will be shown against your attachment picker button to the user",
    },
    {
      id: 2,
      field: "Select action for attachment picker",
      mandatory: "Yes",
      description:
        "Select an appropriate client action for the attachment picker.",
    },
  ];
  return (
    <div className="container">
      <h1>Attachment Pickers</h1>
      <ul>
        <li>
          <a href="/display/flockos/Attachment+Pickers#AttachmentPickers-Whatisanattachmentpicker?">
            What is an attachment picker?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Attachment+Pickers#AttachmentPickers-Whataretheavailableactionsforanattachmentpickerbutton?">
            What are the available actions for an attachment picker button?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Attachment+Pickers#AttachmentPickers-createHowdoIcreateanattachmentpicker?">
            How do I create an attachment picker?
          </a>
        </li>
      </ul>
      <section id="AttachmentPickers-Whatisanattachmentpicker?">
        <h2>What is an attachment picker?</h2>
        <p>
          An attachment picker allows users to create and send{" "}
          <Link to="/display/flockos/Message"> Message </Link>
          with
          <Link to="/display/flockos/Attachment"> Attachment </Link>. For
          example,
        </p>
        <ul>
          <li>
            The poll app allows a user to attach an opinion poll to a message.
          </li>
          <li>
            The notes app allows a user to attach a rich media note to a
            message.
          </li>
        </ul>
        <p>
          To create an attachment, the user clicks on an attachment picker
          button. The image below shows the Attachment picker bar on the Flock
          desktop client along with the poll app attachment picker button{" "}
        </p>
        <img style={{ width: "30%" }} src={img1} alt="" />
        <p>
          Similarly the Flock mobile clients also provide a hook for the
          Attachment picker button -
        </p>
        <img style={{ width: "30%" }} src={img2} alt="" />
        <p>
          Clicking on the polls app button in the attachment picker on mobile or
          desktop, opens a{" "}
          <Link to="/display/flockos/Widgets"> modal widget, </Link> which the
          user then uses to create a poll.
        </p>
      </section>
      <section id="AttachmentPickers-Whataretheavailableactionsforanattachmentpickerbutton?">
        <h2>What are the available actions for an attachment picker button?</h2>
        <p>
          When a user presses the attachment picker button, you can configure it
          to do one of the below -
        </p>
        <ul>
          <li>Launch a modal widget</li>
          <li>Launch a sidebar widget</li>
          <li>Launch a browser</li>
        </ul>
        <p>
          Alongwith launching any of the above, a{" "}
          <Link to="/display/flockos/client.pressButton">
            {" "}
            client.pressButton{" "}
          </Link>
          event will also be passed as a parameter to the web app URL loaded in
          any of the above widgets or browser
        </p>
      </section>
      <section id="AttachmentPickers-createHowdoIcreateanattachmentpicker?">
        <h2>How do I create an attachment picker?</h2>
        <ol>
          <li>
            <Link to="/display/flockos/Creating+an+App"> Creating an App </Link>
            Go through to setup your app in the developer dashboard.
          </li>
          <li>
            In the Advanced Info section, enable the attachment picker.
            <img src={img3} alt="" />
            <p>Fill in the info below:</p>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Mandatory</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.field}</td>
                    <td>{item.mandatory}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </li>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App">
              {" "}
              app installation{" "}
            </Link>
            and install this app into your own Flock account.
          </li>
          <li>
            Whenever a user presses the attachment picker button, the event
            <Link to="/display/flockos/client.pressButton">
              {" "}
              client.pressButton{" "}
            </Link>
            along with a{" "}
            <Link to="/display/flockos/Event+Tokens"> Event tokens </Link>
            will be appended to the
            <Link to="/display/flockos/Client+Actions"> Client Actions </Link>
            corresponding you selected and sent to your service.
          </li>
          <li>
            After verifying the validation token, you should present the
            interface for the user to create an attachment.
          </li>
          <li>
            Once the user has finished creating the attachment you can send it
            to back to Flock to the chat tab from where the user clicked on your
            attachment picker by creating a{" "}
            <Link to="/display/flockos/Message"> Message </Link>
            object, with an{" "}
            <Link to="/display/flockos/Attachment"> attachment </Link>
            object, and sending the message to Flock via the{" "}
            <Link to="/display/flockos/chat.sendMessage">
              {" "}
              chat.sendMessage{" "}
            </Link>
            method call.
          </li>
          <li>
            <Link to="/display/flockos/Creating+an+App">
              {" "}
              Publish your App{" "}
            </Link>
            to the app store once you are done testing it.
          </li>
        </ol>
      </section>
    </div>
  );
};

export default AttachmentPicker;

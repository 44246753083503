import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const Message = () => {
  const codeBlocks = [
    {
      heading: `A Plain Text Message`,
      code: singleLineString`{
        "from": "u:8c67055b-202f-4ffe-a18f-1c4553a50175",
        "to": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "id": "bfbf7700",
        "uid": "1454595241007-6QpQpe-mh104",
        "text": "A plain text message",
        "timestamp": 1481808450532
    }`,
    },
    {
      heading: `A Message with an Attachment`,
      code: singleLineString`{
        "from": "u:8c67055b-202f-4ffe-a18f-1c4553a50175",
        "to": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "id": "bfbf7700",
        "uid": "1454595241007-6QpQpe-mh104"
        "text": "A rich text message",
        "timestamp": 1481808450532
        "attachments": [  
          {
            "title": "A title",
            "description": "A little longer description",
            "views": {
                "widget": { "src": "<widget url>", "width": 400, "height": 400 },
                "flockml": "<flockml>some content</flockml>"
            }
        }
     ]
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>Message</h1>
      <p>Description</p>
      <p>
        A message is sent whenever a user (or a bot) wants to communicate with
        another user or a group.
      </p>

      <section id="section1">
        <h2>Examples</h2>

        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <p>
          Besides text, messages can also send rich content as an{" "}
          <Link to="/display/flockos/Attachment"> Attachment </Link>.
        </p>

        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      </section>
      <section id="section2">
        <h2>Object Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>from</td>
            <td>String</td>
            <td>Yes</td>
            <td> Id of the user who sent the message.</td>
          </tr>
          <tr>
            <td>to</td>
            <td>String</td>
            <td>Yes</td>
            <td> Id of the user or group to whom the message was sent.</td>
          </tr>
          <tr>
            <td>id</td>
            <td>String</td>
            <td>No</td>
            <td>Client id for the message</td>
          </tr>
          <tr>
            <td>uid</td>
            <td>String</td>
            <td>Yes</td>
            <td> A unique server id for the message.</td>
          </tr>
          <tr>
            <td>timestamp</td>
            <td>Number</td>
            <td>Yes</td>
            <td>
              Server timestamp of the message. Number of milliseconds since
              epoch.
            </td>
          </tr>
          <tr>
            <td>text</td>
            <td>String</td>
            <td>Yes</td>
            <td> Message text added by the user.</td>
          </tr>
          <tr>
            <td>flockml</td>
            <td>String</td>
            <td>No</td>
            <td>
              {" "}
              <Link to="/display/flockos/FlockML"> FlockML </Link>
              content for the message.
            </td>
          </tr>

          <tr>
            <td>notification </td>
            <td>String</td>
            <td>No</td>
            <td>
              Text to be shown as the message's notification. (Default is text.)
            </td>
          </tr>
          <tr>
            <td>appId </td>
            <td>String</td>
            <td>No</td>
            <td>Id of the app that sent the message.</td>
          </tr>
          <tr>
            <td>mentions </td>
            <td>Array</td>
            <td>No</td>
            <td>
              <p>
                An array of user ids indicating users who have been mentioned in
                the message.
              </p>
              <p>Only relevant for group messages.</p>
            </td>
          </tr>
          <tr>
            <td>sendAs </td>
            <td>Object (Elaborated below)</td>
            <td>No</td>
            <td>
              This field is used this if the sender would want to display
              another name and image as the sender.
            </td>
          </tr>
          <tr>
            <td>attachments </td>
            <td>
              {" "}
              Array of{" "}
              <Link to="/display/flockos/Attachment"> Attachment </Link>
            </td>
            <td>No</td>
            <td>
              <p>
                Any rich content to be included with the message is sent as part
                of <Link to="/display/flockos/Attachment"> attachments </Link>.
              </p>
              <p>
                Note: Currently, there is a limitation of one attachment per
                message.
              </p>
            </td>
          </tr>
        </table>
        <p>SendAs</p>
        <p>The attributes of the sendAs field are as follows:</p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>name</td>
            <td>String</td>
            <td>Yes</td>
            <td> Name of the sender.</td>
          </tr>
          <tr>
            <td>profileImage</td>
            <td>String</td>
            <td>Yes</td>
            <td>URL of the sender's profile image.</td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default Message;

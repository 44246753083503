import React from "react";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const DeprecatedGroupList = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`[
        { "id": "g:cfc76545-3400-4864-892a-513a9f4ae409", "name": "<group name 1>" },
        { "id": "g:aee6f08e-05e4-4f49-8e41-6607973ad989", "name": "<group name 2>" }
    ]`,
    },
  ];
  return (
    <div className="container">
      <h1>groups.list</h1>
      <div className="caution">
        <p className="bold">Deprecation Notice</p>

        <p>The method group.list is deprecated. Use channels.list instead. </p>
      </div>
      <h2>Description</h2>
      <p>
        This method retrieves a list of the groups that the user is a member of.
      </p>
      <h2>Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>Authentication token for the user.</td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>A list of Group objects.</p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
    </div>
  );
};

export default DeprecatedGroupList;

import React from "react";
import { Link } from "react-router-dom";
const ChannelMember = () => {
  return (
    <div className="container">
      <h1>ChannelMember</h1>
      <h2>Description</h2>
      <p>
        Provides membership information for each member of a channel. Returned
        by channels.listMembers.
      </p>
      <h2>Object Attributes</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>userId</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            {" "}
            User <Link to="/display/flockos/Identifiers"> Identifiers </Link>
            for the channel member
          </td>
        </tr>

        <tr>
          <td>affiliation</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            <p>Can be one of:</p>
            <ul>
              <li>moderator, if user is a channel admin</li>
              <li>member, if user is a channel member</li>
            </ul>
          </td>
        </tr>
        <tr>
          <td>publicProfile</td>
          <td>
            <Link to="/display/flockos/PublicProfile"> PublicProfile </Link>
          </td>
          <td>No</td>
          <td>
            <p>
              Public profile of the user. This is an optional attribute and is
              included only if specifically requested.
            </p>
            <p>
              For example, this attribute will be set if the user calls
              <Link to="/display/flockos/channels.listMembers">
                {" "}
                channels.listMembers{" "}
              </Link>
              with the showPublicProfile parameter set to true.
            </p>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ChannelMember;

import React from "react";
import { Link } from "react-router-dom";
const Messaging = () => {
  return (
    <div className="container">
      <h1>Messaging</h1>
      <p>
        Messaging lies at the core of any chat application. With FlockOS, your
        apps can send messages (including attachments) on half of a user, or as
        a bot. In addition, it can also receive messages sent to its bot.
      </p>
      <ul>
        <li>
          <Link to="/display/flockos/Sending+Messages">Sending Messages </Link>
        </li>
        <li>
          <Link to="/display/flockos/Sending+Attachments">
            Sending Attachments{" "}
          </Link>
        </li>
        <li>
          <Link to="/display/flockos/Bots">Bots </Link>
        </li>
      </ul>
    </div>
  );
};

export default Messaging;

import React from "react";
import { Link } from "react-router-dom";
const SDKs = () => {
  return (
    <div className="container">
      <h1>SDKs</h1>
      <p>We provide the following SDKs for FlockOS:</p>
      <ul>
        <li>
          <a href="https://github.com/flockchat/flockos-node-sdk">Node.js </a>{" "}
        </li>
        <li>
          <a href="https://github.com/flockchat/javaflock">Java </a>{" "}
        </li>
        <li>
          <a href="https://github.com/flockchat/pyflock">Python </a>{" "}
        </li>
      </ul>
    </div>
  );
};

export default SDKs;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const RosterListContact = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`[
    {
        "id": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "firstName": "<first name 1>",
        "lastName": "<last name 1>"
    },
    {
        "id": "u:d08c8f64-1a64-4cd9-a563-df5079794aa7",
        "firstName": "<first name 2>",
        "lastName": "<last name 2>",
    }
  ]`,
    },
  ];
  return (
    <div className="container">
      <h1>roster.listContacts</h1>
      <p>This method retrieves the list of contacts associated with a user.</p>
      <p>
        The method endpoint is https://api.flock.co/v1/roster.listContacts and
        follows the method calling conventions.
      </p>
      <h2>Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            {" "}
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              {" "}
              Authentication token{" "}
            </a>
            for the user
          </td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>
        A list of{" "}
        <Link to="/display/flockos/PublicProfile"> PublicProfile </Link>
        objects.
      </p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
    </div>
  );
};

export default RosterListContact;

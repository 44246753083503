import React from "react";
import { Link } from "react-router-dom";
import Codeblock from "./Codeblock";
import { singleLineString } from "../Helpers/StringFormatter";

import img1 from "../assets/attachments/39584177/39584185.png";
import img2 from "../assets/attachments/39584177/39584192.png";
import img3 from "../assets/attachments/39584177/39584193.png";
import img4 from "../assets/attachments/39584177/39584190.png";
import img5 from "../assets/attachments/39584177/39584198.png";
import img6 from "../assets/attachments/39584177/39584197.png";
import img7 from "../assets/attachments/39584177/39584178.png";

const SendingAttachments = () => {
  const codeBlocks = [
    {
      heading: `Sending an Attachment`,
      code: singleLineString`POST /v1/chat.sendMessage HTTP/1.1
      Host: api.flock.co
      Content-Type: application/x-www-form-urlencoded
      Content-Length: 70
        
      token=632542db-1a23-4e06-8b39-01f8b0868d57&to=u:513a9f4ae409&text=hello&attachments=
      [{"title":"attachment title","description":"attachment description","views":
      {"widget":{"src":"http:%2F%2Fwww.polls.com%2Fyour-poll-url","width": 400,"height": 400}}}]
      `,
    },
    {
      heading: ``,
      code: singleLineString`{
        "description": "attachment description",
        "views": {
            "widget": { "src": "<widget url>", "width": 400, "height": 400 }
        },
        "buttons": [{
            "name": "<button 1>",
            "icon": "<icon 1 url>",
            "action": { "type": "openWidget", "desktopType": "modal", "mobileType": "modal", "url": "<action url>" },
            "id": "<button id 1>"
        }, {
            "name": "<button 2>",
            "icon": "<icon 2 url>",
            "action": { "type": "openWidget", "desktopType": "sidebar", "mobileType": "modal", "url": "<action url>" }
            "id": "<button id 2>"
        }]
    }
        `,
    },
    {
      heading: ``,
      code: singleLineString`{
            "title": "attachment title",
            "description": "attachment description",
            "views": {
                "widget": { "src": "<widget url>", "width": 400, "height": 400 }
            }
        }
        
        `,
    },
    {
      heading: ``,
      code: singleLineString`{
            "title": "attachment title",
            "description": "attachment description",
            "views": {
                "html": { "inline": "<inline html>", "width": 400, "height": 400 }
            }
        }
          `,
    },
    {
      heading: ``,
      code: singleLineString`{
            "title": "attachment title",
            "description": "attachment description",
            "views": {
                "flockml": "<inline flockml>"
            }
        }
            `,
    },
    {
      heading: ``,
      code: singleLineString`{
            "title": "attachment title",
            "description": "attachment description",
            "views": {
                "image": {
                    "original": { "src": "<image 1 url>", "width": 400, "height": 400 },
                    "thumbnail": { "src": "<image 2 url>", "width": 100, "height": 100 }
                }
            },
        }
              `,
    },
    {
      heading: ``,
      code: singleLineString`{
            "downloads": [
                { "src": "<download 1 url>", "mime": "<mime type>", "filename": "<filename 1>", "size": <bytes> },
            ]
        } 
                `,
    },
  ];
  return (
    <div className="container">
      <h1>Sending Attachments</h1>
      <ul>
        <li>
          <a href="/display/flockos/Sending+Attachments#SendingAttachments-WhatisanAttachment?">
            What is an Attachment?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Sending+Attachments#SendingAttachments-HowcanIsendanattachment?">
            How can I send an attachment?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Sending+Attachments#SendingAttachments-AttachmentButtonsWhatareAttachmentbuttons?">
            What are Attachment buttons?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Sending+Attachments#SendingAttachments-HowdoIcreatedifferentkindsofattachments?">
            How do I create different kinds of attachments?{" "}
          </a>
          <ul>
            <li>
              <a href="/display/flockos/Sending+Attachments#SendingAttachments-Widgets">
                Widgets{" "}
              </a>{" "}
            </li>
            <li>
              <a href="/display/flockos/Sending+Attachments#SendingAttachments-HTML">
                HTML{" "}
              </a>{" "}
            </li>
            <li>
              <a href="/display/flockos/Sending+Attachments#SendingAttachments-FlockML">
                FlockML{" "}
              </a>{" "}
            </li>
            <li>
              <a href="/display/flockos/Sending+Attachments#SendingAttachments-Images">
                Images{" "}
              </a>{" "}
            </li>
            <li>
              <a href="/display/flockos/Sending+Attachments#SendingAttachments-Files">
                Files{" "}
              </a>{" "}
            </li>
          </ul>
        </li>
        <li>
          <a href="/display/flockos/Sending+Attachments#SendingAttachments-Whatistheprecedenceorderforattachmentrendering?">
            What is the precedence order for attachment rendering?
          </a>
        </li>
      </ul>
      <section id="SendingAttachments-WhatisanAttachment?">
        <h2>What is an Attachment?</h2>
        <p>
          An <Link to="/display/flockos/Attachment"> attachment </Link> is rich
          content attached to a{" "}
          <Link to="/display/flockos/Message"> Message </Link>. It can include
          content like dynamic{" "}
          <Link to="/display/flockos/Widgets"> widget </Link>, static HTML,
          <Link to="/display/flockos/FlockML"> FlockML </Link>, images and
          files. Attachments can also have their own buttons. Here are a few
          examples:
        </p>
        <p>
          The Poll app uses a{" "}
          <Link to="/display/flockos/Widgets"> widget </Link>
          to render a live interactive poll inside a chat tab.
        </p>
        <img style={{ width: "25%" }} src={img1} alt="" />
        <p>
          <Link to="/display/flockos/URL+Preview"> URL Preview </Link>
          uses static HTML, for example, to display YouTube previews inline.
        </p>
        <img style={{ width: "25%" }} src={img2} alt="" />
        <p>
          The Trello app uses{" "}
          <Link to="/display/flockos/FlockML"> FlockML </Link>
          to render card information in attachments.
        </p>
        <img style={{ width: "25%" }} src={img3} alt="" />
        <p>
          Unlike <Link to="/display/flockos/Widgets"> Widget </Link> or static
          HTML that can only be rendered inline on desktop, FlockML can be
          rendered inline in mobile too.
        </p>
        <img style={{ width: "20%" }} src={img4} alt="" />
        <p>Your app can also send images using attachments.</p>
        <img style={{ width: "30%" }} src={img5} alt="" />
        <p>Or share files.</p>
        <img style={{ width: "25%" }} src={img6} alt="" />
      </section>
      <section id="SendingAttachments-HowcanIsendanattachment?">
        <h2>How can I send an attachment?</h2>
        <ol>
          <li>
            Since an attachment is a part of a message, to send an attachment
            you will need to send a message. If you haven't, read{" "}
            <Link to="/display/flockos/Sending+Messages">
              {" "}
              Sending Message{" "}
            </Link>
            first.
          </li>

          <li>
            Include the{" "}
            <Link to="/display/flockos/Attachment"> attachment </Link> inside
            the attachments array that is passed to{" "}
            <Link to="/display/flockos/chat.sendMessage">
              {" "}
              chat.sendMessage{" "}
            </Link>
            . The example below shows an attachment with a title, description
            and a widget.. See the{" "}
            <Link to="/display/flockos/Attachment"> attachment </Link> reference
            for more details on the attachment object.
            <Codeblock
              heading={codeBlocks[0].heading}
              code={codeBlocks[0].code}
            />
          </li>
        </ol>
      </section>
      <section id="SendingAttachments-AttachmentButtonsWhatareAttachmentbuttons?">
        <h2>What are Attachment buttons?</h2>
        <p>
          Each <Link to="/display/flockos/Attachment"> attachment </Link>
          can also provide one or more buttons to perform custom actions in
          Flock when they are pressed.
        </p>
        <p>
          For example, the to-do app adds a View button for any attachment that
          it sends.
        </p>
        <img style={{ width: "30%" }} src={img7} alt="" />
        <p>
          To add a button one or more buttons to an attachment, use the buttons
          array.
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
        <p>
          Each button can have a name, icon, an identifier and an action
          associated with it. An attachment button can perform any of these
          three actions:
        </p>
        <ul>
          <li>Open a widget </li>
          <li>Open the browser </li>
          <li>Send an event to the event listener URL </li>
        </ul>
        <p>
          See{" "}
          <a href="/display/flockos/Attachment#Attachment-ActionConfig">
            ActionConfig{" "}
          </a>{" "}
          for details on how an action can be configured for attachment buttons.
        </p>
      </section>
      <section id="SendingAttachments-HowdoIcreatedifferentkindsofattachments?">
        <h2>How do I create different kinds of attachments?</h2>
        <div id="SendingAttachments-Widgets">
          <p className="bold">Widgets</p>
          <p>
            Create an attachment with a widget as follows. Note that an
            attachment widget opens inline in the chat pane only on the desktop
            and not on mobile (where it opens in a full screen modal on click).
            width and height are only used on desktop to fix the dimensions of
            the inline attachment widget. See Widgets for more details.
          </p>
          <Codeblock
            heading={codeBlocks[2].heading}
            code={codeBlocks[2].code}
          />
        </div>
        <div id="SendingAttachments-HTML">
          <p className="bold">HTML</p>
          <p>
            Static HTML included in an attachment shows up inline in desktop. On
            mobile clients, clicking on the attachment opens this HTML in a
            modal webview. Again, width and height are only used on desktop.
          </p>
          <Codeblock
            heading={codeBlocks[3].heading}
            code={codeBlocks[3].code}
          />
        </div>
        <div id="SendingAttachments-FlockML">
          <p className="bold">FlockML</p>
          <p>
            <Link to="/display/flockos/FlockML"> FlockML </Link> is an
            alternative to static HTML that can be shown inline in both desktop
            and mobile.
          </p>
          <Codeblock
            heading={codeBlocks[4].heading}
            code={codeBlocks[4].code}
          />
        </div>
        <div id="SendingAttachments-Images">
          <p className="bold">Images</p>
          <p>
            An image and optionally its thumbnail can be added to an attachment
            as follows. Images are rendered inline on both mobile and desktop
          </p>
          <Codeblock
            heading={codeBlocks[5].heading}
            code={codeBlocks[5].code}
          />
        </div>
        <div id="SendingAttachments-Files">
          <p className="bold">Files</p>
          <p>
            One can also share files using attachments via the downloads
            attribute
          </p>
          <Codeblock
            heading={codeBlocks[6].heading}
            code={codeBlocks[6].code}
          />
        </div>
      </section>

      <section id="SendingAttachments-Whatistheprecedenceorderforattachmentrendering?">
        <h2>What is the precedence order for attachment rendering?</h2>
        <p>
          Flock selects of the following "Views" for display in the chat screen.
          The order of precedence (highest to lowest) is as follows:
        </p>
        <ol>
          <li>Widget</li>
          <li>HTML</li>
          <li>FlockML</li>
          <li>Image</li>
        </ol>
        <p>
          On mobile, widget and HTML are not displayed on the chat screen, so
          only the last two are used. If a user taps on the attachment though,
          widget or HTML are shown in a modal.
        </p>
      </section>
    </div>
  );
};

export default SendingAttachments;

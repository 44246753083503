import React from "react";

const Identifiers = () => {
  return (
    <div className="container">
      <h1>Identifiers</h1>
      <p>
        A unique identifier is assigned to each user and channel in Flock. You
        will need it to, for example,
      </p>
      <ul>
        <li>Send and receive messages to other users or to groups</li>
        <li>Lookup group info or list of members</li>
      </ul>
      <p>
        User identifiers are prefixed by u: whereas channel identifers are
        prefixed by g:.
      </p>
      <ul>
        <li>Example of a user identifier: u:5bd391005e3c5cd3</li>
        <li>Example of a channel identifier: g:c19b7ca2bafbea27</li>
      </ul>
      <p>Bots use user identifiers.</p>
    </div>
  );
};

export default Identifiers;

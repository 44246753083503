import React from "react";
import { Link } from "react-router-dom";
const Objects = () => {
  return (
    <div className="container">
      <h1>Objects</h1>
      <p>
        Objects are the entities that are passed by apps to the Flock platform
        via <Link to="/display/flockos/Methods"> Methods </Link>, and can be
        passed to apps by Flock via{" "}
        <Link to="/display/flockos/Events"> Events </Link>. There are currently
        5 different types of objects that an app can use.
      </p>
      <table className="custom-table" border="1">
        <thead>
          <th>Object</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>
            <Link to="/display/flockos/Message"> Message </Link>
          </td>
          <td>
            The Message object is used to send messages between users and
            groups, and which will appear within the chat window.
          </td>
        </tr>
        <tr>
          <td>
            <Link to="/display/flockos/Attachment"> Attachment </Link>
          </td>
          <td>
            An attachment object is included in any{" "}
            <Link to="/display/flockos/Message"> message </Link>
            that has rich content. Attachments are powerful in that they allow
            you to set multiple properties including title, description, widget,
            FlockML content, downloads, and attachment buttons.
          </td>
        </tr>
        <tr>
          <td>
            <Link to="/display/flockos/User"> User </Link>
          </td>
          <td>An individual user who has an account on Flock.</td>
        </tr>

        <tr>
          <td>
            <Link to="/display/flockos/Group"> Group </Link>
          </td>
          <td>An individual user who has an account on Flock.</td>
        </tr>
        <tr>
          <td>
            <Link to="/display/flockos/PublicProfile"> PublicProfile </Link>
          </td>
          <td>Public profile of a Flock user.</td>
        </tr>
      </table>
    </div>
  );
};

export default Objects;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const UsersGetInfo = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "id": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "teamId": "acme",
        "email": "xyz@domain.com",
        "firstName": "foo",
        "lastName": "bar",
        "role": "user",
        "timezone": "+05:30",
        "profileImage": "https://i.flock.co/kw2svc2fh4krqie6-full"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>users.getInfo</h1>
      <p>
        This method returns information about a user. You can only use it to get
        info about users who have installed your app.
      </p>
      <p>
        To get the name and profile image of other users in Flock, see
        users.getPublicProfile.
      </p>
      <p>
        The method endpoint is https://api.flock.co/v1/users.getInfo and follows
        the method calling conventions.
      </p>
      <h2>Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            {" "}
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              {" "}
              Authentication token{" "}
            </a>
            for the user
          </td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>
        A single <Link to="/display/flockos/User"> User </Link>
        object.
      </p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
    </div>
  );
};

export default UsersGetInfo;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

import img1 from "../assets/attachments/39584259/39584261.png";
import img2 from "../assets/attachments/39584259/39584260.jpeg";
import img3 from "../assets/attachments/39584259/39584269.png";
import img4 from "../assets/attachments/39584259/39584268.png";

const Widgets = () => {
  const tableData = [
    {
      id: 1,
      name: "flockEvent",
      description:
        "The JSON of the event that created the widget, serialized into a string. For eg if the widget was triggered by a button press you will get the JSON of the client.Pressbutton event",
    },
    {
      id: 2,
      field: "flockEventToken",
      description:
        "The event token that your app should use to authenticate the Flock user who opened this widget",
    },
    {
      id: 3,
      field: "flockClient",
      description: "One of either desktop, ios or android",
    },
  ];
  const codeBlocks = [
    {
      heading: `Adding a widget to an attachment`,
      code: singleLineString` {
        "title": "attachment title",
        "description": "attachment description",
        "views": {
            "widget": { "src": "<widget url>", "width": 400, "height": 400 }
        }
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>Widgets</h1>
      <ul>
        <li>
          <a href="/display/flockos/Widgets#Widgets-Whatarewidgets?">
            What are Widgets{" "}
          </a>

          <ol>
            <li>
              <a href="/display/flockos/Widgets#Widgets-ModalWidget">
                Modal Widget{" "}
              </a>
            </li>
            <li>
              <a href="/display/flockos/Widgets#Widgets-SidebarWidget">
                Sidebar Widget{" "}
              </a>
            </li>
            <li>
              <a href="/display/flockos/Widgets#Widgets-AttachmentWidget">
                Attachment Widget{" "}
              </a>
            </li>
          </ol>
        </li>
        <li>
          <a href="/display/flockos/Widgets#Widgets-ConfigureHowdoIopenawidget?">
            How do I open a widget?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Widgets#Widgets-WhatqueryparametersareappendedtothewidgetURL?">
            What query parameters are appended to the widget URL?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Widgets#Widgets-CanmywidgetinteractwiththeFlockclient?">
            Can my widget interact with the Flock client?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Widgets#Widgets-Mywidgetdoesnotopenup.Whatisgoingwrong?">
            My widget does not open up. What is going wrong?{" "}
          </a>
        </li>
      </ul>
      <section id="Widgets-Whatarewidgets?">
        <h2>What are widgets?</h2>
        <p>
          Apps can use widgets to render a web application inside an iframe or a
          webview within Flock. Besides doing everything a normal webapp can, a
          widget can also interact with the Flock client hosting it. This allows
          you to extend Flock's UI in many wonderful and amazing ways.
        </p>
        <p>There are three types of widgets that one can create in Flock.</p>
        <div id="Widgets-ModalWidget">
          <p className="bold">Modal Widget</p>
          <p>
            A Modal Widget appears as a blocking popup on desktop and as a full
            window web view on a mobile. For eg Notes, Code Snippets and Polls
            all use a modal widget to enable the user to create an attachment
            and an attachment picker button to launch the same. The image below
            depicts a modal widget on the desktop.
          </p>
          <img src={img1} alt="" />
          <p>
            The image below depicts a modal widget on a mobile. Note that the
            modal occupies the entire mobile screen area.
          </p>
          <img src={img2} alt="" />
        </div>
        <div id="Widgets-SidebarWidget">
          <p className="bold">Sidebar Widget</p>
          <p>
            A Sidebar Widget is shown below. Sidebar widgets are only available
            on the desktop client.
          </p>
          <img src={img3} alt="" />
          <p>For eg:</p>
          <li>
            The Todo app uses them to list all the todos for a given chat{" "}
          </li>
          <li>
            The Notes app uses them to list all the notes shared within a chat{" "}
          </li>
        </div>
        <div id="Widgets-AttachmentWidget">
          <p className="bold">Attachment Widget</p>
          <p>
            An Attachment Widget appears inline in the chat pane on desktop, and
            opens inside a modal when clicked on a mobile. Check{" "}
            <Link to="/display/flockos/Sending+Attachments">
              {" "}
              Sending Attachments{" "}
            </Link>
            for more details on sending an Attachment widget from your app.
          </p>
          <img src={img4} alt="" />
          <p>For eg:</p>
          <ul>
            <li>
              The polls app uses an Attachment widget to show interactive polls
              right inside the chat pane
            </li>
            <li>
              Code Snippets and Notes app use an Attachment widget to show a
              preview of their content
            </li>
          </ul>
        </div>
      </section>
      <section id="Widgets-ConfigureHowdoIopenawidget?">
        <h2>How do I open a widget?</h2>
        <p>Widgets can be opened in one of two ways:</p>
        <ul>
          <li>
            Either you can configure the open widget{" "}
            <Link to="/display/flockos/Client+Actions"> Client Actions </Link>
            for a{" "}
            <Link to="/display/flockos/Slash+Commands"> Slash Commands </Link>,
            <Link to="/display/flockos/Launcher+Buttons">
              {" "}
              launcher button{" "}
            </Link>
            , <Link to="/display/flockos/Widgets">attachment button</Link>, etc.
          </li>
          <li>
            Or you can create an attachment widget by providing a widget URL
            (and dimensions, for desktop) inside a message{" "}
            <Link to="/display/flockos/Attachment"> Attachment </Link>
            .
            <Codeblock
              heading={codeBlocks[0].heading}
              code={codeBlocks[0].code}
            />
          </li>
        </ul>
        <p>
          When a widget is launched, Flock simply creates an iframe (on desktop)
          or in a webview (on mobile), and within that launches the widget URL
          specified by you.
        </p>
      </section>
      <section id="Widgets-WhatqueryparametersareappendedtothewidgetURL?">
        <h2>What query parameters are appended to the widget URL?</h2>
        <p>
          These parameters provide context about how the widget was launched.
          Your widget URL can be a fully interactive web application.
        </p>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Name</th>

              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <tr key={item.id}>
                <td>{item.field}</td>
                <td>{item.description}</td>
              </tr>
            ))}
            <tr key={4}>
              <td>flockWidgetType</td>
              <td>
                The widget type. It can be one of the following:
                <ol>
                  <li>
                    modal – a modal widget. On mobile, this is the only value
                    possible.
                  </li>
                  <li>sidebar – a sidebar widget. Only used on desktop. </li>
                  <li>
                    inlineAttachment – used when an attachment widget is opened
                    inline in the chat pane. Only used on desktop.
                  </li>
                </ol>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <section id="Widgets-CanmywidgetinteractwiththeFlockclient?">
        <h2>Can my widget interact with the Flock client?</h2>
        <p>
          Yes. Flock provides a handy Javascript library -{" "}
          <Link to="/display/flockos/flock.js"> flock.js </Link>
          that you can include in your web app. Some of the things that you can
          do with flock.js are:
        </p>
        <ul>
          <li>
            Use the currently opened widget to open another widget. For eg
            launch a modal widget from a sidebar widget{" "}
          </li>
          <li>Launch the browser </li>
          <li>Close the current widget (only applicable for modal widgets) </li>
        </ul>
        See <Link to="/display/flockos/flock.js">flock.js</Link>
        <p> for more details.</p>
      </section>
      <section id="Widgets-Mywidgetdoesnotopenup.Whatisgoingwrong?">
        <h2>My widget does not open up. What is going wrong?</h2>
        <p>If your widget doesn't load, ensure that:</p>
        <li>
          Your widget URL is over HTTPS. The desktop client on macOS and the web
          client will not load your widget unless it is over HTTPS.
        </li>
        <li>
          Your widget URL does not include X-Frame-Options or
          Content-Security-Policy: frame-src in the HTTP response. Widgets are
          loaded inside an iframe in the desktop and web clients, and presence
          of these headers will prevent them from loading under Flock.
        </li>
      </section>
    </div>
  );
};

export default Widgets;

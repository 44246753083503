import React from "react";
import { Link } from "react-router-dom";
const Webhooks = () => {
  return (
    <div className="container">
      <h1>Webhooks</h1>
      <p>
        Webhooks are simple integrations that allow you to send or receive
        messages from Flock.
      </p>
      <p>
        To send a message into a Flock channel,{" "}
        <Link to="/display/flockos/Create+An+Incoming+Webhook">
          {" "}
          Create An Incoming Webhook{" "}
        </Link>
        .
      </p>
      <p>
        To receive a message from a Flock channel,{" "}
        <Link to="/display/flockos/Create+An+Outgoing+Webhook">
          {" "}
          Create An Outgoing Webhook{" "}
        </Link>
        .
      </p>
    </div>
  );
};

export default Webhooks;

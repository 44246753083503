import React from "react";
import { Link, NavLink } from "react-router-dom";
import Codeblock from "./Codeblock";
import img1 from "../assets/attachments/39584166/39584176.png";
import img2 from "../assets/attachments/39584166/39584174.png";
import { singleLineString } from "../Helpers/StringFormatter";

const SendingMessages = () => {
  const codeBlocks = [
    {
      heading: `chat.sendMessage using a GET request`,
      code: singleLineString`https://api.flock.co/v1/chat.sendMessage?
            to=u:513a9f4ae409&text=hello&token=632542db-1a23-
            4e06-8b39-01f8b0868d57`,
    },
    {
      heading: `chat.sendMessage using a POST request`,
      code: singleLineString`POST /v1/chat.sendMessage HTTP/1.1
        Host: api.flock.co
        Content-Type: application/x-www-form-urlencoded
        Content-Length: 70
         
        to=u:513a9f4ae409&text=hello&token=632542db-1a23-4e06-
        8b39-01f8b0868d57`,
    },
  ];
  return (
    <div className="container">
      <h1>Sending Messages</h1>
      <ul>
        <li>
          <a href="/display/flockos/Sending+Messages#SendingMessages-Whataremessages?">
            What are messages?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Sending+Messages#SendingMessages-WhatcanIuseMessagesfor?">
            What can I use Message for?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Sending+Messages#SendingMessages-WhatarethetypesofMessages?">
            What are the types of Messages?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Sending+Messages#SendingMessages-HowcanmyappsendmessagesintoFlock?">
            How can my app send messages into Flock?{" "}
          </a>
        </li>
      </ul>
      <section id="/display/flockos/Sending+Messages#SendingMessages-Whataremessages?">
        <h2>What are messages?</h2>
        <p>
          A user or an app can send a message either to another user or to a
          group. One can send both plain text messages and messages with rich
          content. Plain text messages display the sender name, the user avatar,
          the text of the message, and the time at which it was sent.
        </p>
        <img src={img1} alt="sda"></img>

        <p>
          On the other hand, messages with rich content (formally referred to as
          <Link to="/display/flockos/Attachment"> attachment </Link>) can show{" "}
          <Link to="/display/flockos/Widgets"> Widgets </Link>,{" "}
          <Link to="/display/flockos/FlockML"> FlockML </Link>, images, buttons,
          and provide files to download, among other things. See{" "}
          <Link to="/display/flockos/Sending+Attachments">
            {" "}
            Sending Attachments{" "}
          </Link>
          for more details.
        </p>
      </section>
      <img src={img2} alt="fas"></img>
      <section id="SendingMessages-WhatcanIuseMessagesfor?">
        <h2>What can I use Messages for?</h2>
        <ul>
          <li>
            Send notifications to a user or a group e.g. the ToDo app sends a
            notification whenever a ToDo is added, updated or deleted in the
            group.
          </li>
          <li>
            <Link to="/display/flockos/Sending+Attachments">
              {" "}
              Send attachments{" "}
            </Link>{" "}
            on behalf of a user like the Code Snippets or the Notes app
          </li>
          <li>
            Converse with your users using your app's{" "}
            <Link to="/display/flockos/Bots"> bot</Link>. For eg the Reminder
            app bot sends a message to a user based on the user's configuration
          </li>
        </ul>
      </section>
      <section id="SendingMessages-WhatarethetypesofMessages?">
        <h2>What are the types of Messages?</h2>
        <p>Your app can send messages either –</p>
        <ol>
          <li>
            Inline within a one-on-one or group chat tab. You may wish to do
            this for messages that are meant to be seen by the entire group or
            both parties in a one-on-one conversation. For eg the Todo app sends
            a notification to the entire group when a Task created within that
            group was completed.
          </li>
          <li>
            Directly to a user from your app's{" "}
            <Link to="/display/flockos/Bots"> Bots </Link>. This message will
            appear in your Bot's chat tab for that user. You may wish to do this
            for messages that are meant only for a given user e.g. the Reminder
            app bot sends a notification directly to a user
          </li>
        </ol>
      </section>
      <section id="SendingMessages-HowcanmyappsendmessagesintoFlock?">
        <h2>How can my app send messages into Flock?</h2>
        <p>
          Your app can send messages via the{" "}
          <Link to="/display/flockos/chat.sendMessage"> chat.sendMessage </Link>
          method. Prior to that, however, you will need to create an app.
        </p>
        <ol>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App"> Creating an App </Link>
            to set up your app via the developer dashboard.
          </li>
          <li>
            Install the app into your own Flock account. Make sure that you save
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              {" "}
              authentication token{" "}
            </a>
            the received in the{" "}
            <Link to="/display/flockos/app.install"> app.install </Link> event.
            You will need it for the{" "}
            <Link to="/display/flockos/Methods"> methods </Link>
            calls that follow.
          </li>
          <li>
            To be able to send a message to a contact or a group, you will need
            to know their{" "}
            <Link to="/display/flockos/Identifiers"> identifier </Link>
            first. The methods{" "}
            <Link to="/display/flockos/roster.listContacts">
              {" "}
              roster.listContacts{" "}
            </Link>{" "}
            and <Link to="/display/flockos/groups.list"> groups.list </Link> can
            help you do this. Typically, your{" "}
            <Link to="/display/flockos/Creating+an+App">
              {" "}
              configuration URL{" "}
            </Link>{" "}
            will use these methods to set up an interface wherein a user can
            select which contact or group he wants to send messages from your
            app to.
          </li>
          <li>
            Send this message using the{" "}
            <Link to="/display/flockos/chat.fetchMessages">
              {" "}
              chat.sendMessage{" "}
            </Link>
            method. You can use either HTTP GET:
            <Codeblock
              heading={codeBlocks[0].heading}
              code={codeBlocks[0].code}
            />
            <p>or POST to call the method.</p>
            <Codeblock
              heading={codeBlocks[1].heading}
              code={codeBlocks[1].code}
            />
          </li>
          <li>
            If you want to send attachments , take a look at the{" "}
            <Link to="/display/flockos/Sending+Attachments">
              {" "}
              Sending Attachments{" "}
            </Link>
            page. Or if you want to create a bot, take a look at{" "}
            <Link to="/display/flockos/Bots"> Bots </Link>.
          </li>
        </ol>
      </section>
    </div>
  );
};

export default SendingMessages;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ChannelAddMembers = () => {
  const codeBlocks = [
    {
      heading: `Example`,
      code: singleLineString`{
        "members": {
            "u:<id1>": { "status": "added" },
            "u:<id2>": { "status": "failed", "error": "UserNotFound" }
        }
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>channels.addMembers</h1>
      <p>
        This method allows adding members to a given channel. The user adding
        the members must be a member of the channel and must have permission to
        modify the member list.
      </p>
      <p>
        The method endpoint is https://api.flock.co/v1/channels.addMembers and
        follows the method calling conventions.
      </p>
      <section id="section1">
        <h2>Parameters</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>token</td>
            <td>String</td>
            <td>Yes</td>
            <td>
              {" "}
              <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
                {" "}
                Authentication token{" "}
              </a>
              of the user
            </td>
          </tr>
          <tr>
            <td>channelIds</td>
            <td>String</td>
            <td>Yes</td>
            <td> Id of the channel</td>
          </tr>
          <tr>
            <td>members</td>
            <td>String</td>
            <td>Yes</td>
            <td> An array of user ids that will be added to the channel l</td>
          </tr>
        </table>
      </section>
      <section id="section2">
        <h2>Response</h2>
        <p>
          An object where the keys are user ids that were to be added and the
          value is a{" "}
          <Link to="/display/flockos/ChannelMemberStatus">
            {" "}
            ChannelMemberStatus{" "}
          </Link>
          , indicating whether the user was successfully added or not.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
    </div>
  );
};

export default ChannelAddMembers;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ClientFlockMLAction = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
            "name": "client.flockmlAction",
            "userId": "u:8c67055b-202f-4ffe-a18f-1c4553a50175",
            "userName": "Alyssa P. Hacker",
            "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
            "chatName": "Ben Bitdiddle",
            "messageUid": "fda6144e-c925-487f-92ed-9e0956a4263c",
            "actionId": "act1"
        }`,
    },
  ];
  return (
    <div className="container">
      <h1>client.flockmlAction</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is generated when a user clicks on the &lt; action &gt;
          element in a <Link to="/display/flockos/FlockML"> FlockML </Link>
          attachment or message.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Attribute Name</th>
            <th>Attribute Type</th>
            <th>Attribute Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>
          <tr>
            <td>userName</td>
            <td>String</td>
            <td>Name of the User</td>
          </tr>
          <tr>
            <td>chat</td>
            <td>String</td>
            <td>Id of the user or group whose chat tab was open</td>
          </tr>
          <tr>
            <td>chatName</td>
            <td>String</td>
            <td>Name of the user or group whose chat tab is open</td>
          </tr>
          <tr>
            <td>messageId </td>
            <td>String</td>
            <td>id of the message</td>
          </tr>
          <tr>
            <td>messageUid </td>
            <td>String</td>
            <td>Uid of the message</td>
          </tr>
          <tr>
            <td>attachmentId </td>
            <td>String</td>
            <td>id of the attachment</td>
          </tr>
          <tr>
            <td>actionId </td>
            <td>String</td>
            <td>id of the action that was pressed</td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default ClientFlockMLAction;

import React from "react";

const ChannelMemberStatus = () => {
  return (
    <div className="container">
      <h1>ChannelMemberStatus</h1>
      <p>
        Used to indicate the status of adding or removing a user from a channel.
      </p>
      <h2>Object Attributes</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>status</td>
          <td>String</td>
          <td> Can be either "added", "removed" or "failed"</td>
        </tr>

        <tr>
          <td>error</td>
          <td>String</td>

          <td>
            <p>
              If the user couldn't be added or removed, the error can be one of
              the following
            </p>
            <ul>
              <li>UserNotFound – no user with the given id could be found</li>
              <li>
                UserNotFromSameTeam – user is not part of the same team where
                the channel is being created
              </li>
              <li>
                UserRestricted – user cannot be added to channel due to certain
                restrictions
              </li>
              <li>
                InternalServerError – some other error prevented the user from
                being added to the channel
              </li>
            </ul>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default ChannelMemberStatus;

import React from "react";
// Filename - components/Sidebar.js

import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { SidebarData } from "./SidebarData";
import SubMenu from "./SubMenu";
import { IconContext } from "react-icons/lib";
import img1 from "../assets/finalLogoFlock.png";

const Nav = styled.div`
  background: #0abe51;
  padding: 6px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const NavIcon = styled(Link)`
  margin-left: 2rem;
  font-size: 2rem;
  height: 80px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const SidebarNav = styled.nav`
  background: lightgray;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  color : black

  overflow: auto;
  top: 0;
  left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
  transition: 350ms;
  z-index: 10;
`;

const SidebarWrap = styled.div`
  width: 100%;
`;

const Header = () => {
  return (
    <div>
      <Nav>
        <h1
          style={{
            textAlign: "center",
            marginLeft: "10px",
            color: "#e0e0e0",
          }}
        ></h1>
        <a href="./display/flockos/FlockOS+Overview">
          <img src={img1} />
        </a>
      </Nav>
    </div>
  );
};

export default Header;

import React from "react";
import { Link } from "react-router-dom";

import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const EventTokens = () => {
  const tableData = [
    {
      id: 1,
      attribute_name: "appId",
      attribute_type: "String",
      attribute_description: "The app's id",
    },
    {
      id: 2,
      attribute_name: "userId",
      attribute_type: "String",
      attribute_description: "The user's id",
    },
    {
      id: 3,
      attribute_name: "exp",
      attribute_type: "Number",
      attribute_description: "Expiration time of the token",
    },
    {
      id: 4,
      attribute_name: "iat",
      attribute_type: "Number",
      attribute_description: "Time at which the token was issued",
    },
    {
      id: 5,
      attribute_name: "jti",
      attribute_type: "String",
      attribute_description: "(JWT ID) A unique identifier for the token",
    },
  ];
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "appId": "my-app",
        "userId": "u:3d004302-a97d-4016-91b4-6c221bb4781d",
        "exp": 1469541580,
        "iat": 1469541572,
        "jti": "568eadf8-77fc-4108-91da-d94da46d709b"
    }`,
    },
    {
      heading: ``,
      code: singleLineString`{"alg":"HS256","typ":"JWT"}`,
    },
    {
      heading: ``,
      code: singleLineString`      eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.ewogICAgImFwcElkIjogIm15LWFwcCIsCiAgICAidXNlcklkIjogInU6M2Qw
      MDQzMDItYTk3ZC00MDE2LTkxYjQtNmMyMjFiYjQ3ODFkIiwKICAgICJleHAiOiAxNDY5NTQx
      NTgwLAogICAgImlhdCI6IDE0Njk1NDE1NzIsCiAgICAianRpIjogIjU2OGVhZGY4LTc3ZmMtNDEwOC05MWRhLWQ5NGRhNDZkNzA5YiIKfQ.6Xo51VjOWNc-SIlCIhMyT-8ivvmMwk3qKs52azx9X7g`,
    },
  ];

  return (
    <div className="container">
      <h1>Event Tokens</h1>
      <ul>
        <li>
          <a href="/display/flockos/Event+Tokens#EventTokens-WhatisanEventToken?">
            What is an Event Token?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Event+Tokens#EventTokens-Whatissignedbytheeventtoken?">
            What is signed by the event token?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Event+Tokens#EventTokens-WhatkeyandalgorithmareusedtosigntheJWT?">
            What key and algorithm are used to sign the JWT?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Event+Tokens#EventTokens-TokenGenerationExample">
            Token Generation Example
          </a>
        </li>
      </ul>

      <section id="EventTokens-WhatisanEventToken?">
        <h2>What is an Event Token?</h2>
        <p>
          Each time Flock either sends an event to the event listener URL, or
          opens a widget or browser, it includes an event token in the form of a
          <Link to="https://datatracker.ietf.org/doc/html/rfc7515">
            {" "}
            JSON Web Signature{" "}
          </Link>
          (JWS). This is a quick method for verifying that the request really
          did originate from Flock and prevent spoofing attacks.
        </p>
      </section>
      <section id="EventTokens-Whatissignedbytheeventtoken?">
        <h2>What is signed by the event token?</h2>
        <p>
          The JWS signs a JSON Web Token (JWT) with the following attributes:
        </p>
        <table className="custom-table">
          <thead>
            <tr>
              <th>Attribute Name</th>
              <th>Attribute Type</th>
              <th>Attribute Description</th>
            </tr>
          </thead>
          <tbody>
            {tableData.map((item) => (
              <tr key={item.id}>
                <td>{item.attribute_name}</td>
                <td>{item.attribute_type}</td>
                <td>{item.attribute_description}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <p>
          The exp and iat are numeric values representing the number of seconds
          from 1970-01-01T00:00:00Z UTC until the specified UTC date/time,
          ignoring leap seconds.
        </p>
        <p>Note that the same event token may be sent more than once.</p>
      </section>
      <section id="EventTokens-WhatkeyandalgorithmareusedtosigntheJWT?">
        <h2>What key and algorithm are used to sign the JWT?</h2>
        <p>
          The key used to sign the event token is the app secret. The algorithm
          used is HMAC SHA-256.
        </p>
      </section>
      <section id="EventTokens-TokenGenerationExample">
        <h2>Token Generation Example</h2>
        <p>
          The example below demonstrates how an event token is generated. You
          can consult the{" "}
          <Link to="https://datatracker.ietf.org/doc/html/rfc7515">
            {" "}
            JWS specification{" "}
          </Link>
          for further details about this process. However, for most languages
          you should be able to find a JWT library with JWS support out of the
          box, so you wouldn't need to implement the verification and decoding
          yourself.
        </p>
        <p>Assuming that the following JWT payload needs to be signed: </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <p>
          Further, assuming that the app secret that would be used to sign the
          payload is 869eb1d0-419d-4747-98b4-6d81360a6681, the resultant
          HMAC-SHA256 signature (encoded using URL-safe base64) is
          ijZ-dXklUV5SKbcbZVAyMAeHIKDY98YTs0u1-ocVegM.
        </p>
        <p>
          The following{" "}
          <Link to="https://datatracker.ietf.org/doc/html/rfc7515">
            JOSE header{" "}
          </Link>{" "}
          is used (provides the algorithm and the type of payload):
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
        <p>
          Encoding the JOSE header and the JWT payload using URL-safe base64,
          and then joining the encoded header, payload and signature using the
          dot (".") yields the following event token:
        </p>
        <Codeblock heading={codeBlocks[2].heading} code={codeBlocks[2].code} />
      </section>
    </div>
  );
};

export default EventTokens;

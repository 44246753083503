import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const ChatGenerateUrlPreview = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
        "name": "chat.generateUrlPreview",
        "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "url": "http://example.net",
        "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "messageId": "4458f0a9-eca7-4efb-8560-2a0fd3ac858d"
    }`,
    },
    {
      heading: ``,
      code: singleLineString`{
        "attachment": {
            "title": "<content title>",
            "description": "<content description>",
            "url": "<the requested url>",
            "views": {
                "flockml": "<flockml>some <b>flockml</b> content</flockml>"
            }
        }
    }`,
    },
  ];

  return (
    <div className="container">
      <h1>chat.generateUrlPreview</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is sent to the{" "}
          <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
            {" "}
            event listenter URL{" "}
          </a>
          by Flock to request the app to unfurl a list of URLs. Your app is
          expected to send back an
          <Link to="/display/flockos/Attachment"> Attachment </Link>
          object in response to this event.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Atribute Name</th>
            <th>Atribute Type</th>
            <th>Atribute Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>
          <tr>
            <td>url</td>
            <td>String (URL)</td>
            <td>The URL to unfurl</td>
          </tr>
          <tr>
            <td>to</td>
            <td>String</td>
            <td>Id of the user or group to whom the message was sent</td>
          </tr>
          <tr>
            <td>messageId</td>
            <td>String</td>
            <td>Id of the message</td>
          </tr>
        </table>
      </section>
      <section id="section3">
        <h2>Response</h2>
        <p>
          The app should respond back with an{" "}
          <Link to="/display/flockos/Attachment"> Attachment </Link>.
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      </section>
    </div>
  );
};

export default ChatGenerateUrlPreview;

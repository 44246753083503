import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const ClientPressButton = () => {
  const codeBlocks = [
    {
      heading: `Event for chat tab button`,
      code: singleLineString`{
            "name": "client.pressButton",
            "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
            "button": "chatTabButton",
            "userName": "Alyssa P. Hacker",
            "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
            "chatName": "Ben Bitdiddle"
        }`,
    },
    {
      heading: ``,
      code: singleLineString`{
            "text": "<toaster message>"
        }`,
    },
  ];
  return (
    <div className="container">
      <h1>client.pressButton</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>This event is generated when a user presses a button.</p>

        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Attribute Name</th>
            <th> Attribute Type</th>
            <th> Attribute Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User identifier </Link>
            </td>
          </tr>
          <tr>
            <td>button</td>
            <td>String</td>
            <td>
              The button that was pressed. This should have any one of the
              following values:
              <ul>
                <li>chatTabButton</li>
                <li>appLauncherButton</li>
                <li>attachmentPickerButton</li>
                <li>attachmentButton</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>userName</td>
            <td>String</td>
            <td>Name of the user</td>
          </tr>
          <tr>
            <td>chat</td>
            <td>String</td>
            <td>Id of the user or group whose chat tab was open</td>
          </tr>
          <tr>
            <td>chatName</td>
            <td>String</td>
            <td>Name of the user or group whose chat tab is open</td>
          </tr>

          <tr>
            <td>messageUid </td>
            <td>String</td>
            <td>
              uid of the message in which the attachment button was pressed.
              Available only for an attachment button.
            </td>
          </tr>

          <tr>
            <td>attachmentId </td>
            <td>String</td>
            <td>
              id of the attachment, if provided. Available only for an
              attachment button.
            </td>
          </tr>

          <tr>
            <td>buttonId </td>
            <td>String</td>
            <td>
              Button identifier. Only sent for an attachment buttons, use it to
              distinguish one attachment button from another.
            </td>
          </tr>
        </table>
      </section>
      <section id="section3">
        <h2>Response</h2>
        <p>
          Your app can respond with a text value – this should be a short (upto
          100 chars) string that is displayed to the user who pressed the
          button.
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      </section>
    </div>
  );
};

export default ClientPressButton;

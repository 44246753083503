import React from "react";
import { Link } from "react-router-dom";
const HelpAndSupport = () => {
  return (
    <div className="container">
      <h1>Help and Support</h1>
      <p>
        If you have any queries related to FlockOS, you can{" "}
        <Link to="mailto:devsupport@flock.com"> drop us an email </Link>.
      </p>
    </div>
  );
};

export default HelpAndSupport;

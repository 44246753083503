import React from "react";
import img1 from "../assets/attachments/40239364/40239458.png";
import img2 from "../assets/attachments/40239364/40239461.png";
import img3 from "../assets/attachments/40239364/40239452.png";
import Codeblock from "./Codeblock";
import { Link } from "react-router-dom";

function singleLineString(strings, ...values) {
  // Interweave the strings with the
  // substitution vars first.
  let output = "";
  for (let i = 0; i < values.length; i++) {
    output += strings[i] + values[i];
  }
  output += strings[values.length];

  // Split on newlines.
  let lines = output.split(/(?:\r\n|\n|\r)/);

  // Rip out the leading whitespace.
  return lines
    .map((line) => {
      return line.replace(/^\s+/gm, "");
    })
    .join("\n")
    .trim();
}
const MessageActionButtons = () => {
  const tableData = [
    {
      id: 1,
      field: "Title",
      mandatory: "Yes",
      description: "Title for the message action button",
    },
    {
      id: 2,
      field: "Select Action",
      mandatory: "Yes",
      description:
        "Select an appropriate client action for the message action button",
    },
  ];
  const codeBlocks = [
    {
      heading: `client.messageAction with messageUids`,
      code: singleLineString`{
        "name": "client.messageAction",
        "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "userName": "Alyssa P. Hacker",
        "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "chatName": "Ben Bitdiddle",
        "messageUids": [ "fd4877b719b1", "59e2da001af2" ]
    }`,
    },
    {
      heading: `client.messageAction with messages`,
      code: singleLineString`{
        "name": "client.messageAction",
        "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
        "userName": "Alyssa P. Hacker",
        "chat": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
        "chatName": "Ben Bitdiddle",
        "messageUids": [ "fd4877b719b1", "59e2da001af2" ]
        "messages": [ 
                         { 
                          "from": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
                         "to": "u:cfc76545-3400-4864-892a-513a9f4ae409",
                         "uid": "fd4877b719b1",
                         "text": "Hello" 
                       },
                       { 
                         "from": "u:cfc76545-3400-4864-892a-513a9f4ae409",
                         "to": "u:1e6429de-16b7-48b3-acc0-c2d7ec5ad195",
                         "uid": "59e2da001af2",
      }
       `,
    },
  ];
  return (
    <div className="container">
      <h1>Message Action Buttons</h1>
      <ul>
        <li>
          <a href="/display/flockos/Message+Action+Buttons#MessageActionButtons-Whatisamessageactionbutton?">
            What is a message action button?
          </a>{" "}
        </li>
        <li>
          <a href="/display/flockos/Message+Action+Buttons#MessageActionButtons-Whataretheavailableactionsforamessageactionbutton?">
            What are the available actions for a message action button?
          </a>{" "}
        </li>
        <li>
          <a href="/display/flockos/Message+Action+Buttons#MessageActionButtons-Whatmessagedetailsareincludedinclient.messageAction?">
            What message details are included in client.messageAction?
          </a>{" "}
        </li>
        <li>
          <a href="/display/flockos/Message+Action+Buttons#MessageActionButtons-HowdoIcreateamessageactionbutton?">
            How do I create a message action button?
          </a>{" "}
        </li>
      </ul>
      <section id="MessageActionButtons-Whatisamessageactionbutton?">
        <h2>What is a message action button?</h2>
        <p>
          Your app's message action button is added to each and every message
          that a user sees in Flock. If a user clicks the message action button,
          the message and chat details are sent to the app via the
          <Link to="/display/flockos/client.messageAction">
            {" "}
            client.messageAction{" "}
          </Link>
          event.
        </p>
        <img src={img1} alt="" />
        <p>
          A user can also select multiple messages in Flock. If a user clicks
          the message action button after selecting multiple messages, then
          details of all the selected messages are sent.
        </p>
      </section>
      <img style={{ width: "20%" }} src={img2} alt="" />
      <section id="MessageActionButtons-Whataretheavailableactionsforamessageactionbutton?">
        <h2>What are the available actions for a message action button?</h2>
        <p>
          <Link to="/display/flockos/Client+Actions"> Client Actions </Link>
          Your app can configure any of these for a message action button:
        </p>
        <ul>
          <li>
            Opening a <Link to="/display/flockos/Widgets"> Widget </Link>
            inside the Flock client{" "}
          </li>
          <li>Opening a URL in the system browser; </li>
          <li>
            Sending an event to the{" "}
            <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
              {" "}
              events listener URL{" "}
            </a>
            .{" "}
          </li>
        </ul>
      </section>
      <section id="MessageActionButtons-Whatmessagedetailsareincludedinclient.messageAction?">
        <h2>What message details are included in client.messageAction?</h2>
        <p>
          Flock will always send the uid of every message that was selected when
          the message action button was pressed.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />

        <p>
          To get further details of each message, you can call
          <Link to="/display/flockos/chat.fetchMessages">
            {" "}
            chat.fetchMessage{" "}
          </Link>
          and pass the received message uids. This will return the{" "}
          <Link to="/display/flockos/Message"> Message </Link>
          object for each message.
        </p>
        <p>
          Optionally, when the number of messages selected is low, Flock might
          send the <Link to="/display/flockos/Message"> Message </Link>
          objects within the event itself. If the messages array is included,
          then you can read message details directly from within the event,
          rather than calling{" "}
          <Link to="/display/flockos/chat.fetchMessages">
            {" "}
            chat.fetchMessage{" "}
          </Link>
          .
        </p>
      </section>
      <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      <section id="MessageActionButtons-HowdoIcreateamessageactionbutton?">
        <h2>How do I create a message action button?</h2>
        <ul>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App"> Creating an App </Link>
            to setup your app in the developer dashboard.
          </li>
          <li>
            In the Advanced Info section, enable the message action button.
            <img src={img3} alt="" />
            <p>Fill in the info below:</p>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Mandatory</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.field}</td>
                    <td>{item.mandatory}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </li>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App">
              {" "}
              app installation{" "}
            </Link>
            and install the app into your own Flock account.
          </li>
          <li>Click the app's message action button on any message. </li>
          <li>Verify the event token and take relevant action at your end. </li>
        </ul>
      </section>
    </div>
  );
};

export default MessageActionButtons;

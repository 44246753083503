import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/attachments/Webhooks_Dev01.png";
import img2 from "../assets/attachments/Webhooks_Dev02.png";
import img3 from "../assets/attachments/Webhooks_Dev03.png";
import img4 from "../assets/attachments/Webhooks_Dev04.png";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const IncomingWebhook = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`curl -X POST <Incoming Webhook URL> -H "Content-Type: 
      application/json" -d '  {
        "text": "This is a test message"
    }`,
    },
    {
      heading: ``,
      code: singleLineString`curl -X POST <Incoming Webhook URL> -H "Content-Type: 
      application/json" -d '  {
        "text": "This is a test message."
    }`,
    },
    {
      heading: ``,
      code: singleLineString`curl -X POST <Incoming Webhook URL> -H 
      "Content-Type: application/json" -d '  {
        "flockml":"<flockml>Hello! Welcome to <b>Flock</b>!</flockml>"
    }`,
    },
    {
      heading: ``,
      code: singleLineString`curl -X POST <Incoming Webhook URL> -H 
      "Content-Type: application/json" -d '  {
        "product": "iPhone", "units": "1", "customer": "Adam", "price": "1000"
    }`,
    },
    {
      heading: ``,
      code: singleLineString`{
        "text": "$(json.customer) bought $(json.units) 
      $(json.product) @ $$$(json.price)"
  }`,
    },
  ];
  return (
    <div className="container">
      <h1>Create An Incoming Webhook</h1>
      <p>
        You can use Flock's webhooks to send and receive messages from external
        services using JSON packets. An incoming webhook posts messages to a
        Flock channel from an external service.
      </p>
      <section id="section1">
        <p>Create an incoming webhook</p>
        <ol>
          <li>
            Navigate to the Flock Developer Dashboard at{" "}
            <Link to="https://dev.flock.com">https://dev.flock.com</Link>
          </li>
          <li>
            Sign in with your Flock account and click Webhooks on the left.
          </li>
        </ol>

        <img src={img1} alt="" />
        <ul>
          <li>Choose to Add an Incoming Webhook.</li>
          <li>
            Select the Channel where you want incoming messages to be posted.
            <img src={img2} alt="" />
          </li>

          <li>
            Give the webhook a name and an icon that will be used to post
            messages in Flock.
            <img src={img3} alt="" />
          </li>
          <li>Click Save and Generate URL. Copy the URL that is generated.</li>
          <li>
            This is the webhook endpoint and it accepts JSON packets posted to
            it. The following command will post a message "This is a test
            message" to your configured channel:
          </li>
          <Codeblock
            heading={codeBlocks[0].heading}
            code={codeBlocks[0].code}
          />
          <p>
            Flock's incoming webhooks support Message objects with two common
            attributes or keys - "text" for message content and "flockml" for
            message formatting. Please refer to{" "}
            <Link to="/display/flockos/Message"> Message </Link>
            for other attributes.
          </p>
          <Codeblock
            heading={codeBlocks[1].heading}
            code={codeBlocks[1].code}
          />
          <p>OR</p>
          <Codeblock
            heading={codeBlocks[2].heading}
            code={codeBlocks[2].code}
          />
        </ul>
      </section>
      <section id="section2">
        <h2>Transforming JSON payload formats</h2>
        <p>
          Some external apps and services may send data that does not follow
          Flock's <Link to="/display/flockos/Message"> Message </Link>
          JSON payload format, and hence it is not parsed.
        </p>
        <p>
          In the following example, the JSON payload received is not in a
          compatible format, so no message is generated:
        </p>
        <Codeblock heading={codeBlocks[3].heading} code={codeBlocks[3].code} />
        <p>
          To receive messages via an incoming webhook from an app and service
          that uses an incompatible JSON format, you'll need to transform the
          data into a Flock-compatible format.
        </p>
        <ul>
          <li>
            First, make a note of the keys and values in the JSON payload.
          </li>
          <li>
            In the above example, the attributes are "product", "units",
            "customer", and "price" with the corresponding values being
            "iPhone", "1", "Adam", and "1000".
          </li>
          <li>
            Convert all the incompatible attributes to placeholder keys in the
            format: $(json.attribute)
          </li>
          <li>
            These placeholder keys will be replaced by the corresponding values
            in the message.
          </li>
          <li>
            String together the placeholder keys into an English sentence, and
            assign it to a key "text" or "flockml".
          </li>
          <li>
            Here is an example of the transformation template for the
            incompatible JSON payload:
          </li>
          <li></li>
          <Codeblock
            heading={codeBlocks[4].heading}
            code={codeBlocks[4].code}
          />
          Go to your existing incoming webhook, click Edit and paste the
          transformation template under Template Heading.
          <img src={img4} alt="" />
          <li>
            The webhook will now generate messages in Flock such as "Adam bought
            1 iPhone @ $1000".
          </li>
        </ul>
      </section>
    </div>
  );
};

export default IncomingWebhook;

// Filename - App.js

import "./App.css";
import Sidebar from "./components/Sidebar";
import FlockOsOverview from "./components/FlockOsOverview";
import Tutorial from "./components/Tutorial";
import CreatingAnApp from "./components/CreatingAnApp";
import Messaging from "./components/Messaging";
import SendingMessages from "./components/SendingMessages";
import SendingAttachments from "./components/SendingAttachments";
import Bots from "./components/Bots";
import Integrations from "./components/Integrations";
import SlashCommands from "./components/SlashCommands";
import AttachmentPicker from "./components/AttachmentPicker";
import LauncherButton from "./components/LauncherButton";
import ChatTab from "./components/ChatTab";
import MessageActionButtons from "./components/MessageActionButtons";
import UrlPreview from "./components/UrlPreview";
import Widgets from "./components/Widgets";
import ClientActions from "./components/ClientActions";
import SDKs from "./components/SDKs";
import HelpAndSupport from "./HelpAndSupport";
import Webhooks from "./components/Webhooks";
import IncomingWebhook from "./components/IncomingWebhook";
import OutgoingWebhook from "./components/OutgoingWebhook";
import ApiReference from "./components/ApiReference";
import Identifiers from "./components/Identifiers";
import Methods from "./components/Methods";
import ChannelAddMembers from "./components/ChannelAddMembers";
import ChannelCreate from "./components/ChannelCreate";
import ChannelGetInfo from "./components/ChannelGetInfo";
import ChannelList from "./components/ChannelList";
import ChannelListMembers from "./components/ChannelListMembers";
import ChannelRemoveMembers from "./components/ChannelRemoveMembers";
import ChatFetchMessages from "./components/ChatFetchMessages";
import ChatSendMessage from "./components/ChatSendMessage";
import RosterListContact from "./components/RosterListContact";
import UsersGetInfo from "./components/UsersGetInfo";
import UsersGetPublicProfile from "./components/UsersGetPublicProfile";
import Objects from "./components/Objects";
import Attachment from "./components/Attachment";
import Channel from "./components/Channel";
import ChannelMember from "./components/ChannelMember";
import ChannelMemberStatus from "./components/ChannelMemberStatus";
import Message from "./components/Message";
import PublicProfile from "./components/PublicProfile";
import User from "./components/User";
import Events from "./components/Events";
import AppInstall from "./components/AppInstall";
import AppUninstall from "./components/AppUninstall";
import ChatGenerateUrlPreview from "./components/ChatGenerateUrlPreview";
import ChatReceiveMessage from "./components/ChatReceiveMessage";
import ClientFlockMLAction from "./components/ClientFlockMLAction";
import ClientMessageAction from "./components/ClientMessageAction";
import ClientOpenAttachment from "./components/ClientOpenAttachment";
import ClientPressButton from "./components/ClientPressButton";
import ClientSlashCommand from "./components/ClientSlashCommand";
import ClientWidgetAction from "./components/ClientWidgetAction";
import EventTokens from "./components/EventTokens";
import FlockML from "./components/FlockML";
import Flockjs from "./components/Flockjs";
import Deprecated from "./components/Deprecated";
import DeprecatedGroup from "./components/DeprecatedGroup";
import DeprecatedGroupGetInfo from "./components/DeprecatedGroupGetInfo";
import DeprecatedGroupGetMembers from "./components/DeprecatedGroupGetMembers";
import DeprecatedGroupList from "./components/DeprecatedGroupList";
import Header from "./components/Header";
import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";

const ScrollToTop = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    // Scroll to the top of the page whenever the pathname changes
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    const id = hash.substring(1); // Remove '#' from the hash
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
  }, [hash]);

  return null; // This component doesn't render anything
};

function App() {
  return (
    <div className="main">
      <Header />
      <ScrollToTop />
      <div className="sidebar-data-wrapper">
        <div className="sidebar-wrapper">
          <Sidebar />
        </div>

        {/* <Router> */}
        <div>
          <Routes>
            <Route path="/" element={<FlockOsOverview />} />
            <Route
              path="/display/flockos/FlockOS+Overview"
              element={<FlockOsOverview />}
            />
            <Route path="/display/flockos/Tutorial" element={<Tutorial />} />
            <Route
              path="/display/flockos/Creating+an+App"
              element={<CreatingAnApp />}
            />
            <Route path="/display/flockos/Messaging" element={<Messaging />} />
            <Route
              path="/display/flockos/Sending+Messages"
              element={<SendingMessages />}
            />
            <Route
              path="/display/flockos/Sending+Attachments"
              element={<SendingAttachments />}
            />
            <Route path="/display/flockos/Bots" element={<Bots />} />
            <Route
              path="/display/flockos/Integrations"
              element={<Integrations />}
            />
            <Route
              path="/display/flockos/Slash+Commands"
              element={<SlashCommands />}
            />
            <Route
              path="/display/flockos/Attachment+Pickers"
              element={<AttachmentPicker />}
            />
            <Route
              path="/display/flockos/Launcher+Buttons"
              element={<LauncherButton />}
            />
            <Route
              path="/display/flockos/Chat+Tab+Buttons"
              element={<ChatTab />}
            />
            <Route
              path="/display/flockos/Message+Action+Buttons"
              element={<MessageActionButtons />}
            />
            <Route
              path="/display/flockos/URL+Preview"
              element={<UrlPreview />}
            />
            <Route path="/display/flockos/Widgets" element={<Widgets />} />
            <Route
              path="/display/flockos/Client+Actions"
              element={<ClientActions />}
            />

            <Route
              path="/display/flockos/API+Reference"
              element={<ApiReference />}
            />
            <Route
              path="/display/flockos/Identifiers"
              element={<Identifiers />}
            />
            <Route path="/display/flockos/Methods" element={<Methods />} />
            <Route
              path="/display/flockos/channels.addMembers"
              element={<ChannelAddMembers />}
            />
            <Route
              path="/display/flockos/channels.create"
              element={<ChannelCreate />}
            />
            <Route
              path="/display/flockos/channels.getInfo"
              element={<ChannelGetInfo />}
            />
            <Route
              path="/display/flockos/channels.list"
              element={<ChannelList />}
            />
            <Route
              path="/display/flockos/channels.listMembers"
              element={<ChannelListMembers />}
            />
            <Route
              path="/display/flockos/channels.removeMembers"
              element={<ChannelRemoveMembers />}
            />
            <Route
              path="/display/flockos/chat.fetchMessages"
              element={<ChatFetchMessages />}
            />
            <Route
              path="/display/flockos/chat.sendMessage"
              element={<ChatSendMessage />}
            />
            <Route
              path="/display/flockos/roster.listContacts"
              element={<RosterListContact />}
            />
            <Route
              path="/display/flockos/users.getInfo"
              element={<UsersGetInfo />}
            />
            <Route
              path="/display/flockos/users.getPublicProfile"
              element={<UsersGetPublicProfile />}
            />

            <Route path="/display/flockos/Events" element={<Events />} />
            <Route
              path="/display/flockos/Event+Tokens"
              element={<EventTokens />}
            />
            <Route path="/display/flockos/FlockML" element={<FlockML />} />
            <Route path="/display/flockos/flock.js" element={<Flockjs />} />
            <Route
              path="/display/flockos/Deprecated"
              element={<Deprecated />}
            />

            <Route path="/display/flockos/Objects" element={<Objects />} />
            <Route
              path="/display/flockos/Attachment"
              element={<Attachment />}
            />
            <Route path="/display/flockos/Channel" element={<Channel />} />
            <Route
              path="/display/flockos/ChannelMember"
              element={<ChannelMember />}
            />
            <Route
              path="/display/flockos/ChannelMemberStatus"
              element={<ChannelMemberStatus />}
            />
            <Route path="/display/flockos/Message" element={<Message />} />
            <Route
              path="/display/flockos/PublicProfile"
              element={<PublicProfile />}
            />
            <Route path="/display/flockos/User" element={<User />} />

            <Route path="/display/flockos/Events" element={<Events />} />
            <Route
              path="/display/flockos/app.install"
              element={<AppInstall />}
            />
            <Route
              path="/display/flockos/app.uninstall"
              element={<AppUninstall />}
            />
            <Route
              path="/display/flockos/chat.generateUrlPreview"
              element={<ChatGenerateUrlPreview />}
            />
            <Route
              path="/display/flockos/chat.receiveMessage"
              element={<ChatReceiveMessage />}
            />
            <Route
              path="/display/flockos/client.flockmlAction"
              element={<ClientFlockMLAction />}
            />
            <Route
              path="/display/flockos/client.messageAction"
              element={<ClientMessageAction />}
            />
            <Route
              path="/display/flockos/client.openAttachmentWidget"
              element={<ClientOpenAttachment />}
            />
            <Route
              path="/display/flockos/client.pressButton"
              element={<ClientPressButton />}
            />
            <Route
              path="/display/flockos/client.slashCommand"
              element={<ClientSlashCommand />}
            />
            <Route
              path="/display/flockos/client.widgetAction"
              element={<ClientWidgetAction />}
            />

            <Route
              path="/display/flockos/Event+Tokens"
              element={<EventTokens />}
            />
            <Route path="/display/flockos/FlockML" element={<FlockML />} />
            <Route path="/display/flockos/flock.js" element={<Flockjs />} />
            <Route
              path="/display/flockos/Deprecated"
              element={<Deprecated />}
            />

            <Route
              path="/display/flockos/Group"
              element={<DeprecatedGroup />}
            />
            <Route
              path="/display/flockos/groups.getInfo"
              element={<DeprecatedGroupGetInfo />}
            />
            <Route
              path="/display/flockos/groups.getMembers"
              element={<DeprecatedGroupGetMembers />}
            />
            <Route
              path="/display/flockos/groups.list"
              element={<DeprecatedGroupList />}
            />
            <Route path="/display/flockos/Webhooks" element={<Webhooks />} />
            <Route
              path="/display/flockos/Create+An+Incoming+Webhook"
              element={<IncomingWebhook />}
            />
            <Route
              path="/display/flockos/Create+An+Outgoing+Webhook"
              element={<OutgoingWebhook />}
            />
            <Route path="/display/flockos/SDKs" element={<SDKs />} />
            <Route
              path="/display/flockos/Help+and+Support"
              element={<HelpAndSupport />}
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </div>
        {/* </Router> */}
      </div>
    </div>
  );
}

export default App;

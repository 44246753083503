import React from "react";
import { Link } from "react-router-dom";
import img1 from "../assets/attachments/39584244/39584248.png";
import img2 from "../assets/attachments/39584244/39584250.png";
import img3 from "../assets/attachments/39584244/40239349.png";
const ChatTab = () => {
  const tableData = [
    {
      id: 1,
      field: "Tooltip text",
      mandatory: "Yes",
      description:
        "This will be shown against your app launcher button to the user",
    },
    {
      id: 2,
      field: "Select action for chat tab",
      mandatory: "Yes",
      description:
        "Select an appropriate client action for the chat tab button.",
    },
  ];
  return (
    <div className="container">
      <h1>Chat Tab Buttons</h1>
      <ul>
        <li>
          <a href="/display/flockos/Chat+Tab+Buttons#ChatTabButtons-Whatisachattabbutton?">
            What is a chat tab button?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Chat+Tab+Buttons#ChatTabButtons-WhataretheavailableactionsforaChattabbutton?">
            What are the available actions for a Chat tab button?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Chat+Tab+Buttons#ChatTabButtons-HowdoIcreateachattabbutton?">
            How do I create a chat tab button?{" "}
          </a>
        </li>
      </ul>
      <section id="ChatTabButtons-Whatisachattabbutton?">
        <h2>What is a chat tab button?</h2>
        <p>
          A chat tab button allows users to initiate your app from the chat tab
          bar. Here is what a chat tab button looks like on desktop
        </p>
        <img style={{ width: "30%" }} src={img1} alt="" />
        <p>...and mobile</p>
      </section>
      <img style={{ width: "30%" }} src={img2} alt="" />
      <section id="ChatTabButtons-WhataretheavailableactionsforaChattabbutton?">
        <h2>What are the available actions for a Chat tab button?</h2>
        <p>
          When a user presses the Chat tab button, you can configure it to do
          one of the below:
        </p>
        <ul>
          <li>Launch a modal widget </li>
          <li>Launch a sidebar widget </li>
          <li>Launch a browser </li>
          <li>Send an event to the event listener URL </li>
        </ul>
        <p>
          Alongwith launching the above widgets or browser, a
          <Link to="/display/flockos/client.pressButton">
            {" "}
            client.pressButton{" "}
          </Link>
          event will also be passed as a parameter to the web app URL loaded in
          any of the above widgets or the browser.
        </p>
      </section>
      <section id="ChatTabButtons-HowdoIcreateachattabbutton?">
        <h2>How do I create a chat tab button?</h2>
        <ul>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App"> Creating an App </Link>
            to setup your app in the developer dashboard.
          </li>
          <li>
            In the Advanced Info section, enable the chat tab button.
            <img src={img3} alt="" />
            <p>Fill in the info below:</p>
            <table className="custom-table">
              <thead>
                <tr>
                  <th>Field</th>
                  <th>Mandatory</th>
                  <th>Description</th>
                </tr>
              </thead>
              <tbody>
                {tableData.map((item) => (
                  <tr key={item.id}>
                    <td>{item.field}</td>
                    <td>{item.mandatory}</td>
                    <td>{item.description}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </li>
          <li>
            Go through{" "}
            <Link to="/display/flockos/Creating+an+App">
              {" "}
              app installation{" "}
            </Link>
            and install this app into your own Flock account
          </li>
          <li>
            Whenever a user presses the Chat tab button, the event
            <Link to="/display/flockos/client.pressButton">
              {" "}
              client.pressButton{" "}
            </Link>
            along with a along with a{" "}
            <Link to="/display/flockos/Events"> validation token </Link>
            will be appended to the corresponding will be appended to the
            corresponding client action you selected and sent to your service
          </li>
          <li>
            <Link to="/display/flockos/Event+Tokens">
              {" "}
              Verify the validation token{" "}
            </Link>{" "}
            and take the relevant action at your end
          </li>
        </ul>
      </section>
    </div>
  );
};

export default ChatTab;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const ChannelList = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`[
        { "id": "g:cfc76545-3400-4864-892a-513a9f4ae409", "name": "<channel name 1>", memberCount: 5 },
        { "id": "g:aee6f08e-05e4-4f49-8e41-6607973ad989", "name": "<channel name 2>", memberCount: 10 }
    ]`,
    },
  ];
  return (
    <div className="container">
      <h1>channels.list</h1>
      <p>
        This method retrieves a list of the groups that the user is a member of.
      </p>
      <h2>Method Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>
            {" "}
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              {" "}
              Authentication token{" "}
            </a>
            for the user
          </td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>
        A list of <Link to="/display/flockos/Channel"> Channel </Link>
        objects.
      </p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
    </div>
  );
};

export default ChannelList;

import React from "react";

const Deprecated = () => {
  return (
    <div className="container">
      <h1>Deprecated</h1>
    </div>
  );
};

export default Deprecated;

import React from "react";

const PublicProfile = () => {
  return (
    <div className="container">
      <h1>PublicProfile</h1>
      <h2>Description</h2>
      <p>
        PublicProfile provides publicly visible profile information for any user
        in Flock.
      </p>
      <h2>Object Attributes</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>id</td>
          <td>String</td>
          <td>Yes</td>
          <td> Id of the user</td>
        </tr>

        <tr>
          <td>firstName</td>
          <td>String</td>
          <td>Yes</td>
          <td> First name of the user</td>
        </tr>
        <tr>
          <td>lastName</td>
          <td>String</td>
          <td>Yes</td>
          <td> Last name of the user</td>
        </tr>
        <tr>
          <td>profileImage</td>
          <td>String</td>
          <td>No</td>
          <td> URL of the user's profile image</td>
        </tr>
        <tr>
          <td>isGuest</td>
          <td>Boolean</td>
          <td>No</td>
          <td> true if user's role is guest. Default value is false.</td>
        </tr>
      </table>
    </div>
  );
};

export default PublicProfile;

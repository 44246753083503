import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const Attachment = () => {
  const codeBlocks = [
    {
      heading: `An example attachment`,
      code: singleLineString`{
        "id": "<id>",
        "title": "<attachment title>",
        "description": "<attachment description>",
        "appId": "<app id>",
        // Precedence order of previews:
        // widget > html > flockml > images
        "color": "#0ABE51",
        "views": {
            // Attachment widget
            "widget": { "src": "<widget url>", "width": 400, "height": 400 },
            // Inline html
            "html":
             { "inline": "<inline html>", "width": 400, "height": 400 },
            "flockml": "<inline flockml>",
            // For image, only "src" is mandatory, everything else is optional
            "image":
             {
                "original": { "src": "<image 1 url>", "width": 400, "height": 400 },
                "thumbnail": { "src": "<image 2 url>", "width": 100, "height": 100 },
                "filename": "foo-bar.png"
            }
        },
        "url": "<unfurled url>",
        "forward": "true",  // default: false
        // For downloads, only "src" is mandatory, everything else is optional.
        // The client can use mime to show an appropriate file icon to the user.
        // Slashes and dots from the filename should be stripped.
        "downloads": 
        [
            { "src": "<download 1 url>", "mime": "<mime type>", "filename": "<filename 1>", "size": <bytes> },
            { "src": "<download 2 url>", "mime": "<mime type>", "filename": "<filename 2>", "size": <bytes> }
        ],
        "buttons":
         [
           {
            "name": "<button 1>",
            "icon": "<icon 1 url>",
            "action": { "type": "openWidget", "desktopType": "modal", "mobileType": "modal", "url": "<action url>" },
            "id": "<button id 1>"
        }, 
        {
            "name": "<button 2>",
            "icon": "<icon 2 url>",
            "action": { "type": "openWidget", "desktopType": "sidebar", "mobileType": "modal", "url": "<action url>" },
            "id": "<button id 2>"
        }, 
        {
            "name": "<button 3>",
            "icon": "<icon 3 url>",
            "action": { "type": "sendEvent" },
            "id": "<button id 3>"
        }
      ]
    }`,
    },
    {
      heading: ``,
      code: singleLineString`{
        "type": "openWidget",
        "url": "https://example.com/widget",
        "desktopType": "sidebar",
        "mobileType": "modal"
    }`,
    },
    {
      heading: ``,
      code: singleLineString`{
        "type": "openBrowser",
        "url": "https://example.com",
        "sendContext": false
    }`,
    },
    {
      heading: ``,
      code: singleLineString`{
        type: "sendEvent"
    }`,
    },
  ];
  return (
    <div className="container">
      <h1>Attachment</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          An attachment is rich content attached to a{" "}
          <Link to="/display/flockos/Message"> message </Link>. It can include
          content like dynamic{" "}
          <Link to="/display/flockos/Widgets"> widgets </Link>, static HTML,
          <Link to="/display/flockos/FlockML"> FlockML </Link>, images and
          files. Attachments can also have their own buttons.
        </p>
        <p>
          To see some examples of attachments see{" "}
          <Link to="/display/flockos/Sending+Attachments">
            {" "}
            Sending Attachments.{" "}
          </Link>
        </p>
      </section>
      <section id="section2">
        <h2>Structure of an Attachment</h2>
        <p>
          The code block below shows in complete detail every element that can
          go inside an attachment. Examples of creating different kinds of
          attachments is covered under Sending Attachments.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <section id="section3">
        <h2>Object Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>id</td>
            <td>String</td>
            <td>No</td>
            <td>
              A unique identifier for the attachment as provided by your app.
            </td>
          </tr>
          <tr>
            <td>appId</td>
            <td>String</td>
            <td>No</td>
            <td>
              App id for the app that sent the attachment. Any value that your
              app provides for this attribute will be overwritten with your
              app's actual id by Flock.
            </td>
          </tr>
          <tr>
            <td>title</td>
            <td>String</td>
            <td>No</td>
            <td>The title of the attachment.</td>
          </tr>
          <tr>
            <td>description</td>
            <td>String</td>
            <td>No</td>
            <td>A longer description of the attachment.</td>
          </tr>
          <tr>
            <td>color</td>
            <td>String</td>
            <td>No</td>
            <td>A hex value (e.g. "#0ABE51") for the color bar.</td>
          </tr>
          <tr>
            <td>views</td>
            <td>Views</td>
            <td>No*</td>
            <td>
              Provides user visible views for the attachment. See below for more
              details.
            </td>
          </tr>
          <tr>
            <td>url</td>
            <td>String</td>
            <td>No</td>
            <td>
              The URL to open when user clicks an attachment, if no widget or
              FlockML is provided. When generating a
              <Link to="/display/flockos/URL+Preview"> URL Preview </Link>
              this should always be set.
            </td>
          </tr>
          <tr>
            <td>forward</td>
            <td>Boolean</td>
            <td>No</td>
            <td>
              If true, the attachment can be forwarded. Default value is false.
            </td>
          </tr>

          <tr>
            <td>downloads </td>
            <td>Array</td>
            <td>No*</td>
            <td>
              <p>
                An array of download objects. Note: As of now this array should
                contain at max one object.
              </p>
              <p>Each object has the following attributes:</p>
              <ul>
                <li>src (String, Required) – URL of the file</li>
                <li>
                  mime (String) – Mime type of the file (used to select the file
                  icon)
                </li>
                <li>filename (String) – Name of the file</li>
                <li>size (Number) – Size of the file in bytes</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>buttons </td>
            <td>Array</td>
            <td>No</td>
            <td>
              <p>
                An array of attachment buttons. Each object has the following
                attributes:
              </p>

              <ul>
                <li>name (String) – Name of the button.</li>
                <li>icon (String) – URL of the button icon.</li>
                <li>
                  action (ActionConfig) – The action to perform when a user
                  clicks on the button.
                </li>
                <li>
                  id (String) – An opaque string provided by your app to
                  uniquely identify the button. The id is sent as an attribute
                  of the{" "}
                  <Link to="/display/flockos/client.pressButton">
                    {" "}
                    client.pressButton{" "}
                  </Link>
                  event.
                </li>
              </ul>
            </td>
          </tr>
        </table>
        <p>
          * While neither views nor downloads is required, one of these must
          always be present in the attachment.
        </p>
        <p className="bold">Views</p>
        <p>
          Flock will select one of the objects inside Views to display on the
          chat screen. See{" "}
          <Link to="/display/flockos/Sending+Attachments">
            {" "}
            Sending Attachments{" "}
          </Link>
          for the precedence order of these views.
        </p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>widget </td>
            <td>Object</td>
            <td>No*</td>
            <td>
              <p>
                Displays an attachment widget inside the chat screen in desktop,
                or pops up a modal when the attachment is opened on mobile.
              </p>

              <ul>
                <li>src (String) – URL for the widget</li>
                <li>width (Number) – Width of the widget</li>
                <li>height (Number) – Height of the widget</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>html </td>
            <td>Object</td>
            <td>No*</td>
            <td>
              <p>
                Displays the HTML string inside the chat screen in desktop
                (using an iframe). It has the following attributes:
              </p>

              <ul>
                <li>inline (String) – The HTML string</li>
                <li>width (Number) – Width of the content</li>
                <li>height (Number) – Height of the content</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>flockml </td>
            <td>String</td>
            <td>No*</td>
            <td>
              <p>
                A string containing FlockML content. It is displayed inside the
                chat screen on both desktop and mobile.
              </p>
            </td>
          </tr>
          <tr>
            <td>image </td>
            <td>Object</td>
            <td>No*</td>
            <td>
              <p>
                An image for the attachment. This is an object with two
                attributes:
              </p>

              <ul>
                <li>original (Image, Required) – the full size image</li>
                <li>thumbnail (Image) – a thumbnail for the image</li>
                <li>
                  filename (String) – original file name for the image, if any
                </li>
              </ul>
              <p>
                The value for both these attributes is an Image object,
                described below.
              </p>
            </td>
          </tr>
        </table>
        <p>* At least one of these objects must be present</p>
        <p className="bold">Image</p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th> Description</th>
          </thead>
          <tr>
            <td>src </td>
            <td>String</td>
            <td>Yes</td>
            <td>The URL to open in the browser</td>
          </tr>
          <tr>
            <td>width </td>
            <td>Number</td>
            <td>No</td>
            <td>Width of the image in pixels</td>
          </tr>
          <tr>
            <td>height </td>
            <td>Number</td>
            <td>No</td>
            <td>Height of the image in pixels</td>
          </tr>
        </table>
        <div id="Attachment-ActionConfig">
          <p className="bold">ActionConfig</p>
          <p>
            This object describes the action that should be triggered when a
            user clicks on an attachment button.
          </p>
        </div>
        <p>It can be used to open a widget:</p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
        <p>Or open a URL in the browser:</p>
        <Codeblock heading={codeBlocks[2].heading} code={codeBlocks[2].code} />
        <p>Or send an event to the event listener URL:</p>
        <Codeblock heading={codeBlocks[3].heading} code={codeBlocks[3].code} />
        <p>It requires at least one attribute, type:</p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Attribute Description</th>
          </thead>
          <tr>
            <td>type </td>
            <td>String</td>
            <td>Yes</td>
            <td>
              <p>
                The configured action. It can have any one of the following
                values:
              </p>

              <ul>
                <li>openWidget</li>
                <li>openBrowser</li>
                <li>sendEvent</li>
              </ul>
              <p>
                The value for both these attributes is an Image object,
                described below.
              </p>
            </td>
          </tr>
        </table>
        <p>
          In addition, if type equals openWidget, these attributes may be
          required:
        </p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Attribute Description</th>
          </thead>
          <tr>
            <td>url </td>
            <td>String</td>
            <td>Yes</td>
            <td>The widget URL</td>
          </tr>
          <tr>
            <td>desktopType </td>
            <td>String</td>
            <td>Yes</td>
            <td>
              <p>
                How the widget should be opened on the desktop. It can be either
                modal or sidebar.
              </p>
            </td>
          </tr>
          <tr>
            <td>mobileType </td>
            <td>String</td>
            <td>No</td>
            <td>
              <p>
                How the widget should be opened on mobile. There is only one
                possible value for this, modal, which is also the default.
              </p>
            </td>
          </tr>
        </table>
        <p>If type equals openBrowser, these attributes may be required:</p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Required</th>
            <th>Attribute Description</th>
          </thead>
          <tr>
            <td>url </td>
            <td>String</td>
            <td>Yes</td>
            <td>The URL to open in the browser</td>
          </tr>
          <tr>
            <td>sendContext </td>
            <td>Boolean</td>
            <td>No</td>
            <td>
              Whether context should be sent to the browser or not. Defaults to
              false.
            </td>
          </tr>
        </table>
      </section>
    </div>
  );
};

export default Attachment;

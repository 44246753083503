import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const AppInstall = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`
            {
                "name": "app.install",
                "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409",
                "token": "4458f0a9-eca7-4efb-8560-2a0fd3ac858d"
            }`,
    },
  ];
  return (
    <div className="container">
      <h1>app.install</h1>
      <section id="section1">
        <h2>Description</h2>
        <p>
          This event is sent to the{" "}
          <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
            {" "}
            event listenter URL{" "}
          </a>
          when an app has been successfully installed by a user.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      </section>
      <div className="caution">
        <p>
          While no return value is expected from the app, if your app does not
          respond to the HTTP request with a 200 OK status code, Flock assumes
          that the installation has failed and will indicate as much to the
          user.
        </p>
      </div>
      <section id="section2">
        <h2>Event Attributes</h2>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Type</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>userId</td>
            <td>String</td>
            <td>
              <Link to="/display/flockos/Identifiers"> User Identifiers </Link>
            </td>
          </tr>
          <tr>
            <td>token</td>
            <td>String </td>
            <td>
              <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
                {" "}
                Authentication token{" "}
              </a>
              for the user
            </td>
          </tr>
        </table>
      </section>
      <section id="section3">
        <h2>Response</h2>
        <p>
          No return value is required for this event. However, a 200 OK status
          code is expected for the installation to be considered successful. In
          case a 200 status code is not received as response to this event,
          Flock will assume that the install failed.
        </p>
      </section>
    </div>
  );
};

export default AppInstall;

import React from "react";
import { Link } from "react-router-dom";

import img1 from "../assets/attachments/39584142/40239278.png";

const CreatingAnApp = () => {
  return (
    <div className="container">
      <h1>Creating an App</h1>
      <ul>
        <li>
          <a href="/display/flockos/Creating+an+App#CreatinganApp-HowdoIcreateaFlockOSapp?">
            How do I create a FlockOS app?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Creating+an+App#CreatinganApp-AppInstallationHowdoIinstallandtestmyapp?">
            How do I install and test my app?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Creating+an+App#CreatinganApp-Whathappenswhenauserinstallsmyapp?">
            What happens when a user installs my app?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Creating+an+App#CreatinganApp-PublishHowdoIpublishmyApp?">
            How do I publish my App?{" "}
          </a>
        </li>
        <li>
          <a href="/display/flockos/Creating+an+App#CreatinganApp-HowcanauserInstallmyapp?">
            How can a user Install my app?{" "}
          </a>
        </li>
      </ul>

      <section id="CreatinganApp-HowdoIcreateaFlockOSapp?">
        <h2>How do I create a FlockOS app?</h2>
        <ol>
          <li>
            To get started, visit our{" "}
            <Link to="https://dev.flock.com/"> Developer Dashboard </Link>, and
            click on Start Creating a Flock App. Fill in the information
            requested and Click on the Save button to create the app
          </li>
          <li>
            Specify your{" "}
            <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
              {" "}
              Event Listener URL.
            </a>{" "}
            This is an HTTPS endpoint provided by your app. Flock will send{" "}
            <Link to="/display/flockos/Events">events </Link>to this endpoint
            (including{" "}
            <Link to="/display/flockos/app.install"> app.install </Link> which
            provides you with an{" "}
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              {" "}
              authentication token{" "}
            </a>
            for each user who installs the app).
          </li>
          <li>
            At this point you are all set to{" "}
            <Link to="/display/flockos/Sending+Messages">send messages</Link>{" "}
            and
            <Link to="/display/flockos/Attachment"> attachments </Link>to Flock.
          </li>
          <li>
            You may choose to configure the following optional capabilities:
            <ul>
              <li>
                <Link to="/display/flockos/Bots"> Your app'sBots</Link>
              </li>
              <li>
                {" "}
                <Link to="/display/flockos/Launcher+Buttons">
                  Launcher Buttons
                </Link>
              </li>
              <li>
                <Link to="/display/flockos/Chat+Tab+Buttons">
                  Chat Tab Buttons
                </Link>
              </li>
              <li>
                <Link to="/display/flockos/Attachment+Pickers">
                  Attachment Picker Button
                </Link>
              </li>
              <li>
                <Link to="/display/flockos/Slash+Commands">Slash Commands</Link>
              </li>
              <li>
                <Link to="/display/flockos/URL+Preview">URL Preview</Link>
              </li>
            </ul>
          </li>
          <li>
            Copy your App Secret. This is a shared secret between Flock and your
            app which is used by Flock to sign{" "}
            <Link to="/display/flockos/Event+Tokens">Event Tokens</Link>. Make
            sure this value is never known to any third party.
          </li>
          <li>
            Read the <Link to="/display/flockos/Events">Events </Link>page to
            understand how events are sent to your event listener URL, or sent
            to a widget or the browser.
          </li>
          <li>
            Read{" "}
            <a href="/display/flockos/Creating+an+App#CreatinganApp-AppInstallationHowdoIinstallandtestmyapp?">
              App Installation{" "}
            </a>
            on what happens when a user tries to install your app.
          </li>
        </ol>
      </section>
      <section id="CreatinganApp-AppInstallationHowdoIinstallandtestmyapp?">
        <h2>How do I install and test my app?</h2>
        <p>
          Click the Install button on the app page in your{" "}
          <Link to="https://dev.flock.com/">Developer Dashboard. </Link>
          This will trigger the install sequence described next and install the
          app into your own Flock account.
        </p>
      </section>
      <section id="CreatinganApp-Whathappenswhenauserinstallsmyapp?">
        <h2>What happens when a user installs my app?</h2>
        <p>
          When a user initiates an app install, the following happens in
          sequence:
        </p>
        <ol>
          <li>
            The <Link to="/display/flockos/app.install">app.install</Link> event
            is sent to your event listener URL. Note: Your event listener must
            respond to this event with an HTTP 200 status code in order for
            installation to succeed
          </li>
          <li>
            Your app should save the authentication token (as the attribute
            token) sent in the request. You will need this token to make{" "}
            <a href="/display/flockos/Methods#Methods-AuthenticationAuthentication">
              method{" "}
            </a>
            calls.
          </li>
          <li>
            The user is redirected to the app configuration URL configured by
            you. You may use this URL to present settings / configuration and
            any instructions. Note: You will receive an{" "}
            <Link to="/display/flockos/Event+Tokens"> event token </Link>
            as a query parameter to your configuration URL. You must
            authenticate the user using this event token before taking any
            action
          </li>
        </ol>
      </section>
      <section id="CreatinganApp-PublishHowdoIpublishmyApp?">
        <h2>How do I publish my App?</h2>
        <p>
          To publish your app to the Flock App store, go to the app page in the
          developer dashboard, and click the Publish button. This will list the
          app in the <a href="https://apps.flock.com/">Flock App Store </a> just
          for your team. To publish your app for every Flock user,{" "}
          <Link to="/display/flockos/Help+and+Support">
            {" "}
            get in touch with us{" "}
          </Link>
          .
        </p>
      </section>

      <img src={img1} alt="sda"></img>

      <section id="CreatinganApp-HowcanauserInstallmyapp?">
        <h2>How can a user Install my app?</h2>
        <p>
          A user can install your app from the Flock App Store once you have
          published it
        </p>
      </section>
    </div>
  );
};

export default CreatingAnApp;

import React from "react";
import { Link } from "react-router-dom";

import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const FlockML = () => {
  const codeBlocks = [
    {
      heading: `FlockML Example`,
      code: singleLineString`<flockml>Hello <strong>foobar</strong>, Welcome to <a href="https://flock.co/">Flock!</a></flockml>`,
    },
  ];
  return (
    <div className="container">
      <h1>FlockML</h1>
      <ul>
        <li>
          <a href="/display/flockos/FlockML#FlockML-Introduction">
            {" "}
            Introduction
          </a>
        </li>
        <li>
          <a href="/display/flockos/FlockML#FlockML-Elements"> Elements</a>
          <ul>
            <li>
              <a href="/display/flockos/FlockML#FlockML-flockml"> flockml</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-a"> a</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-em"> em</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-i"> i</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-strong"> strong</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-b"> b</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-u"> u</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-br"> br</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-user"> user</a>
            </li>
            <li>
              <a href="/display/flockos/FlockML#FlockML-actionaction">
                {" "}
                action
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <section id="FlockML-Introduction">
        <h2>Introduction</h2>
        <p>
          FlockML is an XML based markup language used to show rich text
          <Link to="/display/flockos/Message"> messages </Link>
          and <Link to="/display/flockos/Attachment"> attachment </Link>
          in Flock.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <p>Its salient features are:</p>
        <ul>
          <li>Renders natively on both desktop and mobile</li>
          <li>
            Inspired by HTML, so you will be familiar with many FlockML's
            elements already
          </li>
          <li>
            Elements for actions specific to Flock, like linking to a user or
            triggering actions like opening a widget
          </li>
        </ul>
      </section>
      <section id="FlockML-Elements">
        <h2>Elements</h2>
        <div id="FlockML-flockml">
          <p className="bold">flockml</p>
          <p>
            The root element for FlockML. Any FlockML document that doesn't have
            &lt; flockml &gt; as its root is considered invalid.
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
            </thead>
            <tr>
              <td>&lt;flockml&gt;Hello World&lt;/flockml&gt;</td>
            </tr>
          </table>
        </div>
        <div id="FlockML-a">
          <p className="bold">a</p>
          <p>
            The anchor element &lt; a &gt; defines a hyperlink. Clicking on this
            opens a URL in the browser.
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>&lt;a href="" &gt;Hello World&lt;/a&gt;</td>
              <td>
                <a href="">Hello World</a>
              </td>
            </tr>
          </table>
          <p className="bold">Attributes</p>
          <table className="custom-table" border="1">
            <thead>
              <th>Name</th>
              <th>Required</th>
              <th>Description</th>
            </thead>
            <tr>
              <td>href</td>
              <td>Yes</td>
              <td>The URL to link to</td>
            </tr>
          </table>
        </div>
        <div id="FlockML-em">
          <p className="bold">em</p>
          <p>
            The emphasis element &lt; em &gt; is used to emphasise text and is
            typically italicized.
          </p>
          <table className="custom-table">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>&lt;em&gt;Hello World&lt;/em&gt;</td>
              <td>
                <em>Hello World</em>
              </td>
            </tr>
          </table>
        </div>
        <div id="FlockML-i">
          <p className="bold">i</p>
          <p>The italics element &lt; i &gt; is used to italicize text.</p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>&lt;i&gt;Hello World&lt;/&gt;</td>
              <td>
                <i>Hello World</i>
              </td>
            </tr>
          </table>
        </div>
        <div id="FlockML-strong">
          <p className="bold">strong</p>
          <p>
            The strong element &lt; strong &gt; gives text strong importance,
            and is typically displayed in bold.
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>&lt;strong&gt;Hello World&lt;/strong&gt;</td>
              <td>
                <strong>Hello World</strong>
              </td>
            </tr>
          </table>
        </div>
        <div id="FlockML-b">
          <p className="bold">b</p>
          <p>The bold element &lt; b ; &gt; is used to display text in bold.</p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>&lt;b&gt;Hello World&lt;/b&gt;</td>
              <td>
                <b>Hello World</b>
              </td>
            </tr>
          </table>
        </div>
        <div id="FlockML-u">
          <p className="bold">u</p>
          <p>
            The underline element &lt; u ; &gt; renders text with an underline.
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>&lt;u&gt;Hello World&lt;/u&gt;</td>
              <td>
                <u>Hello World</u>
              </td>
            </tr>
          </table>
        </div>
        <div id="FlockML-br">
          <p className="bold">br</p>
          <p>
            The line break element &lt; br ; &gt; produces a line break in text
            (carriage-return).
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>Hello &lt;br /&gt; World</td>
              <td>
                Hello
                <br />
                World
              </td>
            </tr>
          </table>
        </div>
        <div id="FlockML-user">
          <p className="bold">user</p>
          <p>This element can be used to link to a Flock user.</p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
              <th>Rendered</th>
            </thead>
            <tr>
              <td>
                &lt;user userId="u:guid"&gt;FirstName LastName&lt;/user&gt;
              </td>
              <td>
                <a
                  href="#"
                  style={{ color: "blue", textDecoration: "underline" }}
                >
                  FirstName LastName
                </a>
              </td>
            </tr>
          </table>

          <p className="bold">Attributes</p>

          <table className="custom-table" border="1">
            <thead>
              <th>Name</th>
              <th>Required</th>
              <th>Description</th>
            </thead>
            <tr>
              <td>userId</td>
              <td>Yes</td>
              <td>Should be a user identifier.</td>
            </tr>
          </table>
          <ul>
            <li>
              If the Flock client knows the user's name, it will ignore any text
              content inside. Otherwise, it will display the text content if
              present.
            </li>
            <li>
              If the Flock client doesn't know the user's name, and no text
              content is provided, it will display "Unknown User".
            </li>
          </ul>
        </div>
        <div id="FlockML-actionaction">
          <p>action</p>
          <p>
            The action element allows an app to trigger a{" "}
            <Link to="/display/flockos/Client+Actions"> Client Actions </Link>.
            The associated event is{" "}
            <Link to="/display/flockos/client.flockmlAction">
              {" "}
              client.flockmlAction{" "}
            </Link>
            .
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Example</th>
            </thead>
            <tr>
              <td>
                click &lt;action id="act1" type="openWidget" url="&lt;url&gt;"
                desktopType="sidebar" mobileType="modal" &gt; here
                &lt;/action&gt; to launch a widget.
              </td>
            </tr>
          </table>
          <p className="bold">Attributes</p>
          <table className="custom-table" border="1">
            <thead>
              <th>Name</th>
              <th>Required</th>
              <th>Description</th>
            </thead>
            <tr>
              <td>type</td>
              <td>Yes </td>
              <td>
                <p>The type of action to trigger. Should be one of:</p>
                <ul>
                  <li>openWidget</li>
                  <li>openBrowser</li>
                  <li>sendEvent</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>id</td>
              <td>No</td>
              <td>
                An identifier for the action. It is included in the payload for
                <Link to="/display/flockos/client.flockmlAction">
                  {" "}
                  client.flockmlAction{" "}
                </Link>
                as the actionId attribute.
              </td>
            </tr>
          </table>

          <p>
            If type is openWidget, the following attributes should also be
            provided:
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Name</th>
              <th>Required</th>
              <th>Description</th>
            </thead>

            <tr>
              <td>url</td>
              <td>Yes</td>
              <td>The widget URL</td>
            </tr>
            <tr>
              <td>desktopType</td>
              <td>Yes</td>
              <td>
                The type of widget in desktop. Should be either modal or
                sidebar. Defaults to modal.
              </td>
            </tr>
            <tr>
              <td>mobileType</td>
              <td>Yes</td>
              <td>
                The type of widget in mobile. If provided, it should be set to
                modal.
              </td>
            </tr>
          </table>

          <p>
            If type is openBrowser, the following attributes should be provided:
          </p>
          <table className="custom-table" border="1">
            <thead>
              <th>Name</th>
              <th>Required</th>
              <th>Description</th>
            </thead>

            <tr>
              <td>url</td>
              <td>Yes</td>
              <td>The URL to open</td>
            </tr>
            <tr>
              <td>sendContext</td>
              <td>No</td>
              <td>
                TWhether to send context to the URL or not. Should be either
                true or false. Default value is false.
              </td>
            </tr>
          </table>
          <p>If type is sendEvent, no other attribute needs to be provided.</p>
        </div>
      </section>
    </div>
  );
};

export default FlockML;

// Filename - components/SubMenu.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SidebarLink = styled(Link)`
  display: flex;
  color: black;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  list-style: none;
  height: 40px;
  text-decoration: none;
  font-size: 18px;
  background: #eee;
  font-weight: 550;

  &:hover {
    background: #e0e0e0;
    border-left: 4px solid green;
    cursor: pointer;
  }
`;

const SidebarLabel = styled.span`
  margin-left: 16px;
`;

const SubMenu = ({ item, nestingLevel, paddingClass }) => {
  const DropdownLink = styled(Link)`
    background: #eee;
    height: 40px;
    display: flex;
    padding-left: 3rem;
    align-items: center;
    text-decoration: none;
    color: black;
    font-size: 18px;

    &:hover {
      background: #e0e0e0;
      cursor: pointer;
    }
  `;
  const dynamicStyle = {
    paddingLeft: `${nestingLevel}rem`,
  };

  const [subnav, setSubnav] = useState(false);

  const showSubnav = () => setSubnav(!subnav);

  return (
    <>
      <SidebarLink to={item.path} onClick={item.subNav && showSubnav}>
        <div>
          <SidebarLabel>{item.title}</SidebarLabel>
        </div>
        <div>
          {item.subNav && subnav ? (
            item.iconOpened
          ) : item.subNav ? (
            item.iconClosed
          ) : (
            <div className="dot"></div>
          )}
        </div>
      </SidebarLink>

      {subnav &&
        item.subNav.map((item2, index) => {
          if (item2.subNav) {
            return (
              <div className="navbar-2">
                <SubMenu
                  item={item2}
                  nestingLevel={nestingLevel + 1}
                  paddingClass={"navbar-2"}
                  classname={`naviitem-${nestingLevel}`}
                ></SubMenu>
              </div>
            );
          } else {
            return (
              <div>
                <DropdownLink to={item2.path} key={index} style={dynamicStyle}>
                  {item2.icon}
                  <SidebarLabel>{item2.title}</SidebarLabel>
                </DropdownLink>
              </div>
            );
          }
        })}
    </>
  );
};

export default SubMenu;

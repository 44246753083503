import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";

const Events = () => {
  const codeBlocks = [
    {
      heading: `HTTP Request for an event
      `,
      code: singleLineString`POST /events HTTP/1.1
      Host: example.com
      Content-Type: application/json
      Content-Length: 162
      X-Flock-Event-Token: eyJhbGciOiJI.Zm9vYmFy.DNKgXrWOe0WEeU3j5ZTVSU9LKWE
       
      {
          "name": "<event name>",
          "userId": "u:<identifier>",
          "customAttribute1": "<value 1>",
          "customAttribute2": "<value 2>"
      }`,
    },
    {
      heading: `Response for a chat.unfurl_url event
      `,
      code: singleLineString`HTTP/1.1 200 OK
      Content-Type: application/json
      Content-Length: 235
       
      {
          "attachment": {
              "title": "<title>",
              "description": "<description>",
              "views": {
                  "widget": { "src": "<url>", "width": 400, "height": 400 },
                  "flockml": "<flockml>some content</flockml>"
              }
          }
      }`,
    },
    {
      heading: `
      `,
      code: singleLineString`https://<widget url>?flockEventToken=<token>
      &flockClient=desktop&flockWidgetType=modal&flockEvent=%7B%
      22name%22%3A%22client.pressButton%22%2C%22button%22%3A%22
      chatTabButton%22%2C%22userId%22%3A
      %22u%3Aguid1%22%2C%22userName%22%3A%22Alyssa%20P
      .%20Hacker%22%2C%
      22chat%22%3A%22u%3Aguid2%22%2C%22chat
      Name%22%3A%22Ben%20Bitdiddle%22%7D
      `,
    },
  ];
  return (
    <div className="container">
      <h1>Events</h1>
      <ul>
        <li>
          <a href="/display/flockos/Events#Events-Whatareevents?">
            What are events?
          </a>
        </li>
        <li>
          <a href="/display/flockos/Events#Events-ListofEvents">
            List of Events
          </a>
        </li>
        <li>
          <a href="/display/flockos/Events#Events-ReceivingEvents">
            Receiving Events
          </a>
        </li>
        <li>
          <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
            Receiving Events on the Event Listenere URL
          </a>
        </li>
        <li>
          <a href="/display/flockos/Events#Events-ResponseRespondingtoEvents">
            Responding to Events
          </a>
        </li>
        <li>
          <a href="/display/flockos/Events#Events-WidgetOrBrowserURLReceivingEventsonaWidgetorintheBrowser">
            Receiving Events on a Widget or in the Browser
          </a>
        </li>
        <li>
          <a href="/display/flockos/Events#Events-ConfigurableEventsListofEventssenttoaWidgetorBrowser">
            List of Events sent to a Widget or Browser
          </a>
        </li>
      </ul>
      <section id="Events-Whatareevents?">
        <h2>What are events?</h2>
        <p>
          Flock uses events to notify apps in real-time about interesting things
          like:
        </p>
        <ul>
          <li>App installs and uninstalls</li>
          <li>New messages received by the app bot</li>
          <li>
            User actions like when a button is pressed, or when a slash command
            is entered.
          </li>
        </ul>
      </section>
      <section id="Events-ListofEvents">
        <h2>List of Events</h2>
        <ul>
          <li>
            <Link to="/display/flockos/app.install"> app.install </Link>
          </li>
          <li>
            <Link to="/display/flockos/app.uninstall"> app.uninstall </Link>
          </li>
          <li>
            <Link to="/display/flockos/chat.generateUrlPreview">
              {" "}
              chat.generateUrlPreview{" "}
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/chat.receiveMessage">
              {" "}
              chat.receiveMessage{" "}
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.flockmlAction">
              {" "}
              client.flockmlAction{" "}
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.messageAction">
              {" "}
              client.messageAction{" "}
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.openAttachmentWidget">
              {" "}
              client.openAttachmentWidget
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.pressButton">
              {" "}
              client.pressButton{" "}
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.slashCommand">
              {" "}
              client.slashCommand
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.widgetAction">
              {" "}
              client.widgetAction
            </Link>
          </li>
        </ul>
      </section>
      <section id="Events-ReceivingEvents">
        <h2>Receiving Events</h2>
        <p>
          Whenever an event is triggered, it is sent to the app in one of two
          ways
        </p>
        <ul>
          <li>Either they are sent to the Event Listener URL.</li>
          <li>
            Or they may be sent to a{" "}
            <Link to="/display/flockos/Widgets"> Widget </Link>
            or the browser (in the form of a URL query parameter)
          </li>
        </ul>
        <p>
          Some events like{" "}
          <Link to="/display/flockos/app.install"> app.install </Link>
          can only be sent to the event listener URL, whereas others like
          <Link to="/display/flockos/client.pressButton">
            {" "}
            client.pressButton{" "}
          </Link>
          (that are triggered in response to user actions in Flock), can be sent
          either to the event listener or to a widget or browser URL, depending
          on how the corresponding integration was configured. See{" "}
          <a href="/display/flockos/Events#Events-ReceivingEvents">
            {" "}
            Can any event be sent a to widget or browser URL?{" "}
          </a>{" "}
          for a list of these events.
        </p>
      </section>
      <section id="Events-EventListenerURLReceivingEventsontheEventListenerURL">
        <h2>Receiving Events on the Event Listener URL</h2>
        <p>
          The event listener URL is an HTTPS endpoint that you provide while
          <Link to="/display/flockos/Creating+an+App"> creating the App </Link>
          in the dev dashboard. Events are sent to this URL in the form of an
          HTTP POST request with a JSON body. The app may choose to process
          certain events and ignore others.
        </p>
        <p>
          For example, if the callback URL is https://example.com/events, the
          HTTP request as shown below is sent to the app.
        </p>
        <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
        <p>
          The headers X-Flock-Event-Token provides an{" "}
          <Link to="/display/flockos/Event+Tokens"> Event tokens </Link>
          that has been signed with the app secret. Your app can use it to
          verify that the request did indeed originate from Flock.
        </p>
        <p>
          The body of the HTTP request contains an event object. Each event
          object contains the following fields:
        </p>

        <ul>
          <li>name. The name of this event.</li>
          <li>userId. Id of the user related to this event.</li>
          <li>Any other custom event attributes related to this event.</li>
        </ul>
      </section>
      <section id="Events-ResponseRespondingtoEvents">
        <h2>Responding to Events</h2>
        <p>
          For most events Flock ignores the response to the HTTP request.
          However, for some events, a response is useful and may in fact be
          required. For example, the{" "}
          <Link to="/display/flockos/chat.generateUrlPreview">
            {" "}
            chat.generateUrlPreview{" "}
          </Link>
          event expects an{" "}
          <Link to="/display/flockos/Attachment"> attachment </Link>
          object in the response. This attachment is added to the message for
          which the preview was requested. The snippet below shows how a
          response to such an event might be sent.
        </p>
        <Codeblock heading={codeBlocks[1].heading} code={codeBlocks[1].code} />
      </section>
      <section id="Events-WidgetOrBrowserURLReceivingEventsonaWidgetorintheBrowser">
        <h2>Receiving Events on a Widget or in the Browser</h2>
        <p>
          A few events are sent as a query parameter to the{" "}
          <Link to="/display/flockos/Widgets"> Widget </Link> or the{" "}
          <Link to="/display/flockos/Widgets"> browser </Link> if the action
          configured for their corresponding integrations is either to open a
          widget or to open the browser.
        </p>
        <p>The following parameters are added to the widget or browser URL:</p>
        <table className="custom-table" border="1">
          <thead>
            <th>Name</th>
            <th>Description</th>
          </thead>
          <tr>
            <td>flockEvent </td>
            <td>The event JSON, serialized into a string</td>
          </tr>
          <tr>
            <td>flockEventToken </td>
            <td>
              <Link to="/display/flockos/Event+Tokens"> Event tokens </Link>
              to verify that the event was sent by Flock
            </td>
          </tr>
          <tr>
            <td>flockClient </td>
            <td>Either desktop, ios, or android.</td>
          </tr>
          <tr>
            <td>flockWidgetType </td>
            <td>
              The widget type (modal, sidebar or attachment) in which the
              content is rendered.
            </td>
          </tr>
        </table>
        <p>
          For example, if one configures an attachment picker button in the
          developer dashboard, when a user clicks on this button, the
          <Link to="/display/flockos/client.pressButton">
            {" "}
            client.pressButton{" "}
          </Link>
          event alongwith the event token and other parameters is added to the
          URL like this:
        </p>
        <Codeblock heading={codeBlocks[2].heading} code={codeBlocks[2].code} />
      </section>
      <section id="Events-ConfigurableEventsListofEventssenttoaWidgetorBrowser">
        <h2>List of Events sent to a Widget or Browser</h2>
        <p>Only the following events can be sent to a widget or browser.</p>
        <ul>
          <li>
            <Link to="/display/flockos/client.pressButton">
              {" "}
              client.pressButton{" "}
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.slashCommand">
              {" "}
              client.slashCommand
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.widgetAction">
              {" "}
              client.widgetAction
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.flockmlAction">
              {" "}
              client.flockmlAction{" "}
            </Link>
          </li>
          <li>
            <Link to="/display/flockos/client.openAttachmentWidget">
              {" "}
              client.ClientOpenAttachment
            </Link>
          </li>
        </ul>
      </section>
    </div>
  );
};

export default Events;

import React from "react";
import { Link } from "react-router-dom";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
const AppUninstall = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`
      {
          "name": "app.uninstall",
          "userId": "u:cfc76545-3400-4864-892a-513a9f4ae409"
      }`,
    },
  ];
  return (
    <div className="container">
      <h1>app.uninstall</h1>
      <h2>Description</h2>
      <p>
        This event is sent to the{" "}
        <a href="/display/flockos/Events#Events-EventListenerURLReceivingEventsontheEventListenerURL">
          {" "}
          event listenter URL{" "}
        </a>
        when a user has uninstalled an app.
      </p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      <h2>Event Attributes</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>userId</td>
          <td>String</td>
          <td>
            {" "}
            <Link to="/display/flockos/Identifiers"> User identifiers </Link>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default AppUninstall;

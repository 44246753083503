import React from "react";
import { singleLineString } from "../Helpers/StringFormatter";
import Codeblock from "./Codeblock";
import img1 from "../assets/groupsGetinfo.png";
const DeprecatedGroupGetInfo = () => {
  const codeBlocks = [
    {
      heading: ``,
      code: singleLineString`{
            "id": "g:f71eec8b-b94a-45af-a698-cc139b3d48b1",
            "name": "<group name>",
            "memberCount": 15,
            "profileImage": "https://av.flock.co/9e6d31314638145778a3f51e-full"
        }`,
    },
  ];
  return (
    <div className="container">
      <h1>groups.getInfo</h1>
      <div className="caution">
        <p className="bold">Deprecation Notice</p>

        <p>
          The method groups.getInfo is deprecated. Use channels.getInfo instead
        </p>
      </div>
      <h2>Description</h2>
      <p>This method retrieves information related to a group.</p>
      <h2>Parameters</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Name</th>
          <th>Type</th>
          <th>Required</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>token</td>
          <td>String</td>
          <td>Yes</td>
          <td>Authentication token for the user.</td>
        </tr>
        <tr>
          <td>groupId</td>
          <td>String</td>
          <td>Yes</td>
          <td>Identifier of the group for which information was requested</td>
        </tr>
      </table>
      <h2>Response</h2>
      <p>A single Group object.</p>
      <Codeblock heading={codeBlocks[0].heading} code={codeBlocks[0].code} />
      <h2>Errors</h2>
      <table className="custom-table" border="1">
        <thead>
          <th>Error Code</th>
          <th>HTTP Status</th>
          <th>Description</th>
        </thead>
        <tr>
          <td>UnknownGroup</td>
          <td>400</td>
          <td>The group provided in the groupId field can't be located </td>
        </tr>
      </table>
      <img src={img1} alt="" />
    </div>
  );
};

export default DeprecatedGroupGetInfo;
